import { CustomMenuItem, PermissionActionTypes } from 'helper/type/action.type';
import { ReactComponent as IconExclamation } from 'assets/images/exclamation-circle.svg';
import { ReactComponent as IconBed } from 'assets/images/bed.svg';
import { ReactComponent as IconAssign } from 'assets/images/assign.svg';
import { ReactComponent as IconCancelAssign } from 'assets/images/cancel-assign.svg';
import { ReactComponent as IconPlusCircle } from 'assets/images/plus-circle.svg';
import { ReactComponent as IconCalendar } from 'assets/images/calendar.svg';
import { ReactComponent as IconSwap } from 'assets/images/swap.svg';
import { ReactComponent as IconSurvey } from 'assets/images/survey-icon.svg';
import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { ReactComponent as Duplicate } from 'assets/images/duplicate.svg';
import { usePermissions } from 'AuthorizePermission';
import { ACTION_BOOKING } from 'components/booking-list/action-booking';
import { useFilterActions } from 'helper/common/permission.action.helper';

const ACTION_BOOKING_LIST = (t: (key: string) => string, showCreateSurvey: boolean) => {
  const baseActions = [
    {
      key: ACTION_BOOKING.DETAIL,
      label: (
        <div className="action-item">
          <IconExclamation />
          <span>{t('common.actions.details')}</span>
        </div>
      ),
      permission: PermissionActionTypes.BOOKING_LIST_DETAIL
    },
    {
      label: (
        <div className="action-item">
          <IconBed />
          <span>{t('common.actions.checkIn')}</span>
        </div>
      ),
      key: ACTION_BOOKING.GET_ROOM,
      permission: PermissionActionTypes.CHECK_IN
    },
    {
      label: (
        <div className="action-item">
          <IconAssign />
          <span>{t('common.actions.assignRoom')}</span>
        </div>
      ),
      key: ACTION_BOOKING.ASSIGN_ROOM,
      permission: PermissionActionTypes.ASSIGN_ROOM
    },
    {
      label: (
        <div className="action-item">
          <IconCancelAssign />
          <span>{t('common.actions.unAssignRoom')}</span>
        </div>
      ),
      key: ACTION_BOOKING.CANCEL_ASSIGN_ROOM,
      permission: PermissionActionTypes.UNASSIGN_ROOM
    },
    {
      type: 'divider',
      key: ACTION_BOOKING.DETAIL_DIVIDER
    },
    {
      label: (
        <div className="action-item">
          <IconPlusCircle />
          <span>{t('common.actions.addServices')}</span>
        </div>
      ),
      key: ACTION_BOOKING.ADD_SERVICE,
      permission: PermissionActionTypes.ADD_SERVICE
    },
    {
      label: (
        <div className="action-item">
          <IconCalendar />
          <span>{t('common.actions.changeDate')}</span>
        </div>
      ),
      key: ACTION_BOOKING.CHANGE_DATE,
      permission: PermissionActionTypes.CHANGE_DATE
    },
    {
      label: (
        <div className="action-item">
          <IconSwap />
          <span>{t('common.actions.changeRoom')}</span>
        </div>
      ),
      key: ACTION_BOOKING.CHANGE_ROOM,
      permission: PermissionActionTypes.CHANGE_ROOM
    },
    {
      type: 'divider',
      key: ACTION_BOOKING.CANCEL_BOOKING_DIVIDER
    },
    {
      label: (
        <div className="action-item">
          <IconClose />
          <span>{t('common.actions.cancelBooking')}</span>
        </div>
      ),
      key: ACTION_BOOKING.CANCEL_BOOKING,
      permission: PermissionActionTypes.CANCEL_BOOKING
    },
    {
      label: (
        <div className="action-item">
          <Duplicate />
          <span>{t('common.actions.duplicateBooking')}</span>
        </div>
      ),
      key: ACTION_BOOKING.DUPLICATE_BOOKING_LINE,
      permission: PermissionActionTypes.DOUBLE_ORDER
    }
  ];
  const surveyAction = {
    label: (
      <div className="action-item">
        <IconSurvey />
        <span>{t('common.actions.createSurvey')}</span>
      </div>
    ),
    key: ACTION_BOOKING.CREATE_SURVEY
  };

  return showCreateSurvey ? [...baseActions, surveyAction] : baseActions;
};

export const useFilteredActionBookingList = (
  t: (key: string) => string,
  showCreateSurvey: boolean
): CustomMenuItem[] => {
  const { hasPermission, permissions } = usePermissions();
  const actions = ACTION_BOOKING_LIST(t, showCreateSurvey);
  return useFilterActions(actions, permissions, hasPermission);
};
