import React from 'react';
import { Row, Col, Form, Input, Checkbox, Select } from 'antd';
import { MESSAGE_CODE } from 'constants/validate';
import { EmailRegex } from 'constants/regex';

interface FormRowProps {
  countries: any;
  companies: any;
  sources: any;
  t: (key: string) => string;
}

export const renderFormRow = ({ countries, companies, sources, t }: FormRowProps) => {
  return (
    <>
      <Row gutter={[24, 16]}>
        <Col xs={24} md={12}>
          <Form.Item
            label={t('managePage.travelAgencyPage.taActions.companyAndTaName')}
            name="name"
            rules={[{ required: true, message: MESSAGE_CODE.REQUIRED_TRAVEL_AGENCY_NAME }]}
          >
            <Input
              placeholder={t('managePage.travelAgencyPage.taActions.placeholderCompanyAndTaName')}
            />
          </Form.Item>

          <Form.Item
            label={t('managePage.travelAgencyPage.invoiceName')}
            name="invoice_name"
            rules={[{ required: true, message: MESSAGE_CODE.REQUIRED_INVOICE_NAME }]}
          >
            <Input
              placeholder={t('managePage.travelAgencyPage.taActions.placeholderInvoiceName')}
            />
          </Form.Item>

          <Form.Item
            label={t('managePage.travelAgencyPage.taActions.placeholderInvoiceName')}
            name="external_id"
          >
            <Input placeholder="Nhập ID bên ngoài" />
          </Form.Item>

          <Form.Item label="Mã số thuế" name="vat">
            <Input placeholder="Nhập mã số thuế" />
          </Form.Item>
        </Col>

        <Col xs={24} md={12}>
          <Form.Item
            label={t('managePage.travelAgencyPage.taEmail')}
            name="email"
            rules={[
              {
                pattern: EmailRegex,
                message: MESSAGE_CODE.WRONG_FORMAT_EMAIL
              }
            ]}
          >
            <Input placeholder={t('managePage.travelAgencyPage.taActions.placeholderEmail')} />
          </Form.Item>

          <Form.Item
            name="medium_id"
            label={t('managePage.travelAgencyPage.taActions.mediumMarket')}
          >
            <Select
              options={companies}
              showSearch
              allowClear
              filterOption={(input, option) =>
                !!option && option.name.toLowerCase().includes(input.toLowerCase())
              }
              fieldNames={{ label: 'name', value: 'id' }}
              placeholder={t('managePage.travelAgencyPage.taActions.placeholderSelectMediumMarket')}
            />
          </Form.Item>

          <Form.Item
            name="source_id"
            label={t('managePage.travelAgencyPage.taActions.selectSource')}
          >
            <Select
              options={sources}
              showSearch
              allowClear
              filterOption={(input, option) =>
                !!option && option.name.toLowerCase().includes(input.toLowerCase())
              }
              fieldNames={{ label: 'name', value: 'id' }}
              placeholder={t('managePage.travelAgencyPage.taActions.selectSource')}
            />
          </Form.Item>

          <Form.Item label={t('managePage.travelAgencyPage.taNationality')} name="country_id">
            <Select
              showSearch
              allowClear
              filterOption={(input, option) =>
                !!option && option.name.toLowerCase().includes(input.toLowerCase().trim())
              }
              options={countries || []}
              fieldNames={{ label: 'name', value: 'id' }}
              placeholder={t('managePage.travelAgencyPage.taActions.selectNationality')}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t('managePage.travelAgencyPage.taAddress')} name="address">
            <Input placeholder={t('managePage.travelAgencyPage.taActions.addressInput')} />
          </Form.Item>
        </Col>
        <Col span={8} style={{ textAlign: 'center' }}>
          <Form.Item name="is_travel_agency" valuePropName="checked">
            <Checkbox>{t('managePage.travelAgencyPage.taActions.checkbox.ta')}</Checkbox>
          </Form.Item>
        </Col>
        <Col span={8} style={{ textAlign: 'center' }}>
          <Form.Item name="is_company" valuePropName="checked">
            <Checkbox>{t('managePage.travelAgencyPage.taActions.checkbox.company')}</Checkbox>
          </Form.Item>
        </Col>
        <Col span={8} style={{ textAlign: 'center' }}>
          <Form.Item name="is_system" valuePropName="checked">
            <Checkbox disabled>
              {t('managePage.travelAgencyPage.taActions.checkbox.isSystem')}
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item label={t('common.note')} name="notes">
            <Input.TextArea placeholder={t('common.note')} rows={4} />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
};
