import _ from 'lodash';
import React, { useEffect } from 'react';
import { Checkbox, Col, Form, FormInstance, Input, Row, Select } from 'antd';

import { useGetCompanies } from 'hooks/useGetCompanies';
import { useGetSources } from 'hooks/useGetSources';

import { CustomerForm } from 'components/form/customer-info/CustomerForm';
import { SelectGroup } from './SelectGroup';
import { MESSAGE_CODE } from 'constants/validate';
import { LEVEL_ENUM_OPTIONS } from 'constants/common';
import 'styles/customer-info.scss';

function CustomerInfo({
  form,
  isHidden,
  t
}: {
  form: FormInstance;
  isHidden: boolean;
  t: (key: string) => string;
}) {
  const isFoc = Form.useWatch('foc', form);
  const reasonApproveBy = Form.useWatch('reason_approve_by', form);
  const level = Form.useWatch('level', form);
  const { data: companies } = useGetCompanies();
  const { data: sources } = useGetSources();

  useEffect(() => {
    const roomTypes: any[] = _.clone(form.getFieldValue('roomTypes')) || [];
    roomTypes.forEach(item => {
      item.is_foc = isFoc;
      item.reason_approve_by = reasonApproveBy;
      item.level = level;
    });
    form.setFieldValue('roomTypes', roomTypes);
  }, [form, isFoc, t, reasonApproveBy, level]);

  return (
    <div className="pms-new-group__form" style={{ display: isHidden ? 'none' : 'block' }}>
      <Row gutter={[48, 16]} className="new-group-form">
        <Col span={24} md={12}>
          <h5 className="title">Thông tin đặt phòng</h5>
          <SelectGroup form={form} />

          <Form.Item label="Ghi chú" name="note">
            <Input.TextArea placeholder="Nội dung" rows={8} />
          </Form.Item>

          <Form.Item
            name="medium_id"
            label="Phân khúc thị trường"
            rules={[
              {
                required: true,
                message: MESSAGE_CODE.REQUIRED_MARKET
              }
            ]}
          >
            <Select
              options={companies}
              showSearch
              filterOption={(input, option) =>
                !!option && option.name.toLowerCase().includes(input.toLowerCase())
              }
              fieldNames={{ label: 'name', value: 'id' }}
            />
          </Form.Item>
          <Form.Item
            name="source_id"
            label="Chọn nguồn"
            rules={[
              {
                required: true,
                message: MESSAGE_CODE.REQUIRED_SOURCE
              }
            ]}
          >
            <Select
              options={sources}
              showSearch
              filterOption={(input, option) =>
                !!option && option.name.toLowerCase().includes(input.toLowerCase())
              }
              fieldNames={{ label: 'name', value: 'id' }}
            />
          </Form.Item>

          <Form.Item name="foc" valuePropName="checked" style={{ marginBottom: 8 }}>
            <Checkbox>FOC</Checkbox>
          </Form.Item>

          {isFoc && (
            <Row gutter={[8, 8]}>
              <Col span={8}>
                <Form.Item
                  name="level"
                  label="Cấp bậc"
                  rules={[
                    {
                      required: isFoc,
                      message: MESSAGE_CODE.REQUIRED_LEVEL
                    }
                  ]}
                >
                  <Select options={LEVEL_ENUM_OPTIONS} style={{ maxWidth: 200 }} />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  name="reason_approve_by"
                  label="Lý do/ hoặc người phê duyệt"
                  rules={[
                    {
                      required: isFoc,
                      message: ''
                    }
                  ]}
                >
                  <Input placeholder="Lý do" />
                </Form.Item>
              </Col>
            </Row>
          )}
        </Col>
        <Col span={24} md={12}>
          <CustomerForm name="" form={form} selectedCustomerId="customer" isShowUpload={false} />
        </Col>
      </Row>
    </div>
  );
}

export default CustomerInfo;
