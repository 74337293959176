import { useMemo, useState } from 'react';
import ExcelJS from 'exceljs';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as FileSaver from 'file-saver';
import { handleMenuClick, generateMenuItems } from 'utils/excelUtils';
import { GUEST_LEVEL_NAME, MAP_GUEST_LEVEL } from 'constants/common';
import { formatInputMoney } from 'utils';

interface Props {
  fileName: string;
  dataToExport: any[];
  t: (key: string) => string;
  fromDate: string;
  toDate: string;
  level: string;
}

const ExportFocsReport = ({ fileName, dataToExport, t, fromDate, toDate, level }: Props) => {
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const exportToCSV = async () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const mappedGuestLevel = (item: any) => {
      const genderKey = MAP_GUEST_LEVEL[item.guest_level];
      return GUEST_LEVEL_NAME[genderKey as keyof typeof GUEST_LEVEL_NAME];
    };

    try {
      setIsLoadingExport(true);

      const workbook = new ExcelJS.Workbook();
      const sheetName = `Focs Report`;
      const sheet = workbook.addWorksheet(sheetName, {
        views: [
          {
            showGridLines: false
          }
        ]
      });

      sheet.mergeCells('A2:J2');
      sheet.getCell('A2').value = 'BÁO CÁO DANH SÁCH FOCs';
      sheet.getCell('A2').font = { size: 11, bold: true };
      sheet.getCell('A2').alignment = { horizontal: 'center' };

      sheet.getCell('A4').value = `Ngày`;
      sheet.getCell('A4').font = { bold: true };
      sheet.getCell('B4').value = fromDate;
      sheet.getCell('C4').value = `Đến`;
      sheet.getCell('C4').font = { bold: true };
      sheet.getCell('D4').value = toDate;
      sheet.getCell('E4').value = `Cấp bậc`;
      sheet.getCell('E4').font = { bold: true };
      sheet.getCell('F4').value = level || 'Tất cả';

      // Header row
      sheet.getRow(6).values = [
        'Mã đặt phòng',
        'Tên khách',
        'Loại Phòng',
        'Nguồn đặt phòng',
        'Số lượng = Số đêm',
        'Cấp bậc',
        'Tổng tiền phòng',
        'Nhận phòng',
        'Ngày đi',
        'Ngày FOC'
      ];
      sheet.getRow(6).eachCell(cell => {
        cell.border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center' };
      });

      sheet.columns = [
        { key: 'booking_id', width: 20 },
        { key: 'partner_name', width: 30 },
        { key: 'room_type', width: 15 },
        { key: 'source_name', width: 20 },
        { key: 'room_qty', width: 30 },
        { key: 'guest_level', width: 20 },
        { key: 'room_price', width: 20 },
        { key: 'check_in', width: 20 },
        { key: 'check_out', width: 20 },
        { key: 'date_of_foc', width: 20 }
      ];

      dataToExport.forEach(item => {
        const row = sheet.addRow({
          booking_id: item.booking_id,
          partner_name: item.partner_name,
          room_type: item.room_type,
          source_name: item.source_name,
          room_qty: item.room_qty,
          guest_level: mappedGuestLevel(item),
          room_price: formatInputMoney(item.room_price),
          check_in: item.check_in,
          check_out: item.check_out,
          date_of_foc: item.date_of_foc
        });

        row.eachCell({ includeEmpty: true }, cell => {
          cell.border = {
            top: { style: 'thin' },
            left: { style: 'thin' },
            bottom: { style: 'thin' },
            right: { style: 'thin' }
          };
          cell.alignment = { horizontal: 'center' };
        });
      });

      const totalRowIndex = dataToExport.length + 6;
      ['A', 'B', 'C', 'D', 'E', 'F', 'G'].forEach(col => {
        sheet.getCell(`${col}${totalRowIndex}`).border = {
          top: { style: 'thin', color: { argb: 'FF000000' } },
          bottom: { style: 'thin', color: { argb: 'FF000000' } },
          left: { style: 'thin', color: { argb: 'FF000000' } },
          right: { style: 'thin', color: { argb: 'FF000000' } }
        };
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (e) {
      return { status: 'ERROR', error: e };
    } finally {
      setIsLoadingExport(false);
    }
  };

  const handleClickMenu = (menu: any) => {
    handleMenuClick(menu.key, exportToCSV);
  };
  const items: MenuProps['items'] = useMemo(() => generateMenuItems(), []);

  return (
    <Dropdown
      menu={{ items, onClick: handleClickMenu }}
      trigger={['click']}
      overlayClassName="menu-user"
    >
      <Button icon={<DownloadOutlined />} loading={isLoadingExport}>
        {t('common.actions.exportData')}
      </Button>
    </Dropdown>
  );
};

export default ExportFocsReport;
