import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { Button, Card, Col, Form, FormProps, Input, Modal, notification, Row, Select } from 'antd';
import useModal from 'stores/useModal';
import { useMutation } from '@tanstack/react-query';
import {
  buildTransactionsInvoice,
  updateInvoiceDetails,
  einvoiceUpdateCustomer
} from 'services/api/module/booking.api';
import 'styles/post-payment-transaction.scss';
import { toLocalTime } from 'utils';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { EmailRegex } from 'constants/regex';
import { MESSAGE_CODE } from 'constants/validate';
import { useGetBookingDetail } from 'hooks/useGetBookingDetail';
import {
  useGetGuestOrderDetail,
  useGetGuestOrders,
  useGetMinvoicePreview
} from 'hooks/useGetGuestTransactions';
import 'styles/create-invoice-modal.scss';
import { useGetTravelAgencies, useGetTravelAgency } from 'hooks/useGetTravelAgencies';
import { useTranslation } from 'react-i18next';
import InvoicePreviewModal from 'components/modal/invoice-preview';
import debounce from 'lodash/debounce';

function CreateInvoice() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { createInvoiceTransaction, closeCreateInvoiceTransaction, setIsOpenViewPrintInvoiceV2 } =
    useModal();
  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);
  const [travelAgencyInput, setTravelAgencyInput] = useState<string>('');

  const { data: bookingLineDetail } = useGetBookingDetail(createInvoiceTransaction.booking_line_id);
  const { data: travelAgencyData } = useGetTravelAgency(bookingLineDetail?.hotel_travel_agency_id);
  const { data: orders } = useGetGuestOrders(Number(createInvoiceTransaction?.guest_id));

  const { data: TravelAgencies, isLoading } = useGetTravelAgencies({ name: travelAgencyInput });

  const currentOrder = useMemo(() => {
    return orders
      ?.filter((item: any) => item?.folio_balance_code === createInvoiceTransaction.code)
      .at(0);
  }, [createInvoiceTransaction, orders]);

  const currentCustomerBookingData = useMemo(() => {
    return bookingLineDetail?.booking_line_guests
      .filter(item => item.id === currentOrder?.booking_line_guest_id)
      .at(0);
  }, [bookingLineDetail?.booking_line_guests, currentOrder?.booking_line_guest_id]);

  const { data: orderDetail } = useGetGuestOrderDetail(currentOrder ? currentOrder.id : 0);
  const { data: minvoicePreviewPDF, isFetching } = useGetMinvoicePreview(
    Number(orderDetail?.id),
    currentOrder?.state === 'invoiced'
  );

  const { mutateAsync: mutateBuildTransactionsInvoice } = useMutation({
    mutationFn: (params: any) =>
      buildTransactionsInvoice({
        guest_order_id: params.guest_order_id,
        publish_einvoice: true,
        name: params.name,
        vat: params.vat,
        email: params.email,
        company_name: params.company_name,
        address: params.address
      })
  });

  const { mutateAsync: mutateEinvoiceUpdateCustomer } = useMutation({
    mutationFn: (params: any) =>
      einvoiceUpdateCustomer({
        guest_order_id: params.guest_order_id,
        name: params.name,
        vat: params.vat,
        email: params.email,
        company_name: params.company_name,
        address: params.address
      })
  });

  const { mutateAsync: UpdateInvoiceDetails } = useMutation({
    mutationFn: async (values: any) => {
      const id = bookingLineDetail?.booking_line_id;
      if (id === undefined) {
        return;
      }
      const data = {
        partner_name: values.partner_name || '',
        company_name: values.company || '',
        company_address: values.company_address || '',
        tax_code: values.tax_code || '',
        email: values.email || '',
        publish_einvoice: true
      };

      const bookingDetail = await updateInvoiceDetails(id, data);
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL, id]
      });
      return bookingDetail;
    }
  });

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setTravelAgencyInput(value);
    }, 300),
    []
  );

  const handleCloseModal = () => {
    form.resetFields();
    closeCreateInvoiceTransaction();
    setIsPreviewOpen(false);
  };

  const handleShowInvoice = async () => {
    setIsOpenViewPrintInvoiceV2(true);
  };

  const handlePreviewMinvoice = async () => {
    setIsPreviewOpen(true);
  };

  const handleClosePreviewModal = () => {
    setIsPreviewOpen(false);
  };

  const handleGetTravelAgencyData = (value: string) => {
    debouncedSearch(value);
  };

  const handleCreateInvoice = async () => {
    const { name, company_name, address, vat, email } = form.getFieldsValue();
    try {
      await UpdateInvoiceDetails({
        partner_name: name,
        company: company_name,
        company_address: address,
        tax_code: vat,
        email: email
      });
      form.submit();
    } catch (error: any) {
      notification.error({
        message: error.error || t('common.error')
      });
    }
  };

  const handleSaveInfo = async () => {
    const { name, company_name, address, vat, email } = form.getFieldsValue();
    try {
      await UpdateInvoiceDetails({
        partner_name: name,
        company: company_name,
        company_address: address,
        tax_code: vat,
        email: email
      });
      if (currentOrder.einvoice_published && !currentOrder?.einvoice_signed) {
        await mutateEinvoiceUpdateCustomer({
          guest_order_id: currentOrder.id,
          name: name,
          vat: vat,
          email: email,
          company_name: company_name,
          address: address
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_MINVOICE_PREVIEW, currentOrder.id]
        });
      }
      notification.success({
        message: t('common.message.saveInvoiceInfo')
      });
    } catch (err: any) {
      notification.error({
        message: err.error || t('common.error')
      });
    }
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    if (createInvoiceTransaction.guest_id && createInvoiceTransaction.code) {
      try {
        await mutateBuildTransactionsInvoice({
          guest_order_id: currentOrder.id,
          name: values.name,
          vat: values.vat,
          email: values.email,
          company_name: values.company_name,
          address: values.address
        });
        notification.success({
          message: t('common.message.createInvoice')
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, createInvoiceTransaction.guest_id]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL]
        });
        await queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.KEY_ROOM,
            QUERY_KEYS.GET_BOOKING_DETAIL,
            bookingLineDetail?.booking_line_id
          ]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_MINVOICE_PREVIEW, currentOrder.id]
        });
      } catch (error: any) {
        notification.error({
          message: error.error || t('common.error')
        });
      }
    }
  };

  useEffect(() => {
    if (createInvoiceTransaction.isOpenCreateInvoice) {
      const emailValue =
        bookingLineDetail?.invoice_details?.email ||
        travelAgencyData?.email ||
        currentCustomerBookingData?.email ||
        '';

      if (bookingLineDetail?.invoice_details) {
        form.setFieldsValue({
          name: bookingLineDetail?.invoice_details.partner_name,
          email: emailValue,
          address: bookingLineDetail?.invoice_details.company_address,
          company_name: bookingLineDetail?.invoice_details.company_name,
          vat: bookingLineDetail?.invoice_details.tax_code
        });
      } else {
        form.setFieldsValue({
          name: '',
          email: emailValue,
          address: travelAgencyData?.address,
          company_name: travelAgencyData?.invoice_name,
          vat: travelAgencyData?.vat
        });
      }
    } else {
      form.resetFields();
    }
  }, [
    orderDetail,
    form,
    currentCustomerBookingData,
    travelAgencyData,
    bookingLineDetail,
    createInvoiceTransaction.isOpenCreateInvoice
  ]);

  return (
    <Modal
      title={t('cashierPage.invoice.title')}
      className="modal-create-invoice"
      centered
      width={750}
      open={createInvoiceTransaction.isOpenCreateInvoice}
      cancelText={t('common.actions.cancel')}
      onOk={handleCreateInvoice}
      onCancel={handleCloseModal}
      destroyOnClose
      footer={[
        <Button
          key="save_info"
          type="primary"
          className="ant-btn-secondary"
          onClick={handleSaveInfo}
          style={currentOrder?.state === 'invoiced' ? { display: 'none' } : {}}
        >
          {t('cashierPage.invoice.saveInfo')}
        </Button>,
        <Button
          key="print_invoice"
          type="primary"
          className="ant-btn-secondary"
          onClick={handleShowInvoice}
        >
          {t('cashierPage.invoice.printInvoice')}
        </Button>,
        <Button
          key="create_or_preview"
          type="primary"
          className="ant-btn-secondary"
          onClick={currentOrder?.state !== 'invoiced' ? handleCreateInvoice : handlePreviewMinvoice}
        >
          {currentOrder?.state !== 'invoiced'
            ? t('cashierPage.invoice.createInvoice')
            : t('cashierPage.invoice.previewMinvoice')}
        </Button>,
        <Button key="cancel" className="btn-cancel" onClick={handleCloseModal}>
          {t('cashierPage.invoice.cancel')}
        </Button>
      ]}
    >
      <Card title="RESERVATION INFORMATION" className="reservation-info">
        <Row gutter={[16, 0]}>
          <Col span={4} className="col-bold">
            <p>{t('cashierPage.invoice.resv')}</p>
          </Col>
          <Col span={4}>
            <p>{bookingLineDetail?.booking_line_id}</p>
          </Col>
          <Col span={4} className="col-bold">
            <p>{t('cashierPage.invoice.confirm')}</p>
          </Col>
          <Col span={4}>
            <p>{bookingLineDetail?.booking_id}</p>
          </Col>
          <Col span={4} className="col-bold">
            <p>{t('cashierPage.common.room')}</p>
          </Col>
          <Col span={4}>
            <p>
              {bookingLineDetail?.room_name
                ? bookingLineDetail.room_name
                : bookingLineDetail?.room_type_name}
            </p>
          </Col>
          <Col span={4} className="col-bold">
            <p>{t('cashierPage.invoice.arrivalDate')}</p>
          </Col>
          <Col span={4}>
            <p>
              {bookingLineDetail?.check_in
                ? toLocalTime(bookingLineDetail.check_in, 'DD/MM/YYYY')
                : ''}
            </p>
          </Col>
          <Col span={4} className="col-bold">
            <p>{t('cashierPage.invoice.departureDate')}</p>
          </Col>
          <Col span={4}>
            <p>
              {bookingLineDetail?.check_out
                ? toLocalTime(bookingLineDetail.check_out, 'DD/MM/YYYY')
                : ''}
            </p>
          </Col>
          {travelAgencyData && (
            <>
              <Col span={4} className="col-bold">
                <p>{t('common.customer.group')}</p>
              </Col>
              <Col span={4}>
                <p>{travelAgencyData.name}</p>
              </Col>
            </>
          )}
        </Row>
      </Card>

      <Form
        form={form}
        name="create-invoice-form"
        layout="vertical"
        style={{ width: '100%' }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Card title={t('cashierPage.invoice.guestInfo')} className="guest-info">
          <Row gutter={[16, 0]}>
            <Col span={4} className="label">
              <p>{t('cashierPage.common.guestName')}</p>
            </Col>
            <Col span={20}>
              <Form.Item name="name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={4} className="label">
              <p>{t('common.customer.company')}</p>
            </Col>
            <Col span={20}>
              <Form.Item name="company_name">
                <Select
                  showSearch
                  optionFilterProp="children"
                  onSearch={handleGetTravelAgencyData}
                  onChange={value => {
                    if (value === undefined) {
                      setTravelAgencyInput('');
                      form.resetFields(['company_name', 'address', 'vat', 'email']);
                    }
                    const selectedAgency = TravelAgencies?.find(agency => agency.id === value);
                    if (selectedAgency) {
                      form.setFieldsValue({
                        company_name: selectedAgency.invoice_name || selectedAgency.name,
                        address: selectedAgency.address || '',
                        vat: selectedAgency.vat || '',
                        email: selectedAgency.email || ''
                      });
                    }
                  }}
                  allowClear
                  filterOption={false}
                  loading={isLoading}
                >
                  {TravelAgencies?.map(agency => (
                    <Select.Option key={agency.id} value={agency.id}>
                      {agency.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={4} className="label">
              <p>
                {t('common.customer.taxCode')} <span className="required">*</span>
              </p>
            </Col>
            <Col span={20}>
              <Form.Item
                name="vat"
                rules={[{ required: true, message: MESSAGE_CODE.REQUIRED_VAT_CODE }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={4} className="label">
              <p>{t('common.customer.email')}</p>
            </Col>
            <Col span={20}>
              <Form.Item
                name="email"
                rules={[
                  {
                    pattern: EmailRegex,
                    message: MESSAGE_CODE.WRONG_FORMAT_EMAIL
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={4} className="label">
              <p>{t('common.customer.address')}</p>
            </Col>
            <Col span={20}>
              <Form.Item name="address">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
      <InvoicePreviewModal
        isOpen={isPreviewOpen}
        previewUrl={minvoicePreviewPDF?.preview_url}
        isLoading={isFetching}
        guestOrderId={currentOrder?.id}
        currentOrder={currentOrder}
        onClose={handleClosePreviewModal}
      />
    </Modal>
  );
}

export default CreateInvoice;
