import { useEffect, useState } from 'react';
import Sendbird from 'sendbird';
import { usePermissions } from 'AuthorizePermission';
import { PermissionActionTypes } from 'helper/type/action.type';

function UnReadMessages() {
  const { hasPermission } = usePermissions();
  const APP_ID = process.env.REACT_APP_SEND_BIRD_APP_ID as string;
  const USER_ID = process.env.REACT_APP_SEND_BIRD_USER_ID as string;
  const accessToken = process.env.REACT_APP_SEND_BIRD_ACCESS_TOKEN as string;
  const [unreadCount, setUnreadCount] = useState<number>(0);

  useEffect(() => {
    if (!APP_ID || !USER_ID || !accessToken || !hasPermission(PermissionActionTypes.CHAT_BOX)) {
      return;
    }

    const sb = new Sendbird({ appId: APP_ID });

    const connectUser = async () => {
      try {
        await sb.connect(USER_ID, accessToken);

        const updateUnreadCount = async () => {
          const groupChannelListQuery = sb.GroupChannel.createMyGroupChannelListQuery();
          const channels = await groupChannelListQuery.next();
          const totalUnread = channels.reduce((acc, channel) => {
            return acc + channel.unreadMessageCount;
          }, 0);
          setUnreadCount(totalUnread);
        };

        await updateUnreadCount();

        const channelHandler = new sb.ChannelHandler();

        channelHandler.onMessageReceived = () => {
          setUnreadCount(prevCount => prevCount + 1);
        };

        channelHandler.onReadReceiptUpdated = () => {
          updateUnreadCount();
        };

        channelHandler.onChannelChanged = () => {
          updateUnreadCount();
        };

        sb.addChannelHandler('unread-messages-handler', channelHandler);

        return () => {
          sb.removeChannelHandler('unread-messages-handler');
          sb.disconnect();
        };
      } catch (err) {
        console.error('Error connecting to Sendbird:', err);
        return () => {};
      }
    };
    connectUser();
  }, [APP_ID, USER_ID, accessToken, hasPermission]);

  return unreadCount;
}

export default UnReadMessages;
