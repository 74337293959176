import { MenuProps } from 'antd';
import { ReactNode } from 'react';

export enum PermissionActionTypes {
  CHANGE_DATE = 'change_date',
  CHANGE_ROOM = 'change_room',
  ASSIGN_ROOM = 'assign_room',
  UNASSIGN_ROOM = 'unassign_room',
  CHECK_IN = 'check_in',
  CHECK_OUT = 'check_out',
  POST_ROOM_CHARGE = 'post_room_charge',
  MOVE_TRANSACTION = 'move_transaction',
  PAYMENT = 'payment',
  ADD_SERVICE = 'add_service',
  VOID_TRANSACTION = 'void_transaction',
  UNDO_VOIDED_TRANSACTION = 'undo_void_transaction',
  REFUND = 'refund',
  BOOKING_LIST_DETAIL = 'booking_list_detail',
  DOUBLE_ORDER = 'double_order',
  CANCEL_BOOKING = 'cancel_booking',
  UNDO_CANCELLED_BOOKING = 'undo_cancel_booking',
  BOOKING_LIST_EXPORT = 'booking_list_export',
  ROOM_LOG = 'room_log',
  HK_SERVICE = 'hk_service',
  INVOICE = 'invoice',
  CREATE_NEW_GROUP = 'create_new_group',
  TRANSACTION_LIST_EXPORT = 'transaction_list_export',
  TA_MANAGEMENT_CREATE = 'ta_management_create',
  TA_MANAGEMENT_DELETE = 'ta_management_delete',
  INCOME_EXPENSE_CREATE = 'income_expense_create',
  MOVE_SALE_ORDER = 'move_sale_order',
  EDIT_RESERVATION = 'edit_reservation',
  INCOME_EXPENSE_DELETE = 'income_expense_delete',
  SET_ROOM_STATUS = 'set_room_status',
  CHAT_BOX = 'chat_box'
}

export type CustomMenuItem = {
  permission?: string;
  onclick?: () => void;
  disable?: boolean;
  label?: ReactNode;
} & Required<MenuProps>['items'][number];
