import React from 'react';

export const LoadingDots = () => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      gap: '8px'
    }}
  >
    <div
      style={{
        width: '10px',
        height: '10px',
        backgroundColor: '#1890ff',
        borderRadius: '50%',
        animation: 'dotFlashing 1s infinite linear alternate',
        animationDelay: '0s'
      }}
    />
    <div
      style={{
        width: '10px',
        height: '10px',
        backgroundColor: '#1890ff',
        borderRadius: '50%',
        animation: 'dotFlashing 1s infinite linear alternate',
        animationDelay: '0.5s'
      }}
    />
    <div
      style={{
        width: '10px',
        height: '10px',
        backgroundColor: '#1890ff',
        borderRadius: '50%',
        animation: 'dotFlashing 1s infinite linear alternate',
        animationDelay: '1s'
      }}
    />
    <style>
      {`
        @keyframes dotFlashing {
          0% { opacity: 0.2; }
          50%, 100% { opacity: 1; }
        }
      `}
    </style>
  </div>
);
