import { useMemo, useState } from 'react';
import ExcelJS from 'exceljs';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as FileSaver from 'file-saver';
import { handleMenuClick, generateMenuItems } from 'utils/excelUtils';

interface Props {
  fileName: string;
  dataToExport: any;
  t: (key: string) => string;
}

const ExportBreakfastList = ({ fileName, dataToExport, t }: Props) => {
  const [isLoadingExport, setIsLoadingExport] = useState(false);

  const exportToCSV = async () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    try {
      setIsLoadingExport(true);

      const workbook = new ExcelJS.Workbook();
      const sheetName = `Breakfasts`;
      const sheet = workbook.addWorksheet(sheetName, {
        views: [
          {
            showGridLines: false
          }
        ]
      });

      sheet.mergeCells('A2:G2');
      sheet.getCell('A2').value = 'Danh sách ăn sáng';
      sheet.getCell('A2').font = { size: 14, bold: true };
      sheet.getCell('A2').alignment = { horizontal: 'center' };

      // Header row
      sheet.getRow(4).values = [
        'Mã đặt phòng',
        'Tên khách',
        'Phòng',
        'Người lớn',
        'Trẻ em',
        'Ngày đến',
        'Ngày đi'
      ];
      sheet.getRow(4).eachCell(cell => {
        cell.border = {
          top: { style: 'medium', color: { argb: 'FF000000' } },
          bottom: { style: 'medium', color: { argb: 'FF000000' } },
          left: { style: 'medium', color: { argb: 'FF000000' } },
          right: { style: 'medium', color: { argb: 'FF000000' } }
        };
        cell.font = { bold: true };
        cell.alignment = { horizontal: 'center' };
      });

      sheet.columns = [
        { key: 'booking_id', width: 20 },
        { key: 'guest', width: 30 },
        { key: 'room_name', width: 15 },
        { key: 'adult', width: 10 },
        { key: 'child', width: 10 },
        { key: 'arrival', width: 20 },
        { key: 'departure', width: 20 }
      ];

      let rowIndex = 5;
      let startRow = 5;

      dataToExport.forEach((item: any, index: number) => {
        const nextItem = dataToExport[index + 1];
        const row = sheet.addRow({
          booking_id: item.booking_id,
          guest: item.guest,
          room_name: item.room_name,
          adult: item.adult,
          child: item.child,
          arrival: item.arrival,
          departure: item.departure
        });

        row.eachCell({ includeEmpty: true }, cell => {
          cell.border = {
            top: { style: 'medium' },
            left: { style: 'medium' },
            bottom: { style: 'medium' },
            right: { style: 'medium' }
          };
          cell.alignment = { horizontal: 'center' };
        });

        const nextBookingId = nextItem ? nextItem.booking_id : '';
        const nextRoomType = nextItem ? nextItem.room_name : '';

        if (item.booking_id !== nextBookingId || !nextItem || item.room_name !== nextRoomType) {
          sheet.mergeCells(`A${startRow}:A${rowIndex}`);
          sheet.mergeCells(`C${startRow}:C${rowIndex}`);
          sheet.mergeCells(`D${startRow}:D${rowIndex}`);
          sheet.mergeCells(`E${startRow}:E${rowIndex}`);

          startRow = rowIndex + 1;
        }

        rowIndex++;
      });

      const totalRowIndex = dataToExport.length + 4;
      ['A', 'B', 'C', 'D', 'E', 'F', 'G'].forEach(col => {
        sheet.getCell(`${col}${totalRowIndex}`).border = {
          top: { style: 'medium', color: { argb: 'FF000000' } },
          bottom: { style: 'medium', color: { argb: 'FF000000' } },
          left: { style: 'medium', color: { argb: 'FF000000' } },
          right: { style: 'medium', color: { argb: 'FF000000' } }
        };
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (e) {
      return { status: 'ERROR', error: e };
    } finally {
      setIsLoadingExport(false);
    }
  };

  const handleClickMenu = (menu: any) => {
    handleMenuClick(menu.key, exportToCSV);
  };
  const items: MenuProps['items'] = useMemo(() => generateMenuItems(), []);

  return (
    <Dropdown
      menu={{ items, onClick: handleClickMenu }}
      trigger={['click']}
      overlayClassName="menu-user"
    >
      <Button icon={<DownloadOutlined />} loading={isLoadingExport}>
        {t('common.actions.exportData')}
      </Button>
    </Dropdown>
  );
};

export default ExportBreakfastList;
