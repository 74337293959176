import _ from 'lodash';
import React, { useState } from 'react';
import { Button, Checkbox, CheckboxProps, List } from 'antd';
import { useMutation } from '@tanstack/react-query';

import { useGetRooms } from 'hooks/useGetRooms';
import { cleanRoom } from 'services/api/module/room.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import 'styles/room-status.scss';
import { useTranslation } from 'react-i18next';

function RoomStatus() {
  const { t } = useTranslation();
  const { allRooms, isLoading } = useGetRooms();
  const [checkedList, setCheckedList] = useState<number[]>([]);

  const { mutateAsync: mutateDirtyRoom, isPending: isPendingDirty } = useMutation({
    mutationFn: ({ ids, is_clean }: { ids: number[]; is_clean: boolean }) => {
      return cleanRoom(ids, is_clean);
    }
  });
  const { mutateAsync: mutateCleanRoom, isPending: isPendingCleaning } = useMutation({
    mutationFn: ({ ids, is_clean }: { ids: number[]; is_clean: boolean }) => {
      return cleanRoom(ids, is_clean);
    }
  });

  const checkAll = allRooms.length === checkedList.length;
  const indeterminate = checkedList.length > 0 && checkedList.length < allRooms.length;

  const handleChangeCheckRoom = (e: any) => {
    const cloneCheckedList = _.cloneDeep(checkedList);
    if (cloneCheckedList.includes(e.target.value)) {
      _.remove(cloneCheckedList, value => value === e.target.value);
    } else {
      cloneCheckedList.push(e.target.value);
    }
    setCheckedList(cloneCheckedList);
  };

  const onCheckAllChange: CheckboxProps['onChange'] = (e: any) => {
    setCheckedList(e.target.checked ? allRooms.map(item => item.id) : []);
  };

  const handleMakeDirty = async () => {
    await mutateDirtyRoom({
      ids: checkedList,
      is_clean: false
    });

    // TODO: update change FE data instead of refetch data
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_ROOM_MAP]
    });
    setCheckedList([]);
  };
  const handleClean = async () => {
    await mutateCleanRoom({
      ids: checkedList,
      is_clean: true
    });
    // TODO: update change FE data instead of refetch data
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_ROOM_MAP]
    });
    setCheckedList([]);
  };

  return (
    <div className="pms-room-status">
      {!!allRooms.length && (
        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
          <span className="check-all">{t('common.actions.chooseAll')}</span>
        </Checkbox>
      )}

      <div className="pms-room-status__list">
        <List
          loading={isLoading}
          dataSource={allRooms}
          renderItem={item => (
            <List.Item>
              <Checkbox
                value={item.id}
                checked={checkedList.includes(item.id)}
                onChange={handleChangeCheckRoom}
              >
                {item.name}
                {/* {item.id} {item.type} */}
              </Checkbox>
              {!item.is_clean && <span>{t('common.statuses.dirty')}</span>}
            </List.Item>
          )}
        />

        {/* <div className="pms-room-status__list-pagination">
          <Pagination
            showSizeChanger
            current={currentPage}
            total={50}
            onChange={setCurrentPage}
            locale={{ items_per_page: "/ trang" }}
            showTotal={(total) => `${total} items`}
          />
        </div> */}
      </div>

      <div className="pms-room-status__action">
        <Button.Group style={{ gap: '8px' }}>
          <Button type="primary" onClick={handleMakeDirty} loading={isPendingDirty}>
            {t('roomPage.roomStatus.makeDirty')}
          </Button>
          <Button className="ant-btn-secondary" onClick={handleClean} loading={isPendingCleaning}>
            {t('roomPage.roomStatus.makeClean')}
          </Button>
        </Button.Group>
      </div>
    </div>
  );
}

export default RoomStatus;
