import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { Button, Card, Input, Form, FormProps, Table, notification, DatePicker } from 'antd';
import { useMutation } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import { nanoid } from 'nanoid';

import { useGetRoomLock } from 'hooks/useGetRoomLock';
import useBookingStore from 'stores/useBooking';
import useModal from 'stores/useModal';
import { useGetAvailRoomType } from 'hooks/useGetAvailRoomType';
import ExportRoomLock from 'components/common/export-room-lock';

import { getColumnRoomLock } from 'constants/table';
import { unlockRoom } from 'services/api/module/room.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { handleRangeDateChange, toUtcTime } from 'utils';
import { RoomLockType } from 'services/api/type/room.type';
import 'styles/room-lock.scss';

import { useTranslation } from 'react-i18next';

const initStartDate = dayjs().set('hour', 14).set('minute', 0).set('second', 0);
const initEndDate = dayjs().add(1, 'day').set('hour', 12).set('minute', 0).set('second', 0);

function RoomLock() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { setRoom } = useBookingStore();
  const { setIsOpenFixRoom, setInfoConfirmModal, setConfirmLoading } = useModal();
  const rangeDateForm = Form.useWatch('rangeDate', form);

  const [page, setPage] = useState<number>(1);
  const [filterOptions, setFilterOptions] = useState<any>();
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);

  const { data, isFetching } = useGetRoomLock(filterOptions);

  const { data: availRoomTypes } = useGetAvailRoomType(
    toUtcTime(_.get(rangeDateForm, '0') || initStartDate, 'YYYY-MM-DD HH:mm:ss'),
    toUtcTime(_.get(rangeDateForm, '1') || initEndDate, 'YYYY-MM-DD HH:mm:ss')
  );

  const { mutateAsync: mutateUnlock } = useMutation({
    mutationFn: (lock_ids: React.Key[]) => unlockRoom({ lock_ids })
  });

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});
    const { rangeDate, ...restFilterOptions } = truthyValues;
    if (rangeDate) {
      const checkInFrom: Dayjs = rangeDate[0];
      const checkInTo: Dayjs = rangeDate[1];
      if (checkInFrom) {
        _.set(restFilterOptions, 'start_date', checkInFrom.format('YYYY-MM-DD HH:mm:ss'));
      }
      if (checkInTo) {
        _.set(restFilterOptions, 'end_date', checkInTo.format('YYYY-MM-DD HH:mm:ss'));
      }
    }
    if (page !== 1) {
      setPage(1);
    }
    setFilterOptions(restFilterOptions);
  };

  const onFinishFailed: FormProps['onFinishFailed'] = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleUnlockSelected = () => {
    setInfoConfirmModal(true, {
      title: 'Xác nhận mở khóa phòng',
      okText: 'Xác nhận',
      onOk: async () => {
        if (!_.isEmpty(selectedRowKeys)) {
          try {
            setConfirmLoading(true);
            await mutateUnlock(
              selectedRowKeys
                .filter(key => !String(key).includes('root'))
                .map(key => String(key).split('-')[1])
            );

            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.GET_ROOM_LOCK]
            });
            setInfoConfirmModal(false);
            setSelectedRowKeys([]);
            notification.success({
              message: 'Mở khóa phòng thành công'
            });
          } catch (err: any) {
            notification.error({
              message: 'Mở khóa phòng thất bại'
            });
          } finally {
            setConfirmLoading(false);
          }
        }
      }
    });
  };

  const handleOpenFixRoomModal = () => {
    setIsOpenFixRoom(true, /* isCreateFixRoom = */ true);
  };

  const cookedData = useMemo(() => {
    const hash = {} as any;
    const roomLocks: RoomLockType[] = _.clone(data);

    roomLocks.forEach((item: RoomLockType) => {
      if (!hash[item.room_type_name]) {
        const pushedItem = _.cloneDeep(item);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        delete pushedItem.room_type_name;

        hash[item.room_type_name] = {
          id: 'root',
          room_type_name: item.room_type_name,
          hideCheckbox: true,
          children: [_.cloneDeep(pushedItem)]
        };
      } else {
        const pushedItem = _.cloneDeep(item);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        delete pushedItem.room_type_name;

        hash[item.room_type_name].children.push(_.cloneDeep(pushedItem));
      }
    });

    const sortRoomType = _.orderBy(availRoomTypes, 'price', 'asc');
    Object.keys(hash).forEach(key => {
      hash[key].children = _.orderBy(
        hash[key].children,
        ['attributes.room_no', 'start_date', 'end_date'],
        ['asc', 'asc', 'asc']
      );
    });

    return sortRoomType
      .filter(item => item.room_type_name in hash)
      .map(item => hash[item.room_type_name]);
  }, [availRoomTypes, data]);

  const columnRoomLock = useMemo(() => {
    const handleEdit = (id: number) => {
      const roomInfo = data?.find(room => room.id === id);
      if (roomInfo) {
        setRoom(roomInfo.room_id, roomInfo.room_name, {
          start_date: roomInfo.start_date,
          end_date: roomInfo.end_date,
          reason: roomInfo.reason,
          lock_id: roomInfo.id
        });
        setIsOpenFixRoom(true);
      }
    };

    return getColumnRoomLock(t, false, handleEdit);
  }, [data, setIsOpenFixRoom, setRoom, t]);

  return (
    <div className="pms-room-lock">
      <Card title={t('common.statuses.all')} style={{ width: '100%' }}>
        <Form
          form={form}
          name="room-lock-filter-form"
          layout="inline"
          initialValues={{
            search: '',
            rangeDate: null
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="pms-room-lock__filter">
            <div className="flex items-center flex-wrap" style={{ gap: '8px 16px' }}>
              <Form.Item name="search" style={{ marginRight: 0 }}>
                <Input placeholder={t('bookingPage.roomLock.roomNo')} />
              </Form.Item>
              <Form.Item name="rangeDate">
                <DatePicker.RangePicker
                  className="w-full"
                  placeholder={[t('common.time.startDate'), t('common.time.endDate')]}
                  format="DD-MM-YYYY HH:mm"
                  onChange={value => {
                    handleRangeDateChange(form, value);
                  }}
                />
              </Form.Item>

              <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                {t('common.actions.submitButton')}
              </Button>

              <Button
                type="primary"
                onClick={handleUnlockSelected}
                disabled={selectedRowKeys.length === 0}
              >
                {t('bookingPage.roomLock.unlockRoom')}
              </Button>

              <Button type="primary" onClick={handleOpenFixRoomModal}>
                {t('bookingPage.roomLock.createLockRoom')}
              </Button>
            </div>

            <ExportRoomLock
              filterOptions={{
                filterOptions: filterOptions
              }}
              fileName={`room-lock-${(_.get(rangeDateForm, '0') || initStartDate)?.format(
                'DD-MM-YYYY'
              )}-${(_.get(rangeDateForm, '1') || initEndDate)?.format('DD-MM-YYYY')}-${nanoid()}`}
              availRoomTypes={availRoomTypes}
            />
          </div>
        </Form>

        <div className="pms-room-lock__table">
          <Table
            loading={isFetching}
            rowKey={record => `${record.room_type_name}-${record.id}`}
            columns={columnRoomLock}
            dataSource={cookedData}
            locale={{
              emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
            }}
            scroll={{ x: 500, y: 'calc(100vh - 400px)' }}
            rowSelection={{
              selectedRowKeys,
              onChange: onSelectChange,
              getCheckboxProps: (record: RoomLockType) => ({
                style: record.hideCheckbox ? { display: 'none' } : {} // Hide the checkbox visually
              })
            }}
            pagination={false}
          />
        </div>
      </Card>
    </div>
  );
}

export default RoomLock;
