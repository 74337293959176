export const MESSAGE_CODE: Record<string, string> = {
  // REQUIRED_EMAIL: "Common.ValidateMessage.RequiredEmail",
  // WRONG_FORMAT_EMAIL: "Common.ValidateMessage.WrongEmail",
  // REQUIRED_PASSWORD: "Common.ValidateMessage.RequiredPassword",
  REQUIRED_EMAIL: 'Hãy nhập email của bạn',
  WRONG_FORMAT_EMAIL: 'Email của bạn chưa đúng',
  REQUIRED_PASSWORD: 'Hãy nhập mật khẩu của bạn',
  REQUIRED_FULL_NAME: 'Hãy nhập họ và tên',
  REQUIRED_DATE_TIME: 'Hãy chọn ngày giờ',
  REQUIRED_LAST_NAME: 'Hãy nhập họ',
  REQUIRED_FIRST_NAME: 'Hãy nhập tên',
  REQUIRED_PHONE_NUMBER: 'Hãy nhập số điện thoại của bạn',
  REQUIRED_DOB: 'Hãy chọn ngày sinh',
  REQUIRED_ID: 'Hãy nhập ID/passport',
  REQUIRED_NATIONALITY: 'Hãy chọn quốc tịch',
  REQUIRED_LEVEL: 'Hãy chọn cấp bậc',

  REQUIRED_OTP: 'Hãy nhập mã xác nhận',
  REQUIRED_SERVICE_NAME: 'Hãy nhập tên dịch vụ',
  REQUIRED_SERVICE_NAME_PRICE: 'Hãy nhập giá dịch vụ',
  REQUIRED_REASON_FIX_ROOM: 'Hãy nhập lý do',
  REQUIRED_CUSTOMER: 'Hãy chọn người nhận',
  REQUIRED_EXPENSE_TYPE: 'Hãy chọn nội dung chi',
  REQUIRED_AMOUNT: 'Hãy nhập tổng tiền',
  REQUIRED_TRAVEL_AGENCY_NAME: 'Hãy nhập tên công ty',
  REQUIRED_INVOICE_NAME: 'Hãy nhập tên hóa đơn',
  REQUIRED_MARKET: 'Hãy chọn phân khúc thị trường',
  REQUIRED_SOURCE: 'Hãy chọn nguồn',

  REQUIRED_CURRENT_PASSWORD: 'Hãy nhập mật khẩu hiện tại',
  REQUIRED_NEW_PASSWORD: 'Hãy nhập mật khẩu mới',
  REQUIRED_NEW_PASSWORD_MIN: 'Mật khẩu cần 6 ký tự trở lên',
  REQUIRED_CONFIRM_PASSWORD: 'Hãy nhập xác nhận mật khẩu mới',
  PASSWORD_NOT_MATCH: 'Mật khẩu xác nhận chưa trùng khớp',

  INVALID_CREDS: 'Sai email hoặc mật khẩu',
  REQUIRED_GROUP_NAME: 'Hãy nhập tên đoàn',
  REQUIRED_TA_COMPANY: 'Hãy chọn công ty',
  REQUIRED_ROOM_TYPE: 'Hãy chọn loại phòng',
  REQUIRED_NUMBER_ROOM: 'Hãy nhập số phòng',
  REQUIRED_PAX: 'Hãy nhập số khách',
  REQUIRED_RATE_AMOUNT: 'Hãy nhập giá phòng',

  REQUIRED_TRANSACTION_PAGE: 'Please select a transaction page',
  REQUIRED_TRANSACTION_DATE: 'Please select a transaction date',
  REQUIRED_TRANSACTION_TYPE: 'Please select a transaction type',
  REQUIRED_ADDON_SERVICE_TAX: 'Please select a service tax',

  REQUIRED_VAT_CODE: 'Please input VAT code',
  REQUIRED_GUEST_NAME: 'Please input the Guest name',
  REQUIRED_COMPANY_INVOICE_PAGE: 'Please input the Company name'
};
