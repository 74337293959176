import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import useCommonStore from 'stores/useCommon';
import { toLocalTime } from 'utils';
import { ACTION_EXPORT_DATA } from 'constants/common';
import { TITLE_STATUS_OPTION } from 'constants/form';
import { getAllBookings } from 'services/api/module/booking.api';
import { BookingLine } from 'services/api/type/booking.type';
import Logo from 'assets/images/logo.png';

interface Props {
  fileName: string;
  filterOptions: any;
  statusForm: string;
  t: (key: string) => string;
}

const ExportArrivalGuest = ({ fileName, filterOptions, statusForm, t }: Props) => {
  const { currentBranch } = useCommonStore();
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const handleClickMenu = (menu: any) => {
    switch (menu.key) {
      case ACTION_EXPORT_DATA.EXCEL:
        exportToCSV();
        break;
      default:
        break;
    }
  };

  const formatDate = (date?: string): string => {
    if (!date) {
      return '--';
    }

    const parsedDate = dayjs.utc(date);
    if (!parsedDate.isValid()) {
      return '--';
    }

    return parsedDate.local().format('DD-MM-YYYY');
  };

  const getEarliestValidDate = (...dates: (string | undefined)[]): string => {
    const validDates = dates.map(date => formatDate(date)).filter(date => date !== '--');
    if (validDates.length === 0) {
      return '--';
    }
    return validDates.reduce((earliestDate, currentDate) => {
      return dayjs(currentDate, 'DD-MM-YYYY').isBefore(dayjs(earliestDate, 'DD-MM-YYYY'))
        ? currentDate
        : earliestDate;
    });
  };

  const fromDate = getEarliestValidDate(
    filterOptions.filterOptions.check_in_from,
    filterOptions.filterOptions.check_out_from,
    filterOptions.filterOptions.actual_check_out_from
  );

  const toDate = getEarliestValidDate(
    filterOptions.filterOptions.check_in_to,
    filterOptions.filterOptions.check_out_to,
    filterOptions.filterOptions.actual_check_out_to
  );
  const filterOptionsObject = filterOptions.filterOptions;

  const getAllData = async () => {
    const result = await getAllBookings({
      page: filterOptions.page,
      limit: filterOptions.limit,
      ...filterOptionsObject
    });
    return result;
  };
  const exportToCSV = async () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    try {
      setIsLoadingExport(true);
      const result = await getAllData();
      const uniquePartnerNames = new Set<string>();
      const uniqueRooms = new Set<string>();
      let totalAdults = 0;
      let totalChildren = 0;

      const dataToExport = (_.get(result, 'data.data', []) as BookingLine[]).map((item, index) => {
        uniqueRooms.add(item.room_name);
        uniquePartnerNames.add(item.partner_name);
        totalAdults += item.adult;
        totalChildren += item.child;
        return {
          stt: index + 1,
          name: item.partner_name,
          room_name: item.room_name || item.room_type_name,
          room_type: item.room_type_name,
          check_in: toLocalTime(item.check_in, 'DD/MM/YYYY HH:mm'),
          check_out: toLocalTime(item.check_out, 'DD/MM/YYYY HH:mm'),
          adult: item.adult,
          child: item.child,
          medium_name: item.medium_name || '',
          hotel_travel_agency_name: item.hotel_travel_agency_name || ''
        };
      });

      // Sheet name
      const workbook = new ExcelJS.Workbook();

      const sheetName = `Police_${dayjs.utc(fromDate).local().format('DD-MM-YYYY')}_${dayjs.utc(toDate).local().format('DD-MM-YYYY')}`;
      const sheet = workbook.addWorksheet(sheetName, {
        views: [
          {
            showGridLines: false
          }
        ]
      });

      const matchingOption = TITLE_STATUS_OPTION[statusForm[0]];
      // Title
      sheet.mergeCells('A1:J1');
      sheet.getCell('A1').value = currentBranch?.name?.toUpperCase() || '';
      sheet.getCell('A1').font = { bold: true, size: 14 };
      sheet.getCell('A1').alignment = { horizontal: 'center' };

      // Address
      sheet.mergeCells('A2:J2');
      sheet.getCell('A2').value = `Địa chỉ: ${currentBranch?.address}`;
      sheet.getCell('A2').font = { size: 10 };
      sheet.getCell('A2').alignment = { horizontal: 'center' };

      // Support
      sheet.mergeCells('A3:J3');
      sheet.getCell('A3').value = currentBranch?.phone_contact
        ? `ĐT: ${currentBranch?.phone_contact}`
        : '';
      sheet.getCell('A3').font = { size: 10 };
      sheet.getCell('A3').alignment = { horizontal: 'center' };

      // Support subtitle
      sheet.mergeCells('A4:J4');
      sheet.getCell('A4').value = `BÁO CÁO ${matchingOption}`;
      sheet.getCell('A4').font = { bold: true, size: 10 };
      sheet.getCell('A4').alignment = { horizontal: 'center' };

      // Filter value
      sheet.mergeCells('B6:C6');
      sheet.mergeCells('E6:F6');
      sheet.mergeCells('G6:K6');

      sheet.getCell('A6').value = `Ngày`;
      sheet.getCell('A6').font = { bold: true };
      sheet.getCell('B6').value = fromDate;
      sheet.getCell('D6').value = `Đến`;
      sheet.getCell('D6').font = { bold: true };
      sheet.getCell('E6').value = toDate;
      sheet.getRow(6).eachCell(function (cell) {
        cell.border = {
          top: { style: 'medium', color: { argb: '#000' } },
          bottom: { style: 'medium', color: { argb: '#000' } }
        };
      });
      sheet.getRow(6).height = 25;

      // Mark column
      sheet.getRow(7).values = [
        '[1]',
        '[2]',
        '[3]',
        '[4]',
        '[5]',
        '[6]',
        '[7]',
        '[8]',
        '[9]',
        '[10]',
        '[11]'
      ];

      // Header columns
      sheet.getRow(8).values = [
        'STT',
        'Tên đầy đủ',
        'Phòng',
        'Loại phòng',
        'TA',
        'Công ty',
        'Ngày vào',
        'Ngày đi',
        'NL',
        'TE',
        'Chú ý'
      ];

      sheet.getCell('A7').alignment = { horizontal: 'center' };
      sheet.getCell('A8').alignment = { horizontal: 'center' };
      sheet.getCell('H7').alignment = { horizontal: 'center' };
      sheet.getCell('H8').alignment = { horizontal: 'center' };
      sheet.getRow(8).eachCell(function (cell) {
        cell.border = {
          bottom: { style: 'medium', color: { argb: '#000' } }
        };
        cell.font = { bold: true };
      });

      // Data
      sheet.columns = [
        {
          key: 'stt',
          width: 10,
          style: { alignment: { horizontal: 'center' } }
        },
        { key: 'name', width: 25 },
        { key: 'room_name', width: 20 },
        { key: 'room_type', width: 20 },
        { key: 'medium_name', width: 20 },
        { key: 'hotel_travel_agency_name', width: 20 },
        { key: 'check_in', width: 25 },
        { key: 'check_out', width: 25 },
        { key: 'adult', width: 5, style: { alignment: { horizontal: 'center' } } },
        { key: 'child', width: 5, style: { alignment: { horizontal: 'center' } } },
        { key: 'note', width: 35 }
      ];

      dataToExport.forEach(item => {
        sheet.addRow(item);
      });

      const totalRowIndex = dataToExport.length + 10; // Adjust the index based on data length

      // Set values for each cell
      sheet.getCell(`A${totalRowIndex}`).value = 'Tổng';
      sheet.getCell(`B${totalRowIndex}`).value = uniquePartnerNames.size;
      sheet.getCell(`C${totalRowIndex}`).value = uniqueRooms.size;
      sheet.getCell(`I${totalRowIndex}`).value = totalAdults;
      sheet.getCell(`J${totalRowIndex}`).value = totalChildren;

      sheet.getCell(`A${totalRowIndex}`).font = { bold: true };

      // Set alignment for each cell
      ['A', 'B', 'C', 'I', 'J', 'K'].forEach(col => {
        sheet.getCell(`${col}${totalRowIndex}`).alignment = { horizontal: 'center' };
      });

      // Set borders for the cells
      sheet.getCell(`A${totalRowIndex}`).border = {
        top: { style: 'medium', color: { argb: 'FF000000' } },
        bottom: { style: 'medium', color: { argb: 'FF000000' } },
        left: { style: 'medium', color: { argb: 'FF000000' } },
        right: { style: 'medium', color: { argb: 'FF000000' } }
      };

      ['B', 'C', 'G', 'H', 'I', 'J', 'K'].forEach(col => {
        sheet.getCell(`${col}${totalRowIndex}`).border = {
          top: { style: 'medium', color: { argb: 'FF000000' } },
          bottom: { style: 'medium', color: { argb: 'FF000000' } }
        };
      });

      // Optionally, apply borders to all cells from A to K
      for (let col = 1; col <= 11; col++) {
        const cell = sheet.getCell(`${String.fromCharCode(64 + col)}${totalRowIndex}`);
        if (col === 1) {
          cell.border = {
            top: { style: 'medium', color: { argb: 'FF000000' } },
            bottom: { style: 'medium', color: { argb: 'FF000000' } },
            left: { style: 'medium', color: { argb: 'FF000000' } },
            right: { style: 'medium', color: { argb: 'FF000000' } }
          };
        } else if (col === 9) {
          cell.border = {
            top: { style: 'medium', color: { argb: 'FF000000' } },
            bottom: { style: 'medium', color: { argb: 'FF000000' } }
          };
        } else {
          cell.border = {
            top: { style: 'medium', color: { argb: 'FF000000' } },
            bottom: { style: 'medium', color: { argb: 'FF000000' } }
          };
        }
      }

      const imageId2 = workbook.addImage({
        base64: Logo,
        extension: 'png'
      });
      sheet.addImage(imageId2, {
        tl: { col: 1, row: 1 },
        ext: { width: 71, height: 57 }
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (e) {
      console.log('ERROR', e);
    } finally {
      setIsLoadingExport(false);
    }
  };

  const items: MenuProps['items'] = useMemo(() => {
    return [
      {
        key: ACTION_EXPORT_DATA.EXCEL,
        label: 'Excel'
      }
    ];
  }, []);

  return (
    <Dropdown
      menu={{ items, onClick: handleClickMenu }}
      trigger={['click']}
      overlayClassName="menu-user"
    >
      <Button icon={<DownloadOutlined />} loading={isLoadingExport}>
        {t('common.actions.exportData')}
      </Button>
    </Dropdown>
  );
};

export default ExportArrivalGuest;
