import _ from 'lodash';
import { useQuery } from '@tanstack/react-query';
import QUERY_KEYS from 'services/api/queryKeys';
import {
  PaginationType,
  ParamsGetTransactionsType,
  PendingPostRoomCharge,
  TransactionCodesResponse,
  TransactionData,
  TransactionPayment
} from 'services/api/type/booking.type';
import { axiosInstance } from 'services/api/base.api';
import {
  getAddonServiceTaxes,
  getPendingPostRoomCharge,
  transactionCode
} from 'services/api/module/booking.api';
import { useMemo } from 'react';
import { generateUniqueId } from 'utils';
import { paths } from 'services/api/paths';

const groupByParty = (data: any) => {
  return (data || []).reduce((acc: any, booking: any) => {
    const lastItem = acc[acc.length - 1];

    // Group all bookings with a party_id
    if (
      !lastItem ||
      lastItem.party_id !== booking.party_id ||
      lastItem.group_id !== booking.group_id
    ) {
      // Insert 'is_party' for a new party group
      acc.push({
        is_party: true,
        party_id: booking.party_id || (booking.group_id ? '' : 'FIT'),
        group_id: booking.group_id,
        group_name: booking.group_name,
        id: generateUniqueId()
      });
    }
    // Push the booking itself
    acc.push(booking);

    return acc;
  }, []);
};

export const useGetGuestTransactions = (
  filterOptions?: ParamsGetTransactionsType,
  page: number = 1,
  search?: string,
  limit: number = 40
) => {
  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.GET_GUEST, page, search, limit, filterOptions],
    queryFn: () =>
      axiosInstance
        .get('/guests', {
          params: {
            ...filterOptions,
            limit
          }
        })
        .then(item => item.data)
  });

  return {
    data: groupByParty(data?.data) || [],
    pagination: _.get(data?.data, 'pagination', {}) as PaginationType,
    isLoading
  };
};

export const useGetGuestOrders = (guest_id: number) => {
  return useQuery({
    queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, guest_id],
    queryFn: () => axiosInstance.get(`/guests/${guest_id}/orders`).then(item => item.data?.data),
    enabled: !!guest_id
  });
};

export const useGetGuestOrderDetail = (order_id?: number) => {
  return useQuery<TransactionData>({
    queryKey: [
      QUERY_KEYS.GET_GUEST_TRANSACTIONS,
      QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL,
      order_id
    ],
    queryFn: () => axiosInstance.get(`/guests/orders/${order_id}`).then(item => item.data?.data),
    enabled: !!order_id
  });
};

export const useGetTransactionCodes = (payment_type?: string, enable: boolean = true) => {
  return useQuery<TransactionCodesResponse[]>({
    queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, QUERY_KEYS.GET_GUEST_TRANSACTIONS_DETAIL, enable],
    queryFn: () => transactionCode({ payment_type }),
    refetchOnWindowFocus: false
  });
};

export const usePendingPostRoomCharge = (filterOptions: any) => {
  const {
    data: dataPendingPostRoomCharge,
    status,
    isLoading,
    isFetching
  } = useQuery({
    queryKey: [QUERY_KEYS.GET_BOOKING_LIST_ALL, filterOptions],
    queryFn: () => getPendingPostRoomCharge(filterOptions),
    enabled: !!filterOptions
  });

  const sortedData = useMemo(() => {
    const pendingPostRoomChargeData: PendingPostRoomCharge[] = _.get(
      dataPendingPostRoomCharge,
      'data.data',
      []
    );
    return pendingPostRoomChargeData.sort((a: any, b: any) => b.booking_id - a.booking_id);
  }, [dataPendingPostRoomCharge]);

  return {
    data: sortedData,
    status,
    isLoading,
    isFetching
  };
};

export const useGetAddonServiceTaxes = () => {
  const { data } = useQuery({
    queryKey: [QUERY_KEYS.GET_BOOKING_LIST_ALL],
    queryFn: () => getAddonServiceTaxes(),
    refetchOnWindowFocus: false
  });
  return {
    data: data
  };
};

export const useGetTransactionsFromOrders = (
  booking_line_guest_ids?: string | undefined,
  booking_line_order_id?: number
) => {
  const { data } = useQuery<TransactionPayment[]>({
    queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, booking_line_guest_ids, booking_line_order_id],
    queryFn: () =>
      axiosInstance
        .get(paths.getTransactionsFromOrders(), {
          params: { booking_line_guest_ids, booking_line_order_id }
        })
        .then(item => item.data?.data),
    enabled: !!booking_line_guest_ids || !!booking_line_order_id
  });
  return {
    data: data
  };
};

export const useGetMinvoicePreview = (order_id: number, enabled: boolean) => {
  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_MINVOICE_PREVIEW, order_id],
    queryFn: () =>
      axiosInstance.get(paths.getMinvoicePreview(order_id)).then(item => item.data.data),
    enabled: !!order_id && enabled
  });
  return {
    data: data,
    isFetching: isFetching
  };
};
