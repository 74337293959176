import React from 'react';
import { Button, notification } from 'antd';
import { matchPath, useLocation } from 'react-router-dom';
import { queryClient } from 'index';
import { useMutation } from '@tanstack/react-query';
import { BookingLine, BookingStatus } from 'services/api/type/booking.type';
import { actionCheckIn, actionCheckOut } from 'services/api/module/booking.api';
import QUERY_KEYS from 'services/api/queryKeys';
import useModalStore from 'stores/useModal';
import PAGES from 'routes/constants';
import { handleDisableCheckInButton } from 'utils';
import { PermissionActionTypes } from 'helper/type/action.type';
import IBButton from 'helper/permission.action';

interface BookingActionType {
  bookingLine: BookingLine;
  t: (key: string) => string;
}

export const BookingAction = ({ bookingLine, t }: BookingActionType) => {
  const location = useLocation();

  const { setIsOpenAssignRoom, setInfoConfirmModal } = useModalStore();
  const { mutateAsync: mutateCheckIn, isPending: isPendingCheckIn } = useMutation({
    mutationFn: () => actionCheckIn(bookingLine.booking_line_id)
  });

  const { mutateAsync: mutateCheckOut, isPending: isPendingCheckOut } = useMutation({
    mutationFn: () => actionCheckOut(bookingLine.booking_line_id)
  });

  const handleCheckIn = () => {
    setInfoConfirmModal(true, {
      title: t('common.confirm.checkin'),
      onOk: async () => {
        setInfoConfirmModal(false);
        try {
          await mutateCheckIn();
          notification.success({
            message: t('common.message.checkIn')
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.KEY_ROOM]
          });

          if (matchPath(PAGES.roomMap, location.pathname)) {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.GET_BOOKING_TIMELINE]
            });
          }

          if (matchPath(PAGES.cleaningCalendar, location.pathname)) {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.GET_CLEANING_CALENDAR]
            });
          }
          await queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.GET_TRACKING_LOGS, bookingLine.booking_line_id]
          });
        } catch (err: any) {
          notification.error({
            message: err.error || t('common.error')
          });
        }
      }
    });
  };

  const handleCheckOut = () => {
    setInfoConfirmModal(true, {
      title: t('common.confirm.checkout'),
      onOk: async () => {
        setInfoConfirmModal(false);
        try {
          await mutateCheckOut();
          notification.success({
            message: t('common.message.checkout')
          });
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.KEY_ROOM]
          });

          if (matchPath(PAGES.roomMap, location.pathname)) {
            queryClient.invalidateQueries({
              queryKey: [QUERY_KEYS.GET_BOOKING_TIMELINE]
            });

            if (matchPath(PAGES.cleaningCalendar, location.pathname)) {
              queryClient.invalidateQueries({
                queryKey: [QUERY_KEYS.GET_CLEANING_CALENDAR]
              });
            }
          }
        } catch (err: any) {
          notification.error({
            message: err.error || t('common.error')
          });
        }
      }
    });
  };

  if (bookingLine.status === BookingStatus.CHECK_IN) {
    return (
      <IBButton
        permission={PermissionActionTypes.CHECK_OUT}
        className="ant-btn-checkout"
        onClick={handleCheckOut}
        loading={isPendingCheckOut}
      >
        {t('bookingPage.bookingList.actions.checkOutRoom')}
      </IBButton>
    );
  }
  if (bookingLine.status === BookingStatus.CHECK_OUT) {
    return <></>;
  }
  if (bookingLine.status === BookingStatus.CANCEL) {
    return (
      <Button className="ant-btn-secondary" disabled>
        {bookingLine.is_no_show ? 'NoShow' : 'Phòng huỷ'}
      </Button>
    );
  }
  if (bookingLine.status === BookingStatus.CONFIRM && !bookingLine.room_id) {
    return (
      <IBButton
        permission={PermissionActionTypes.ASSIGN_ROOM}
        className="ant-btn-assign-room"
        onClick={() => setIsOpenAssignRoom(true)}
      >
        {t('common.actions.assignRoom')}
      </IBButton>
    );
  }
  return (
    <IBButton
      permission={PermissionActionTypes.CHECK_IN}
      className="ant-btn-checkin"
      onClick={handleCheckIn}
      disabled={handleDisableCheckInButton(bookingLine)}
      loading={isPendingCheckIn}
    >
      {t('common.actions.checkIn')}
    </IBButton>
  );
};
