import { useMemo } from 'react';
import { Card, Table } from 'antd';

import { useGetAllWarehouseProducts } from 'hooks/useGetAllWarehouseProducts';
import { getColumnWarehouseManagement } from 'constants/table';
import 'styles/warehouse-management.scss';
import { useTranslation } from 'react-i18next';

function WarehouseManagement() {
  const { t } = useTranslation();
  const { data: WarehouseProducts, isLoading } = useGetAllWarehouseProducts();

  const sortedData = useMemo(() => {
    if (!WarehouseProducts) return [];
    return [...WarehouseProducts].sort((a, b) => a.product_id - b.product_id);
  }, [WarehouseProducts]);

  const columnWarehouseManagement = useMemo(() => {
    return getColumnWarehouseManagement(t);
  }, [t]);

  return (
    <div className="pms-warehouse-management">
      <Card title={t('managePage.inventoryManagementPage.title')} style={{ width: '100%' }}>
        <div className="pms-warehouse-management__table">
          <Table
            loading={isLoading}
            rowKey="product_id"
            columns={columnWarehouseManagement}
            dataSource={sortedData}
            pagination={false}
            locale={{
              emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
            }}
            scroll={{ x: 600, y: 'calc(100vh - 350px)' }}
          />
        </div>
      </Card>
    </div>
  );
}
export default WarehouseManagement;
