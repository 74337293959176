import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { Button, Card, Input, Form, FormProps, Table, DatePicker } from 'antd';
import dayjs, { Dayjs } from 'dayjs';

import { useGetRoomLock } from 'hooks/useGetRoomLock';
import { useGetAvailRoomType } from 'hooks/useGetAvailRoomType';

import { getColumnRoomLock } from 'constants/table';
import { generateExportFileName, handleRangeDateChange, toUtcTime } from 'utils';
import { RoomLockType } from 'services/api/type/room.type';
import 'styles/room-lock-history.scss';

import { useTranslation } from 'react-i18next';
import ExportRoomLockHistory from 'components/common/export-room-lock-history';

const initStartDate = dayjs().set('hour', 14).set('minute', 0).set('second', 0);
const initEndDate = dayjs().add(1, 'day').set('hour', 12).set('minute', 0).set('second', 0);

function RoomLockHistory() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const rangeDateForm = Form.useWatch('rangeDate', form);

  const [page, setPage] = useState<number>(1);
  const [filterOptions, setFilterOptions] = useState<any>({
    active: 'report'
  });

  const { data, isFetching } = useGetRoomLock(filterOptions);

  const { data: availRoomTypes } = useGetAvailRoomType(
    toUtcTime(_.get(rangeDateForm, '0') || initStartDate, 'YYYY-MM-DD HH:mm:ss'),
    toUtcTime(_.get(rangeDateForm, '1') || initEndDate, 'YYYY-MM-DD HH:mm:ss')
  );

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});
    const { rangeDate, ...restFilterOptions } = truthyValues;
    if (rangeDate) {
      const checkInFrom: Dayjs = rangeDate[0];
      const checkInTo: Dayjs = rangeDate[1];
      if (checkInFrom) {
        _.set(restFilterOptions, 'start_date', checkInFrom.format('YYYY-MM-DD HH:mm:ss'));
      }
      if (checkInTo) {
        _.set(restFilterOptions, 'end_date', checkInTo.format('YYYY-MM-DD HH:mm:ss'));
      }
    }
    if (page !== 1) {
      setPage(1);
    }
    _.set(restFilterOptions, 'active', 'report');
    setFilterOptions(restFilterOptions);
  };

  const cookedData = useMemo(() => {
    const hash = {} as any;
    const roomLocks: RoomLockType[] = _.clone(data);

    roomLocks.forEach((item: RoomLockType) => {
      if (!hash[item.room_type_name]) {
        const pushedItem = _.cloneDeep(item);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        delete pushedItem.room_type_name;

        hash[item.room_type_name] = {
          id: 'root',
          room_type_name: item.room_type_name,
          hideCheckbox: true,
          children: [_.cloneDeep(pushedItem)]
        };
      } else {
        const pushedItem = _.cloneDeep(item);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        delete pushedItem.room_type_name;

        hash[item.room_type_name].children.push(_.cloneDeep(pushedItem));
      }
    });

    const sortRoomType = _.orderBy(availRoomTypes, 'price', 'asc');
    Object.keys(hash).forEach(key => {
      hash[key].children = _.orderBy(
        hash[key].children,
        ['attributes.room_no', 'start_date', 'end_date'],
        ['asc', 'asc', 'asc']
      );
    });

    return sortRoomType
      .filter(item => item.room_type_name in hash)
      .map(item => hash[item.room_type_name]);
  }, [availRoomTypes, data]);

  const columnRoomLock = useMemo(() => {
    return getColumnRoomLock(t, true);
  }, [t]);

  return (
    <div className="pms-room-lock_history">
      <Card title={t('managePage.roomLockHistory.title')} style={{ width: '100%' }}>
        <Form
          form={form}
          name="room-lock-filter-form"
          layout="inline"
          initialValues={{
            search: '',
            rangeDate: null
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-room-lock_history__filter">
            <div className="flex items-center flex-wrap" style={{ gap: '8px 16px' }}>
              <Form.Item name="search" style={{ marginRight: 0 }}>
                <Input placeholder={t('bookingPage.roomLock.roomNo')} />
              </Form.Item>
              <Form.Item name="rangeDate">
                <DatePicker.RangePicker
                  className="w-full"
                  placeholder={[t('common.time.startDate'), t('common.time.endDate')]}
                  format="DD-MM-YYYY HH:mm"
                  onChange={value => {
                    handleRangeDateChange(form, value);
                  }}
                />
              </Form.Item>

              <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                {t('common.actions.submitButton')}
              </Button>
            </div>

            <ExportRoomLockHistory
              filterOptions={{
                filterOptions: filterOptions
              }}
              fileName={generateExportFileName('room-lock-history')}
              availRoomTypes={availRoomTypes}
            />
          </div>
        </Form>

        <div className="pms-room-lock_history__table">
          <Table
            loading={isFetching}
            rowKey={record => `${record.room_type_name}-${record.id}`}
            columns={columnRoomLock}
            dataSource={cookedData}
            locale={{
              emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
            }}
            scroll={{ x: 500, y: 'calc(100vh - 400px)' }}
            pagination={false}
          />
        </div>
      </Card>
    </div>
  );
}

export default RoomLockHistory;
