import React, { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import { Button, Card, DatePicker, DatePickerProps, Select, Form, FormProps, Table } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useQueryClient } from '@tanstack/react-query';

import EditCustomerModal from 'components/modal/edit-customer-modal';
import ExportStayingGuest from 'components/common/export-staying-guest';
import { useGetStayingGuests } from 'hooks/useGetStayingGuests';

import QUERY_KEYS from 'services/api/queryKeys';
import {
  COUNTRY_STAYING_GUEST_OPTIONS,
  STATUS_STAYING_GUEST,
  STATUS_STAYING_GUEST_OPTIONS
} from 'constants/form';
import { getStayingGuestsColumns } from 'constants/table';
import { PAGE_SIZE_STAYING_GUESTS } from 'services/api/module/report.api';
import { toUtcTime } from 'utils';
import 'styles/staying-guests.scss';
import { useTranslation } from 'react-i18next';

const disabledDate: DatePickerProps['disabledDate'] = (current, { from }) => {
  if (from) {
    return current.isBefore(from);
  }

  return false;
};

const initStartDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
const initEndDate = dayjs().set('hour', 23).set('minute', 59).set('second', 59);

function StayingGuests() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const rangeDateForm = Form.useWatch('rangeDate', form);
  const statusForm = Form.useWatch('status', form);
  const countryForm = Form.useWatch('country', form);

  const queryClient = useQueryClient();
  const isIpadMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });

  const [filterOptions, setFilterOptions] = useState<any>({
    start_date: toUtcTime(initStartDate, 'YYYY-MM-DD HH:mm:ss'),
    end_date: toUtcTime(initEndDate, 'YYYY-MM-DD HH:mm:ss'),
    status: STATUS_STAYING_GUEST.ALLOT,
    country: ''
  });

  const [isOpenEditCustomer, setIsOpenEditCustomer] = useState(false);
  const [selectedEditCustomer, setSelectedEditCustomer] = useState<any>(null);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_STAYING_GUESTS);
  const { data, pagination, isFetching } = useGetStayingGuests(filterOptions, page, pageSize);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_STAYING_GUESTS, page]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, ...restFilterOptions } = truthyValues;

    if (rangeDate) {
      const startDate: Dayjs = rangeDate[0];
      const endDate: Dayjs = rangeDate[1];
      if (startDate) {
        _.set(restFilterOptions, 'start_date', toUtcTime(startDate, 'YYYY-MM-DD HH:mm:ss'));
      }
      if (endDate) {
        _.set(restFilterOptions, 'end_date', toUtcTime(endDate, 'YYYY-MM-DD HH:mm:ss'));
      }
    }

    if (page !== 1) {
      setPage(1);
    }
    setFilterOptions(restFilterOptions);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleShowEditCustomer = (id: number) => {
    const customer = _.find(data, { id });
    if (customer) {
      setSelectedEditCustomer(customer);
      setIsOpenEditCustomer(true);
    }
  };

  const handleCloseEditCustomer = () => {
    setIsOpenEditCustomer(false);
  };

  const handleOkEditCustomer = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_STAYING_GUESTS, page]
    });
    setIsOpenEditCustomer(false);
  };

  const handleReloadCustomer = async () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_STAYING_GUESTS, page]
    });
  };

  const columnStayingGuest = useMemo(() => {
    return getStayingGuestsColumns(handleShowEditCustomer, page, t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, t]);

  return (
    <div className="pms-staying-guests">
      <Card title={t('managePage.caDeclarationPage.title')} style={{ width: '100%' }}>
        <Form
          form={form}
          name="staying-guests-form"
          layout={isIpadMobile ? 'vertical' : 'inline'}
          initialValues={{
            rangeDate: [initStartDate, initEndDate],
            status: STATUS_STAYING_GUEST.ALLOT,
            country: ''
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-staying-guests__filter-wrapper">
            <div className="pms-staying-guests__filter">
              <Form.Item name="rangeDate">
                <DatePicker.RangePicker
                  showTime
                  className="w-full"
                  placeholder={[t('common.time.startDate'), t('common.time.endDate')]}
                  disabledDate={disabledDate}
                  format="YYYY-MM-DD HH:mm"
                  // onChange={handleRangeDateChange}
                />
              </Form.Item>

              <Form.Item name="status">
                <Select
                  options={STATUS_STAYING_GUEST_OPTIONS}
                  labelRender={option => (
                    <span>
                      {t('common.customer.guestStatus')}: {option.label}
                    </span>
                  )}
                />
              </Form.Item>

              <Form.Item name="country">
                <Select
                  options={COUNTRY_STAYING_GUEST_OPTIONS}
                  labelRender={option => (
                    <span>
                      {t('common.customer.nationality')}: {option.label}
                    </span>
                  )}
                />
              </Form.Item>

              <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                {t('common.actions.submitButton')}
              </Button>
            </div>

            <ExportStayingGuest
              filterOptions={{
                start_date: toUtcTime(
                  _.get(rangeDateForm, '0') || initStartDate,
                  'YYYY-MM-DD HH:mm:ss'
                ),
                end_date: toUtcTime(
                  _.get(rangeDateForm, '1') || initEndDate,
                  'YYYY-MM-DD HH:mm:ss'
                ),
                status: statusForm,
                country: countryForm
              }}
              fileName={`staying-guests-${(_.get(rangeDateForm, '0') || initStartDate)?.format(
                'DD-MM-YYYY'
              )}-${(_.get(rangeDateForm, '1') || initEndDate)?.format('DD-MM-YYYY')}-${nanoid()}`}
            />
          </div>
        </Form>

        <div className="pms-staying-guests__table">
          <Table
            loading={isFetching}
            rowKey={record => `${record.id}-${record.room_id}`}
            columns={columnStayingGuest}
            dataSource={data}
            // onRow={(record, rowIndex) => {
            //   return {
            //     }, // click row
            //   };
            // }}
            pagination={{
              current: page,
              position: ['bottomCenter'],
              total: pagination.total,
              pageSize: pageSize,
              locale: { items_per_page: `/ ${t('common.page')}` },
              showTotal: total => `${total} items`,
              onChange: (page: number) => handleChangePage(page),
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              }
            }}
            locale={{
              emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
            }}
            scroll={{ x: 600, y: 'calc(100vh - 400px)' }}
          />
        </div>
      </Card>

      <EditCustomerModal
        isBasicInfo
        isOpen={isOpenEditCustomer}
        customer={selectedEditCustomer}
        onOk={handleOkEditCustomer}
        onCancel={handleCloseEditCustomer}
        isShowUpload={false}
        onReload={handleReloadCustomer}
      />
    </div>
  );
}

export default StayingGuests;
