import { Button, Modal, Table } from 'antd';
import { getColumnCustomerBookings } from 'constants/table';
import React, { useMemo } from 'react';
import { useGetAllBooking } from 'hooks/useGetBooking';

interface Props {
  customer_id?: number;
  isOpen?: boolean;
  onCancel?: () => void;
  t: (key: string) => string;
}
function CustomersHistoryBookings({ customer_id, isOpen, onCancel, t }: Props) {
  const { data: bookingData, isLoading } = useGetAllBooking({ customer_id: customer_id }, isOpen);

  const columnCustomerBookings = useMemo(() => {
    return getColumnCustomerBookings(t);
  }, [t]);
  return (
    <Modal
      title={t('managePage.customersPage.bookedDetails.title')}
      className="modal-add-expense"
      centered
      width={1400}
      open={isOpen}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          {t('common.actions.cancel')}
        </Button>
      ]}
      onCancel={onCancel}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      confirmLoading={isLoading}
      destroyOnClose
    >
      <Table
        loading={isLoading}
        rowKey="id"
        columns={columnCustomerBookings}
        dataSource={bookingData}
        bordered
        locale={{
          emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
        }}
        pagination={false}
        scroll={{ x: 2000, y: 'calc(100vh - 400px)' }}
      ></Table>
    </Modal>
  );
}

export default CustomersHistoryBookings;
