import React, { useEffect, useMemo, useRef, useState } from 'react';
import _ from 'lodash';
import dayjs, { Dayjs } from 'dayjs';
import { useMutation } from '@tanstack/react-query';
import { Button, Card, DatePicker, Steps, Table, notification } from 'antd';
import { useNavigate } from 'react-router-dom';

import { getNightAuditColumnStep0And1 } from 'constants/table';
import useModal from 'stores/useModal';
import useBookingStore from 'stores/useBooking';
import { useGetAllBooking } from 'hooks/useGetBooking';
import { useGetCurrentNightAudit } from 'hooks/useGetCurrentNightAudit';
import { usePendingPostRoomCharge } from 'hooks/useGetGuestTransactions';

import { BookingStatus } from 'services/api/type/booking.type';
import { actionCheckIn, actionCheckOut, postRoomCharge } from 'services/api/module/booking.api';
import { getEndOfToday, getStartOfToday, toLocalTime } from 'utils';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import API from 'services/api';
import PAGES from 'routes/constants';

import 'styles/night-audit.scss';
import { logEvent } from 'services/tracking/gaTracking';
import { PermissionActionTypes } from 'helper/type/action.type';
import { ACTION_BOOKING } from 'components/booking-list/action-booking';
import IBButton from 'helper/permission.action';
import { useTranslation } from 'react-i18next';

function NightAudit() {
  const { t } = useTranslation();

  const STEP_ITEMS = [
    {
      key: 0,
      title: t('common.statuses.checkInRoom'),
      filter: (night_audit_date: string) => ({
        status: BookingStatus.CONFIRM,
        check_in_from: getStartOfToday(night_audit_date),
        check_in_to: getEndOfToday(night_audit_date)
      })
    },
    {
      key: 1,
      title: t('common.statuses.staying'),
      filter: (night_audit_date: string) => ({
        status: BookingStatus.CHECK_IN,
        check_out_from: getStartOfToday(night_audit_date),
        check_out_to: getEndOfToday(night_audit_date)
      })
    },
    {
      key: 2,
      title: t('common.statuses.transaction'),
      filter: (night_audit_date: string) => ({
        status: `${BookingStatus.INITIAL},${BookingStatus.CHECK_OUT},${BookingStatus.CHECK_IN}`,
        night_audit_date: dayjs(night_audit_date).format('YYYY-MM-DD')
      })
    },
    { key: 3, title: t('common.statuses.finished'), filter: undefined }
  ];

  const navigate = useNavigate();
  const {
    setIsOpenResolveDocument,
    setIsOpenCancelRoom,
    setInfoConfirmModal,
    setConfirmLoading,
    setIsOpenChangeDate
  } = useModal();

  const {
    setBookingLineId,
    setBookingLineDetail,
    addCheckListBookingLineIds,
    removeCheckListBookingLineId,
    removeCheckListBookingLineIds,
    checkedListBookingLineId
  } = useBookingStore();
  const selectedBookingLineId = useRef<number>();

  const [date, setDate] = useState<Dayjs | null>(null);
  const [step, setStep] = useState(0);
  const { data: currentNightAudit } = useGetCurrentNightAudit();

  const [filterOptions, setFilterOptions] = useState<any>({});
  const [filterOptionsForPendingPostRoomCharge, setFilterOptionsForGetPendingPostRoomCharge] =
    useState<any>({});

  const { data, isFetching } = useGetAllBooking(filterOptions);
  const { data: dataPendingPostRoomCharge, isFetching: isFetchingGetPendingPostRoomCharge } =
    usePendingPostRoomCharge(filterOptionsForPendingPostRoomCharge);
  const [isDisableButton, setIsDisableButton] = useState<boolean>(true);

  const ids = useMemo(
    () => dataPendingPostRoomCharge.map(item => item.booking_line_id),
    [dataPendingPostRoomCharge]
  );

  useEffect(() => {
    if (step === 2) {
      addCheckListBookingLineIds(ids);
    }
  }, [ids, step, addCheckListBookingLineIds]);

  useEffect(() => {
    if (checkedListBookingLineId.length > 0) {
      setIsDisableButton(true);
    } else {
      setIsDisableButton(false);
    }
  }, [checkedListBookingLineId]);

  const { mutateAsync: mutateCheckIn } = useMutation({
    mutationFn: (bookingLineId: number) => actionCheckIn(bookingLineId)
  });

  const { mutateAsync: mutateCheckOut } = useMutation({
    mutationFn: (bookingLineId: number) => actionCheckOut(bookingLineId)
  });

  const { mutateAsync: mutateFinishNightAudit, isPending } = useMutation({
    mutationFn: API.nightAudit.finishNightAudit
  });

  const { mutateAsync: mutateAutoPostRoomChargeNightAudit } = useMutation({
    mutationFn: API.nightAudit.autoPostRoomChargeNightAudit
  });

  const { mutateAsync: mutatePostRoomCharge } = useMutation({
    mutationFn: (params: any = {}) =>
      postRoomCharge({
        id: params.booking_line_id,
        from_date: params.from_date,
        to_date: params.to_date,
        folio_balance_code: params.folio_balance_code,
        post_option: 'single'
      })
  });

  useEffect(() => {
    if (!_.isEmpty(currentNightAudit)) {
      const nightAuditDate = dayjs.utc(currentNightAudit.night_audit_date).local();
      handleChangeDate(nightAuditDate);
      loadStepData(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentNightAudit]);

  const handleChangeDate = (value: Dayjs) => {
    setDate(value);
  };

  const loadStepData = (step: number) => {
    const stepValue = STEP_ITEMS.find(item => item.key === step);
    if (stepValue?.filter && currentNightAudit?.night_audit_date) {
      if (step !== 2) {
        setFilterOptions(stepValue.filter(currentNightAudit.night_audit_date));
      } else {
        setFilterOptionsForGetPendingPostRoomCharge(
          stepValue.filter(currentNightAudit.night_audit_date)
        );
      }
    }
  };

  const handleNextStep = () => {
    const nextStep = step + 1;
    setStep(prev => prev + 1);
    loadStepData(nextStep);
  };

  const handlePrevStep = () => {
    const prevStep = step - 1;
    removeCheckListBookingLineIds(ids);
    setStep(prev => prev - 1);
    loadStepData(prevStep);
  };

  const handleClickMenu = (menu: any) => {
    switch (menu.key) {
      case ACTION_BOOKING.CANCEL_BOOKING:
        setIsOpenCancelRoom(true);
        break;
      case ACTION_BOOKING.GET_ROOM: {
        setInfoConfirmModal(true, {
          title: t('common.confirm.checkin'),
          onOk: () => {
            setConfirmLoading(true);
            handleCheckIn();
          }
        });
        break;
      }
      case ACTION_BOOKING.CHANGE_DATE:
        setIsOpenChangeDate(true);
        break;
      case ACTION_BOOKING.CHECK_OUT:
        setInfoConfirmModal(true, {
          title: t('common.confirm.checkout'),
          onOk: async () => {
            setConfirmLoading(true);
            handleCheckOut();
          }
        });
        break;
      case ACTION_BOOKING.RESOLVE_DOCUMENT:
        setIsOpenResolveDocument(true);
        break;
      case ACTION_BOOKING.POST_ROOM_CHARGE:
        setInfoConfirmModal(true, {
          title: t('common.confirm.postRoomCharge'),
          onOk: async () => {
            setConfirmLoading(true);
            handlePostRoomCharge();
          }
        });
        break;
      default:
        notification.warning({
          message: t('common.warning')
        });
        break;
    }
  };

  const handleCheckIn = async () => {
    if (selectedBookingLineId.current) {
      try {
        await mutateCheckIn(selectedBookingLineId.current);
        // notification.success({
        //   message: "Nhận phòng thành công",
        // });
        setInfoConfirmModal(false);
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_BOOKING_LIST_ALL]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_TRACKING_LOGS, selectedBookingLineId.current]
        });
      } catch (err: any) {
        notification.error({
          message: err.error || t('common.error')
        });
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const handleButtonAutoPostRoomChargeNightAudit = () => {
    setInfoConfirmModal(true, {
      title: t('common.confirm.autoPostRoomCharge'),
      onOk: () => {
        setConfirmLoading(true);
        handleAutoPostRoomCharge();
      }
    });
  };

  const handleAutoPostRoomCharge = async () => {
    try {
      const nightAuditDate = dayjs(currentNightAudit?.night_audit_date);
      await mutateAutoPostRoomChargeNightAudit({
        booking_line_ids: checkedListBookingLineId,
        from_date: nightAuditDate.format('YYYY-MM-DD'),
        to_date: nightAuditDate.format('YYYY-MM-DD')
      });
      setInfoConfirmModal(false);
      removeCheckListBookingLineIds(ids);
      notification.success({
        message: t('common.message.autoPostRoomCharge')
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_BOOKING_LIST_ALL]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST]
      });
    } catch (err: any) {
      notification.error({
        message: err.error || t('common.error')
      });
    } finally {
      setConfirmLoading(false);
    }
  };

  const handlePostRoomCharge = async () => {
    if (selectedBookingLineId.current) {
      try {
        const nightAuditDate = dayjs(currentNightAudit?.night_audit_date);
        await mutatePostRoomCharge({
          booking_line_id: selectedBookingLineId.current,
          from_date: nightAuditDate.format('YYYY-MM-DD'),
          to_date: nightAuditDate.format('YYYY-MM-DD'),
          folio_balance_code: 'A'
        });
        setInfoConfirmModal(false);
        removeCheckListBookingLineId(selectedBookingLineId.current);
        notification.success({
          message: t('common.message.postRoomChargeSuccessfully')
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_BOOKING_LIST_ALL]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_GUEST]
        });
      } catch (err: any) {
        notification.error({
          message: err.error || t('common.error')
        });
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const handleCheckOut = async () => {
    if (selectedBookingLineId.current) {
      try {
        await mutateCheckOut(selectedBookingLineId.current);
        notification.success({
          message: t('common.message.checkout')
        });
        setInfoConfirmModal(false);
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_BOOKING_LIST_ALL]
        });
      } catch (err: any) {
        notification.error({
          message: err.error || t('common.error')
        });
      } finally {
        setConfirmLoading(false);
      }
    }
  };

  const handleFinish = async () => {
    try {
      await mutateFinishNightAudit();
      notification.success({
        message: t('common.message.nightAuditSuccessfully')
      });
      logEvent({
        category: 'night_audit',
        action: 'finish_night_audit',
        label: 'Finish Night Audit',
        value: 1
      });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.GET_CURRENT_BRANCH] });
      navigate(PAGES.bookingList);
    } catch (err: any) {
      notification.error({
        message: err.error || t('common.error')
      });
    }
  };

  const columnNightAudit = useMemo(() => {
    if (step === STEP_ITEMS.length - 1) {
      return [];
    }

    return getNightAuditColumnStep0And1(handleClickMenu, step, t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, t]);

  return (
    <div className="pms-night-audit">
      <div className="pms-night-audit__date">
        <p className="m-0">{t('managePage.nightAuditPage.nightAudit')}</p>
        <DatePicker allowClear={false} value={date} disabled />
      </div>

      <Card
        title={
          <div className="pms-night-audit__step">
            <Steps current={step} items={STEP_ITEMS} />
          </div>
        }
        style={{ width: '100%' }}
      >
        {step !== 3 ? (
          <Table
            rowKey="booking_line_id"
            columns={columnNightAudit}
            dataSource={step == 2 ? dataPendingPostRoomCharge : data}
            scroll={{ x: 1000, y: 'calc(100vh - 420px)' }}
            loading={step == 2 ? isFetchingGetPendingPostRoomCharge : isFetching}
            pagination={false}
            onRow={record => {
              return {
                onClick: () => {
                  setBookingLineId(record.booking_line_id);
                  setBookingLineDetail(record);
                  selectedBookingLineId.current = record.booking_line_id;
                } // click row
              };
            }}
            locale={{
              emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
            }}
          />
        ) : (
          <div className="pms-night-audit__finish">
            <p className="m-0">
              {t('common.time.closeDate')} -{' '}
              {toLocalTime(String(currentNightAudit?.night_audit_date), 'DD/MM/YYYY')}
            </p>
            <p className="m-0">{t('managePage.nightAuditPage.confirmFinish')}</p>
          </div>
        )}
      </Card>

      <div className="pms-night-audit__actions">
        <Button.Group>
          {step == 2 && (
            <IBButton
              permission={PermissionActionTypes.POST_ROOM_CHARGE}
              type="primary"
              onClick={handleButtonAutoPostRoomChargeNightAudit}
              disabled={!isDisableButton}
            >
              {t('managePage.nightAuditPage.autoPostRoomChargeButton')}
            </IBButton>
          )}
          {step > 0 && (
            <Button type="primary" onClick={handlePrevStep}>
              {t('managePage.nightAuditPage.backButton')}
            </Button>
          )}
          <Button
            className="ant-btn-secondary"
            loading={step === 3 && isPending}
            onClick={step !== 3 ? handleNextStep : handleFinish}
            disabled={isDisableButton}
          >
            {step !== 3
              ? t('managePage.nightAuditPage.continueButton')
              : t('managePage.nightAuditPage.finishButton')}
          </Button>
        </Button.Group>
      </div>
    </div>
  );
}

export default NightAudit;
