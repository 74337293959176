import React, { useMemo, useState } from 'react';

import { Button, Card, Form, FormProps, Input, notification, Table } from 'antd';

import { getColumnCustomersManagement } from 'constants/table';

import 'styles/customers-management.scss';
import { useGetCustomers } from 'hooks/useGetCustomers';
import EditCustomerModal from 'components/modal/edit-customer-modal';
import QUERY_KEYS from 'services/api/queryKeys';
import { queryClient } from 'index';
import { PersonalInfoType } from 'services/api/type/room.type';
import { PAGE_SIZE_CUSTOMERS } from 'services/api/module/room.api';
import CustomersHistoryBookings from 'components/modal/customers-management/customers-history-bookings';
import { useTranslation } from 'react-i18next';

function CustomersManagement() {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [page, setPage] = useState<number>(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_CUSTOMERS);
  const [filterOptions, setFilterOptions] = useState<any>({
    page: page,
    limit: pageSize
  });
  const [isOpenEditOrCreateCustomer, setIsOpenEditOrCreateCustomerOrCreate] =
    useState<boolean>(false);
  const [isOpenHistoryBookings, setIsOpenHistoryBookings] = useState<boolean>(false);
  const [isEnable, setIsEnable] = useState<boolean>(true);

  const {
    data: customers,
    pagination,
    isLoading
  } = useGetCustomers(filterOptions, 'person', isEnable);
  const selectedCustomer = customers?.find(item => item.id == selectedRowKeys[0]);

  const handleChangePage = (page: number) => {
    setPage(page);
    setFilterOptions({ ...filterOptions, page, limit: pageSize });
    setSelectedRowKeys([]);
  };

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const handleOpenEdit = () => {
    setIsOpenEditOrCreateCustomerOrCreate(true);
  };

  const handleCloseEditOrCreate = () => {
    setIsOpenEditOrCreateCustomerOrCreate(false);
  };

  const handleOpenCreateNewCustomer = () => {
    setIsOpenEditOrCreateCustomerOrCreate(true);
  };

  const handleOpenHistoryBookings = () => {
    setIsOpenHistoryBookings(true);
  };

  const handleCancelHistoryBookings = () => {
    setIsOpenHistoryBookings(false);
  };

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});
    if (page !== 1) {
      setPage(1);
    }
    const { ...restFilterOptions } = truthyValues;

    setFilterOptions(restFilterOptions);
    setIsEnable(true);
  };

  const handleOkEditCustomer = async () => {
    await queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_STAYING_GUESTS, page]
    });
    await queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_CUSTOMERS]
    });
    notification.success({ message: t('common.message.changeInfoSuccessfully') });
    setIsOpenEditOrCreateCustomerOrCreate(false);
    setSelectedRowKeys([]);
  };

  const columnCustomersManagement = useMemo(() => {
    return getColumnCustomersManagement(t);
  }, [t]);

  return (
    <div className="pms-customers-management">
      <Card title={t('managePage.customersPage.title')} style={{ width: '100%' }}>
        <Form form={form} layout={'inline'} onFinish={onFinish} autoComplete="off">
          <div className="pms-customers-management__filter-wrapper">
            <div className="pms-customers-management__filter">
              <Form.Item className="pms-customers-management__filter-id" name="id">
                <Input placeholder={t('common.customer.customerId')} />
              </Form.Item>
              <Form.Item className="pms-customers-management__filter-search" name="search">
                <Input placeholder={t('managePage.customersPage.findingCustomer')} />
              </Form.Item>
              <Form.Item
                className="pms-customers-management__filter-identification"
                name="identification"
              >
                <Input placeholder={t('managePage.customersPage.passportOrCCCD')} />
              </Form.Item>
              <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                {t('common.actions.submitButton')}
              </Button>
            </div>
            <div className="pms-customers-management__buttons">
              <Button onClick={handleOpenEdit} disabled={selectedRowKeys.length === 0}>
                {t('managePage.customersPage.edit')}
              </Button>
              <Button onClick={handleOpenHistoryBookings} disabled={selectedRowKeys.length === 0}>
                {t('managePage.customersPage.history')}
              </Button>
              <Button onClick={handleOpenCreateNewCustomer} disabled={selectedRowKeys.length > 0}>
                {t('managePage.customersPage.createNew')}
              </Button>
            </div>
          </div>
        </Form>
        <div className="pms-customers-management__table">
          <Table
            loading={isLoading}
            rowKey="id"
            columns={columnCustomersManagement}
            dataSource={customers}
            rowSelection={{ selectedRowKeys, onChange: onSelectChange, type: 'radio' }}
            bordered
            size="middle"
            locale={{
              emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
            }}
            onRow={record => ({
              onClick: () => {
                const selectedKey = record.id;
                const newSelectedRowKeys = selectedRowKeys.includes(selectedKey)
                  ? selectedRowKeys.filter(key => key !== selectedKey)
                  : [selectedKey];
                setSelectedRowKeys(newSelectedRowKeys);
              }
            })}
            pagination={{
              current: page,
              position: ['bottomCenter'],
              total: pagination.total,
              pageSize: pageSize,
              locale: { items_per_page: `/ ${t('common.page')}` },
              showLessItems: true,
              showSizeChanger: false,
              showTotal: total => `${total} items`,
              onChange: (page: number) => handleChangePage(page),
              onShowSizeChange: (_, size) => {
                setPageSize(size);
              }
            }}
            scroll={{ x: 2000, y: 'calc(100vh - 400px)' }}
          />
        </div>
        <EditCustomerModal
          isOpen={isOpenEditOrCreateCustomer}
          customer={selectedCustomer as PersonalInfoType}
          onOk={handleOkEditCustomer}
          onCancel={handleCloseEditOrCreate}
        />
        <CustomersHistoryBookings
          customer_id={selectedCustomer?.id}
          isOpen={isOpenHistoryBookings}
          onCancel={handleCancelHistoryBookings}
          t={t}
        />
      </Card>
    </div>
  );
}

export default CustomersManagement;
