import { useGetAllBooking } from 'hooks/useGetBooking';
import { getStartOfToday, toLocalTime } from 'utils';
import { BookingStatus } from 'services/api/type/booking.type';
import CustomTable from './CustomTable';
import { getColumnUsedRoomsTab } from 'constants/table';
import { useMemo } from 'react';

interface UsedRoomsFiltersProps {
  isEnable: boolean;
  dateToQuery: any;
  roomTypeToQuery: string;
  onCountChange: (count: number) => void;
  t: (key: string) => string;
}

export default function UsedRoomsTab({
  isEnable,
  dateToQuery,
  roomTypeToQuery,
  onCountChange,
  t
}: UsedRoomsFiltersProps) {
  const { data: bookingDetails, isLoading } = useGetAllBooking(
    {
      check_in_of_room_available: toLocalTime(getStartOfToday(dateToQuery), 'YYYY-MM-DD HH:mm:ss'),
      status: `${BookingStatus.INITIAL},${BookingStatus.CHECK_IN}`,
      short_code: roomTypeToQuery
    },
    isEnable
  );

  const columnUsedRoomsTab = useMemo(() => {
    return getColumnUsedRoomsTab(t);
  }, [t]);

  onCountChange(bookingDetails.length);

  return (
    <CustomTable
      rowKey="booking_line_id"
      loading={isLoading}
      columns={columnUsedRoomsTab}
      dataSource={bookingDetails || []}
      t={t}
    />
  );
}
