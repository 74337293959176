import React from 'react';
import _ from 'lodash';
import { Button, Checkbox, Form, Input, notification, Space, type FormProps } from 'antd';
// import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { UserOutlined, LockOutlined, MobileOutlined, MailOutlined } from '@ant-design/icons';
import { isMobileOnly } from 'react-device-detect';

import API from 'services/api';
import { MESSAGE_CODE } from 'constants/validate';
import { EmailRegex } from 'constants/regex';
import { JobCode, LoginType, MyInfoType, RegisterType } from 'services/api/type/user.type';
import PAGES from 'routes/constants';
import STATUS_CODE from 'services/api/statusCode';
import { setToken } from 'services/storages/userStorage';

import { ReactComponent as Logo } from 'assets/images/logo.svg';
import 'styles/login-register.scss';
import { useGetPermission } from 'hooks/useGetPermission';

function LoginRegister() {
  // const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const { refetch } = useGetPermission();

  const { mutateAsync: login, isPending } = useMutation({
    mutationFn: (data: LoginType) => {
      return API.user.login(data);
    }
  });

  const onFinishLogin: FormProps<LoginType>['onFinish'] = async (values: LoginType) => {
    try {
      const response = await login(values);
      if (_.get(response, 'status') === STATUS_CODE.SUCCESS) {
        setToken(_.get(response, 'data.data.token', ''));
        const user = await API.user.getMe();
        refetch();
        redirectToHome(user);
      }
    } catch (error: any) {
      notification.error({
        message: MESSAGE_CODE[error.code] || 'Có lỗi xảy ra'
      });
    }
  };

  const redirectToHome = (user: MyInfoType) => {
    if (!isMobileOnly) {
      navigate(PAGES.home);
      return;
    }
    switch (user.job_code) {
      case JobCode.HK:
        navigate(PAGES.hk);
        break;
      case JobCode.SUB_HK:
        navigate(PAGES.subHK);
        break;
      default:
        navigate(PAGES.home);
    }
  };

  const onFinishRegister: FormProps<LoginType>['onFinish'] = async (values: LoginType) => {
    console.log('onFinishRegister', values);
  };

  const isLoginPage = matchPath(PAGES.login, location.pathname);
  const isRegisterPage = matchPath(PAGES.register, location.pathname);

  return (
    <div className="pms-login-register">
      <div className="pms-login-register-inner">
        <div className="pms-logo-wrapper">
          <Logo />
          <p className="subtitle">Your second home Your Kin - PMS</p>
        </div>

        <ul className="pms-tab-wrapper">
          <li className={isLoginPage ? 'active' : ''}>
            <Link to={PAGES.login}>Đăng nhập</Link>
          </li>
          {/*<li className={isRegisterPage ? "active" : ""}>*/}
          {/*  <Link to={PAGES.register}>Đăng kí</Link>*/}
          {/*</li>*/}
        </ul>

        {/* Login page */}
        {isLoginPage && (
          <Form
            name="login-form"
            style={{ width: '360px' }}
            initialValues={{ email: '', password: '', remember: true }}
            onFinish={onFinishLogin}
            autoComplete="off"
          >
            <Form.Item<LoginType>
              name="email"
              rules={[
                {
                  required: true,
                  message: MESSAGE_CODE.REQUIRED_EMAIL
                },
                {
                  pattern: EmailRegex,
                  message: MESSAGE_CODE.WRONG_FORMAT_EMAIL
                }
              ]}
            >
              <Input
                size="large"
                placeholder="Tên"
                prefix={<UserOutlined style={{ fontSize: '16px' }} />}
              />
            </Form.Item>

            <Form.Item<LoginType>
              name="password"
              rules={[{ required: true, message: MESSAGE_CODE.REQUIRED_PASSWORD }]}
            >
              <Input.Password
                size="large"
                placeholder="Mật khẩu"
                prefix={<LockOutlined style={{ fontSize: '16px' }} />}
                iconRender={() => null}
              />
            </Form.Item>

            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Lưu</Checkbox>
            </Form.Item>

            <div className="login-form__action">
              <Button size="large" htmlType="submit" type="primary" loading={isPending}>
                Đăng nhập
              </Button>
            </div>
          </Form>
        )}

        {/* Register page */}
        {isRegisterPage && (
          <Form
            name="register-form"
            style={{ width: '360px' }}
            initialValues={{ email: '', password: '', remember: true }}
            onFinish={onFinishRegister}
            autoComplete="off"
          >
            <Form.Item<RegisterType>
              name="phoneNumber"
              rules={[
                {
                  required: true,
                  message: MESSAGE_CODE.REQUIRED_PHONE_NUMBER
                }
              ]}
            >
              <Input
                size="large"
                placeholder="Số điện thoại"
                prefix={<MobileOutlined style={{ fontSize: '16px' }} />}
              />
            </Form.Item>

            <Form.Item<RegisterType>
              name="otp"
              rules={[{ required: true, message: MESSAGE_CODE.REQUIRED_OTP }]}
            >
              <Space>
                <Input
                  size="large"
                  placeholder="Mã xác nhận"
                  prefix={<MailOutlined style={{ fontSize: '16px' }} />}
                />
                <Button size="large">Lấy Code</Button>
              </Space>
            </Form.Item>

            <div className="login-form__action">
              <Button size="large" htmlType="submit" type="primary" loading={isPending}>
                Đăng kí
              </Button>
            </div>
          </Form>
        )}
      </div>

      <div className="pms-copyright">
        <p>
          Copyright ©2024 Sản xuất bởi <span className="owner">Kin Hotel</span>
        </p>
      </div>
    </div>
  );
}

export default LoginRegister;
