import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Card, Col, DatePicker, Row, Spin, Table, TableProps } from 'antd';

import { useGetHouseStatus } from 'hooks/useGetHouseStatus';
import { Movement } from 'services/api/type/report.type';

import 'styles/house-status.scss';
import { useTranslation } from 'react-i18next';

const today = dayjs().startOf('day');

function HouseStatus() {
  const { t } = useTranslation();
  const [date, setDate] = useState(today);
  const { data: houseStatus, isFetching } = useGetHouseStatus(date.format('YYYY-MM-DD'));

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const columnMovementTable: TableProps<Movement>['columns'] = [
    {
      title: '',
      dataIndex: 'movementName',
      key: 'movementName',
      width: '200px'
    },
    {
      title: t('managePage.houseStatusPage.movement.room'),
      dataIndex: 'room',
      key: 'room',
      width: '100px',
      align: 'center'
    },
    {
      title: t('managePage.houseStatusPage.movement.pax'),
      dataIndex: 'pax',
      key: 'pax',
      width: '100px',
      align: 'center'
    }
  ];
  // const onSearch: FormProps["onFinish"] = async (values: any) => {};

  const handleChangeDate = (value: any) => {
    setDate(value);
  };

  const roomSummary = useMemo(() => {
    if (houseStatus) {
      return Object.keys(houseStatus.room_summary).map(key => {
        return {
          label: t(MapRoomSummaryLabel[key]),
          qty: _.get(houseStatus.room_summary, key)
        };
      });
    }
    return [];
  }, [houseStatus, t]);

  const rowData = useMemo(() => {
    if (houseStatus) {
      return Object.keys(houseStatus.movement).map(key => {
        return {
          movementName: t(MapMovementLabel[key]),
          room: houseStatus.movement[key].room,
          pax: houseStatus.movement[key].pax
        };
      });
    }
    return [];
  }, [houseStatus, t]);

  const houseKeeping = useMemo(() => {
    if (houseStatus) {
      return Object.keys(houseStatus.housekeeping).map(key => {
        return {
          label: t(MapHouseKeepingLabel[key]),
          occ: _.get(houseStatus.housekeeping, `${key}.occ`, _.get(houseStatus.housekeeping, key)),
          vac: _.get(houseStatus.housekeeping, `${key}.vac`)
        };
      });
    }
    return [];
  }, [houseStatus, t]);

  return (
    <div className="pms-house-status">
      <div className="pms-house-status__summary">
        <div className="pms-house-status__filter">
          <div className="pms-house-status__filter__left">
            <DatePicker
              value={date}
              onChange={handleChangeDate}
              placeholder={t('common.time.selectDate')}
            />
          </div>
        </div>

        <div className="flex flex-wrap" style={{ gap: 16 }}>
          {/* Room summary */}
          <Card
            title={t('managePage.houseStatusPage.roomSummary.title')}
            bordered={false}
            className="card-room-summary"
          >
            {isFetching && <Spin />}
            {!isFetching &&
              !!roomSummary.length &&
              roomSummary.map((data, index) => (
                <Row gutter={[8, 8]} key={index}>
                  <Col span={14}>
                    <span>{data.label}</span>
                  </Col>
                  <Col span={10}>
                    <b>{data.qty}</b>
                  </Col>
                </Row>
              ))}
          </Card>

          {/* House keeping */}
          <Card
            title="Housekeeping (Current status)"
            bordered={false}
            className="card-room-summary"
          >
            {isFetching && <Spin />}
            {!isFetching && (
              <Row gutter={[8, 8]}>
                <Col span={8}></Col>
                <Col span={6} className="text-center">
                  <b>{t('managePage.houseStatusPage.houseKeeping.occ')}</b>
                </Col>
                <Col span={6} className="text-center">
                  <b>{t('managePage.houseStatusPage.houseKeeping.vac')}</b>
                </Col>
              </Row>
            )}

            {!isFetching &&
              !!houseKeeping.length &&
              houseKeeping.map((data, index) => (
                <Row gutter={[8, 8]} key={index}>
                  <Col span={8}>
                    <span>{data.label}</span>
                  </Col>
                  <Col span={6} className="text-center">
                    <b>{data.occ}</b>
                  </Col>
                  {!_.isNil(data.vac) && (
                    <Col span={6} className="text-center">
                      <b>{data.vac}</b>
                    </Col>
                  )}
                </Row>
              ))}
          </Card>

          {/* Movement */}
          <Card title="Movement" bordered={false} className="card-room-summary">
            <div className="pms-house-status__table">
              <Table
                rowKey="movementName"
                loading={isFetching}
                columns={columnMovementTable}
                dataSource={rowData}
                pagination={false}
                scroll={{ x: 340 }}
                bordered
                locale={{
                  emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
                }}
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
}

const MapRoomSummaryLabel: {
  [key: string]: string;
} = {
  total_hotel_rooms: 'managePage.houseStatusPage.roomSummary.totalHotelRooms',
  total_available_rooms: 'managePage.houseStatusPage.roomSummary.totalAvailableRooms',
  out_of_order: 'managePage.houseStatusPage.roomSummary.outOfOrder',
  total_saleable_rooms: 'managePage.houseStatusPage.roomSummary.totalSaleableRooms'
};

const MapHouseKeepingLabel: {
  [key: string]: string;
} = {
  clean_rooms: 'managePage.houseStatusPage.houseKeeping.cleanRooms',
  dirty_rooms: 'managePage.houseStatusPage.houseKeeping.dirtyRooms',
  out_of_service: 'managePage.houseStatusPage.houseKeeping.outOfService'
};

const MapMovementLabel: {
  [key: string]: string;
} = {
  departures_expected: 'managePage.houseStatusPage.movement.departuresExpected',
  departures_actual: 'managePage.houseStatusPage.movement.departuresActual',
  arrivals_expected: 'managePage.houseStatusPage.movement.arrivalsExpected',
  arrivals_actual: 'managePage.houseStatusPage.movement.arrivalsActual',
  pre_assigned_rooms: 'managePage.houseStatusPage.movement.preAssignedRoom',
  current_occupied: 'managePage.houseStatusPage.movement.currentOccupied',
  extended_stays: 'managePage.houseStatusPage.movement.extendedStays',
  early_departures: 'managePage.houseStatusPage.movement.earlyDepartures',
  day_rooms: 'managePage.houseStatusPage.movement.dayRooms',
  same_day_reservations: 'managePage.houseStatusPage.movement.sameDayReservation',
  walk_ins: 'managePage.houseStatusPage.movement.walkIns'
};

export default HouseStatus;
