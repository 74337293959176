import React, { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import {
  Button,
  Card,
  DatePicker,
  DatePickerProps,
  Form,
  FormProps,
  Table,
  Input,
  TimeRangePickerProps,
  notification
} from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useMutation } from '@tanstack/react-query';

import ExportCashFlow from 'components/common/export-cash-flow';
import { useGetCashFlow, useGetCashFlowAll } from 'hooks/useGetCashFlow';
import useModal from 'stores/useModal';

import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { editCashPayment, PAGE_SIZE_CASH_FLOW } from 'services/api/module/report.api';
import { formatCurrency, toUtcTime } from 'utils';
import { EditCashPayment } from 'services/api/type/report.type';
import { RangeValueType } from 'services/api/type/common.type';
import { getCashFlowColumns } from 'constants/table';
import { CASHFLOW_TYPE } from 'constants/common';
import 'styles/cash-book-detail.scss';

import { PermissionActionTypes } from 'helper/type/action.type';
import IBButton from 'helper/permission.action';
import { useTranslation } from 'react-i18next';

const disabledDate: DatePickerProps['disabledDate'] = (current, { from }) => {
  if (from) {
    return current.isBefore(from);
  }

  return false;
};

const initStartDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
const initEndDate = dayjs().set('hour', 23).set('minute', 59).set('second', 59);

function CashBookDetail() {
  const { t } = useTranslation();

  const rangePresets: TimeRangePickerProps['presets'] = [
    { label: t('common.time.today'), value: [dayjs(), dayjs()] },
    {
      label: t('common.time.thisWeek'),
      value: [dayjs().isoWeekday(1), dayjs().isoWeekday(7)]
    },
    {
      label: t('common.time.thisMonth'),
      value: [dayjs().startOf('month'), dayjs().endOf('month')]
    },
    {
      label: t('common.time.lastMonth'),
      value: [
        dayjs().startOf('month').subtract(1, 'day').startOf('month'),
        dayjs().startOf('month').subtract(1, 'day')
      ]
    },
    {
      label: t('common.time.thisQuarter'),
      value: [dayjs().startOf('quarter'), dayjs().endOf('quarter')]
    },
    {
      label: t('common.time.lastQuarter'),
      value: [
        dayjs().startOf('quarter').subtract(1, 'day').startOf('quarter'),
        dayjs().startOf('quarter').subtract(1, 'day')
      ]
    },
    {
      label: t('common.time.sixMonthAgo'),
      value: [
        dayjs().subtract(6, 'month').startOf('month'),
        dayjs().subtract(6, 'month').endOf('month')
      ]
    },
    {
      label: t('common.time.thisYear'),
      value: [dayjs().startOf('year'), dayjs().endOf('year')]
    },
    {
      label: t('common.time.lastYear'),
      value: [
        dayjs().startOf('year').subtract(1, 'day').startOf('year'),
        dayjs().startOf('year').subtract(1, 'day')
      ]
    }
  ];

  const [form] = Form.useForm();
  const rangeDateForm = Form.useWatch('rangeDate', form);
  const isIpadMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });

  const { setIsOpenAddExpense, setIsOpenConfirmDeletePayment } = useModal();
  const [filterOptions, setFilterOptions] = useState<any>({
    start_date: toUtcTime(initStartDate, 'YYYY-MM-DD HH:mm:ss'),
    end_date: toUtcTime(initEndDate, 'YYYY-MM-DD HH:mm:ss')
  });

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_CASH_FLOW);
  const { data, pagination, isFetching } = useGetCashFlow(filterOptions, page, pageSize);
  const { data: dataAll } = useGetCashFlowAll(filterOptions);
  // edit cash payment
  const [paymentId, setPaymentId] = useState<number | undefined>(undefined);
  const [note, setNote] = useState<string | undefined>(undefined);
  const [expenseType, setExpenseType] = useState<string | undefined>(undefined);
  const [outcomeAmount, setOutcomeAmount] = useState<number | undefined>(undefined);
  const [finshedEdit, setCompleteEdit] = useState<boolean>(false);

  const { mutateAsync: mutateEditCashPayment } = useMutation({
    mutationFn: (params: EditCashPayment) => editCashPayment(params)
  });

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_CASH_FLOW, page]
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_CASH_FLOW_ALL]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  useEffect(() => {
    const updateCashPayment = async () => {
      if (finshedEdit && paymentId) {
        try {
          await mutateEditCashPayment({
            payment_id: paymentId,
            note: note,
            expense_type: expenseType,
            amount: outcomeAmount
          });
          await queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.GET_CASH_FLOW, page]
          });
          await queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.GET_CASH_FLOW_ALL]
          });
          notification.success({
            message: t('common.message.changeInfoSuccessfully')
          });
        } catch (err: any) {
          notification.error({
            message: err.error || t('common.error')
          });
        } finally {
          setCompleteEdit(false);
          setPaymentId(undefined);
        }
      }
    };
    updateCashPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finshedEdit, paymentId]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, ...restFilterOptions } = truthyValues;

    if (rangeDate) {
      const startDate: Dayjs = rangeDate[0];
      const endDate: Dayjs = rangeDate[1];
      if (startDate) {
        _.set(restFilterOptions, 'start_date', toUtcTime(startDate, 'YYYY-MM-DD HH:mm:ss'));
      }
      if (endDate) {
        _.set(restFilterOptions, 'end_date', toUtcTime(endDate, 'YYYY-MM-DD HH:mm:ss'));
      }
    }

    if (page !== 1) {
      setPage(1);
    }
    setFilterOptions(restFilterOptions);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleShowAddExpense = () => {
    setIsOpenAddExpense(true);
  };

  const handleRangeDateChange = (value: RangeValueType | null) => {
    let startDate: Dayjs | null = _.get(value, '0', null);
    let endDate: Dayjs | null = _.get(value, '1', null);
    if (startDate) {
      startDate = startDate.set('hour', 0).set('minute', 0).set('second', 0);
    }
    if (endDate) {
      endDate = endDate.set('hour', 23).set('minute', 59).set('second', 59);
    }
    form.setFieldValue('rangeDate', [startDate, endDate]);
  };

  const totalIncome = useMemo(() => {
    return dataAll
      .filter(item => item.state !== 'cancelled')
      .reduce((cur, next) => {
        return cur + (next.payment_type === CASHFLOW_TYPE.INCOME ? next.amount : 0);
      }, 0);
  }, [dataAll]);

  const totalOutcome = useMemo(() => {
    return dataAll.reduce((cur, next) => {
      return cur + (next.payment_type === CASHFLOW_TYPE.PAYMENT ? next.amount : 0);
    }, 0);
  }, [dataAll]);

  const cashFlowColumns = useMemo(() => {
    const handleEdit = (paymentId: number) => {
      if (paymentId) {
        setPaymentId(paymentId);
      } else {
        setPaymentId(undefined);
      }
    };

    const handleDelete = (paymentId: number) => {
      if (paymentId) {
        setIsOpenConfirmDeletePayment(true, paymentId);
      } else {
        setIsOpenConfirmDeletePayment(false);
      }
    };

    return getCashFlowColumns(
      page,
      t,
      handleEdit,
      paymentId,
      setNote,
      setExpenseType,
      setOutcomeAmount,
      setCompleteEdit,
      handleDelete
    );
    // eslint-disable-next-line
  }, [page, paymentId]);

  return (
    <div className="pms-cash-book-detail">
      <Card style={{ width: '100%' }}>
        <Form
          form={form}
          name="cash-book-detail-form"
          layout={isIpadMobile ? 'vertical' : 'inline'}
          initialValues={{
            rangeDate: [initStartDate, initEndDate]
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-cash-book-detail__filter-wrapper">
            <div className="pms-cash-book-detail__filter">
              <Form.Item name="rangeDate">
                <DatePicker.RangePicker
                  presets={rangePresets}
                  className="w-full"
                  placeholder={[t('common.time.startDate'), t('common.time.endDate')]}
                  disabledDate={disabledDate}
                  format="YYYY-MM-DD"
                  onChange={handleRangeDateChange}
                />
              </Form.Item>
              <Form.Item name="booking_id">
                <Input placeholder={t('common.bookingData.bookingId')} />
              </Form.Item>
              <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                {t('common.actions.submitButton')}
              </Button>
            </div>

            <div className="flex items-center" style={{ gap: '8px' }}>
              <IBButton
                permission={PermissionActionTypes.INCOME_EXPENSE_CREATE}
                type="primary"
                onClick={handleShowAddExpense}
              >
                {t('managePage.cashBookDetailPage.createPayment')}
              </IBButton>
              <ExportCashFlow
                filterOptions={{
                  start_date: toUtcTime(
                    _.get(rangeDateForm, '0') || initStartDate,
                    'YYYY-MM-DD HH:mm:ss'
                  ),
                  end_date: toUtcTime(
                    _.get(rangeDateForm, '1') || initEndDate,
                    'YYYY-MM-DD HH:mm:ss'
                  )
                }}
                fileName={`cash-book-detail-${(_.get(rangeDateForm, '0') || initStartDate)?.format(
                  'DD-MM-YYYY'
                )}-${(_.get(rangeDateForm, '1') || initEndDate)?.format('DD-MM-YYYY')}-${nanoid()}`}
                isEnableV2={false}
                t={t}
              />
            </div>
          </div>
        </Form>

        <div className="pms-cash-book-detail__table">
          <Table
            loading={isFetching}
            rowKey="id"
            columns={cashFlowColumns}
            dataSource={data}
            bordered
            // onRow={(record, rowIndex) => {
            //   return {
            //     }, // click row
            //   };
            // }}
            pagination={{
              current: page,
              position: ['bottomCenter'],
              total: pagination.total,
              pageSize: pageSize,
              locale: { items_per_page: `/ ${t('common.page')}` },
              showTotal: total => `${total} items`,
              onChange: (page: number) => handleChangePage(page),
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              }
            }}
            locale={{
              emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
            }}
            scroll={{ x: 500, y: 400 }}
          />
        </div>
      </Card>

      <div className="summary-cashflow">
        <div className="summary-cashflow__item">
          <span>{t('managePage.cashBookDetailPage.cashConfirm.openingBalance')}</span>
          <span>{t('common.currency')} 0</span>
        </div>
        <div className="summary-cashflow__item">
          <span>{t('managePage.cashBookDetailPage.cashConfirm.totalCollected')}</span>
          <span>{formatCurrency(totalIncome, t('common.currency'))}</span>
        </div>
        <div className="summary-cashflow__item">
          <span>{t('managePage.cashBookDetailPage.cashConfirm.totalExpenditure')}</span>
          <span>{formatCurrency(totalOutcome, t('common.currency'))}</span>
        </div>
        <div className="summary-cashflow__item">
          <span>{t('managePage.cashBookDetailPage.cashConfirm.endingBalance')}</span>
          <span>{formatCurrency(totalIncome - totalOutcome, t('common.currency'))}</span>
        </div>
      </div>
    </div>
  );
}

export default CashBookDetail;
