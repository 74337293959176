import React, { useEffect, useState } from 'react';
import { Button, Modal, notification, Spin } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { signMinvoice } from 'services/api/module/booking.api';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import { LoadingDots } from 'utils/loadingDots';

interface InvoicePreviewModalProps {
  isOpen: boolean;
  previewUrl?: string;
  isLoading: boolean;
  guestOrderId?: number;
  currentOrder: any;
  onClose: () => void;
}

function InvoicePreviewModal({
  isOpen,
  previewUrl,
  isLoading,
  guestOrderId,
  currentOrder,
  onClose
}: InvoicePreviewModalProps) {
  const { t } = useTranslation();
  const [isSigned, setIsSigned] = useState<boolean>(false);

  const { mutateAsync: mutateSignMinvoice } = useMutation({
    mutationFn: (params: any) => signMinvoice(params.guest_order_id)
  });

  const handleSignMinvoice = async () => {
    if (!guestOrderId) return;

    try {
      await mutateSignMinvoice({ guest_order_id: guestOrderId });
      notification.success({
        message: t('common.message.signMinvoiceSuccessfully')
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_GUEST_TRANSACTIONS, currentOrder.booking_line_guest_id]
      });
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.GET_MINVOICE_PREVIEW, guestOrderId]
      });
    } catch (error: any) {
      notification.error({
        message: error.error || t('common.error')
      });
    }
  };

  useEffect(() => {
    setIsSigned(currentOrder?.einvoice_signed);
  }, [currentOrder]);

  return (
    <Modal
      title={t('cashierPage.previewMinvoicePage.title')}
      centered
      width={1135}
      open={isOpen}
      onCancel={onClose}
      footer={[
        <Button
          key="sign"
          onClick={handleSignMinvoice}
          className="ant-btn-secondary"
          disabled={isSigned || isLoading || !previewUrl}
          loading={isLoading}
        >
          {t('cashierPage.previewMinvoicePage.sign')}
        </Button>,
        <Button key="close" type="default" onClick={onClose} disabled={isLoading}>
          {t('cashierPage.invoice.cancel')}
        </Button>
      ]}
    >
      <div style={{ position: 'relative', minHeight: '700px', width: '100%' }}>
        <Spin
          spinning={isLoading}
          indicator={<LoadingDots />}
          size="large"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 10
          }}
        >
          <div style={{ minHeight: '700px' }}>
            {previewUrl ? (
              <iframe
                src={previewUrl}
                width="100%"
                height="700px"
                title="Invoice Preview"
                style={{ border: 'none' }}
              />
            ) : (
              !isLoading && (
                <div
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                    padding: '20px'
                  }}
                >
                  <p style={{ color: 'red', fontSize: '16px' }}>
                    {t('common.error')} - {t('cashierPage.previewMinvoicePage.previewNotAvailable')}
                  </p>
                </div>
              )
            )}
          </div>
        </Spin>
      </div>
    </Modal>
  );
}

export default InvoicePreviewModal;
