import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import API from 'services/api/index';
import QUERY_KEYS from 'services/api/queryKeys';
import { getToken } from 'services/storages/userStorage';

const enablePermission = JSON.parse(process.env.REACT_APP_ENABLE_PERMISSION || 'false');

export const useGetPermission = () => {
  const token = getToken();
  const { data, isLoading, refetch } = useQuery({
    queryKey: [QUERY_KEYS.GET_PERMISSION],
    // staleTime: 1000 * 60,
    queryFn: () => API.user.getPermission(),
    refetchOnWindowFocus: false,
    enabled: !!token
  });

  const permissions = useMemo(() => {
    if (!data) return {};

    return data.reduce<Record<string, boolean>>((acc, role) => {
      if (role.permissions) {
        role.permissions.forEach((perm: { code: string }) => {
          acc[perm.code] = true;
        });
      }
      return acc;
    }, {});
  }, [data]);

  return {
    permissions: enablePermission && permissions ? permissions : {},
    isLoading,
    refetch
  };
};
