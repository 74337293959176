export const TrackingLogsType: { [key: string]: string } = {
  booking_payment_add: 'Thêm thanh toán',
  booking_cash_payment_add: 'Thêm phiếu thu',
  booking_payment_removed: 'Xóa thanh toán',
  booking_payment_refunded: 'Nhận lại tiền',
  booking_checkin: 'Nhận phòng',
  booking_checkout: 'Trả phòng',
  booking_undo_checkin: 'Hoàn tác nhận phòng',
  booking_undo_checkout: 'Hoàn tác trả phòng',
  booking_booked: 'Đặt phòng',
  booking_cancel: 'Hủy phòng',
  booking_change_price: 'Thay đổi giá tiền',
  booking_change_date: 'Thay đổi ngày ở',
  booking_change_room: 'Đổi phòng',
  booking_assign_room: 'Gán phòng',
  booking_change_room_type: 'Đổi hạn phòng',
  booking_change_note: 'Thay đổi note',
  duplicated_booking_line: 'Nhân đôi đơn đặt',
  undo_cancelled_booking: 'Hoàn tác hủy đặt phòng'
};

export interface ParamsGetTrackingLogs {
  booking_line_id: number;
  start_date: string;
  end_date: string;
  type: string;
}
