import React, { useState } from 'react';

import dayjs, { Dayjs } from 'dayjs';

import { DatePicker, Form, Input, Button, Tabs, Select } from 'antd';

import { generateExportFileName, toLocalTime, toUtcTime } from 'utils';

import { FormProps } from 'rc-field-form';
import _ from 'lodash';

import RoomsFocTab from 'components/modal/foc-report-components/RoomsFocTab';
import ServicesFocTab from 'components/modal/foc-report-components/ServicesFocTab';

import 'styles/foc-list.scss';
import { useTranslation } from 'react-i18next';
import { GUEST_LEVEL_NAME, LEVEL_ENUM_OPTIONS, MAP_GUEST_LEVEL } from 'constants/common';
import ExportFocsReport from 'components/common/export-focs-report';

const INITIAL_RANGE_DATE = 14;
const initStartDate = dayjs().startOf('day');

function FocsReport() {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [filterOptions, setFilterOptions] = useState<any>({
    check_in: toUtcTime(initStartDate.add(1, 'day'), 'YYYY-MM-DD'),
    period: INITIAL_RANGE_DATE,
    is_expandable: false
  });

  const [keyTab, setKeyTab] = useState<string>('rooms-foc');
  const [dataSource, setDataSource] = useState<any[]>([]);

  const onTabClick = (key: string) => {
    setKeyTab(key);
  };

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, period, level, ...restFilterOptions } = truthyValues;
    if (rangeDate) {
      const startDate: Dayjs = rangeDate[0];
      const endDate: Dayjs = rangeDate[1];

      if (startDate && endDate) {
        const dayCount = endDate.diff(startDate, 'day');
        _.set(restFilterOptions, 'period', dayCount);
        _.set(restFilterOptions, 'check_in', toUtcTime(startDate.add(1, 'day'), 'YYYY-MM-DD'));
      }
    }
    if (level) {
      _.set(restFilterOptions, 'level', level);
    }

    _.set(restFilterOptions, 'period', period || INITIAL_RANGE_DATE);

    setFilterOptions(restFilterOptions);
  };

  const handleRangeDateChange = (dates: any) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      const dayCount = end.diff(start, 'day');
      form.setFieldsValue({ period: dayCount });
    }
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const period = parseInt(e.target.value);
    if (!isNaN(period)) {
      const startDate = dayjs(form.getFieldValue('rangeDate')[0]);
      const endDate = startDate.add(period, 'day');
      form.setFieldsValue({ rangeDate: [startDate, endDate] });
    }
  };

  const fromDate = () => toLocalTime(filterOptions.check_in, 'DD/MM/YYYY');
  const toDate = () =>
    toLocalTime(
      dayjs(filterOptions.check_in).add(filterOptions.period, 'day').format(),
      'DD/MM/YYYY'
    );
  const level = () => {
    const genderKey = MAP_GUEST_LEVEL[filterOptions.level];
    return GUEST_LEVEL_NAME[genderKey as keyof typeof GUEST_LEVEL_NAME];
  };

  const handleSetDataSource = (data: any[]) => {
    setDataSource(data);
  };

  return (
    <div className="pms-foc-list">
      <div className="pms-foc-list__timetable">
        <Form
          form={form}
          initialValues={{
            rangeDate: [initStartDate, initStartDate.add(INITIAL_RANGE_DATE, 'day')],
            period: INITIAL_RANGE_DATE
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-foc-list__filter">
            <div className="pms-foc-list__filter__left">
              <Form.Item name="rangeDate">
                <DatePicker.RangePicker
                  className="w-full"
                  placeholder={[t('common.time.fromDate'), t('common.time.toDate')]}
                  format="DD-MM-YYYY"
                  onChange={handleRangeDateChange}
                />
              </Form.Item>
              <Form.Item name="period">
                <Input placeholder={t('common.time.totalDays')} onChange={handlePeriodChange} />
              </Form.Item>
              {keyTab == 'rooms-foc' && (
                <Form.Item name="level">
                  <Select
                    options={LEVEL_ENUM_OPTIONS}
                    placeholder={t('managePage.focsReportPage.focRoom.level')}
                    style={{ width: 100 }}
                    allowClear
                  />
                </Form.Item>
              )}
              <Form.Item>
                <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                  {t('common.actions.submitButton')}
                </Button>
              </Form.Item>
            </div>
            {keyTab == 'rooms-foc' && (
              <ExportFocsReport
                fileName={generateExportFileName('focs-report')}
                dataToExport={dataSource}
                t={t}
                fromDate={fromDate()}
                toDate={toDate()}
                level={level()}
              />
            )}
          </div>
        </Form>
        <div className="pms-foc-list__tabs">
          <Tabs type="card" activeKey={keyTab} onTabClick={onTabClick} defaultActiveKey="1">
            <Tabs.TabPane tab={t('managePage.focsReportPage.focRoom.title')} key="rooms-foc">
              <RoomsFocTab
                isEnable={keyTab == 'rooms-foc'}
                filterOptions={filterOptions}
                t={t}
                dataSource={handleSetDataSource}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t('managePage.focsReportPage.focService.title')} key="services-foc">
              <ServicesFocTab
                isEnable={keyTab == 'services-foc'}
                filterOptions={filterOptions}
                t={t}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default FocsReport;
