import React, { useEffect } from 'react';
import { Modal, Form, Card, Row, Col, DatePicker, Select, InputNumber, Input } from 'antd';
import {
  BookingLine,
  FOLIO_BALANCE_CODE,
  TransactionCodesResponse,
  TransactionData
} from 'services/api/type/booking.type';
import { formatInputNumber } from 'utils/currency';
import { MyInfoType } from 'services/api/type/user.type';
import { useTranslation } from 'react-i18next';

interface RenderModalProps {
  isOpen: boolean;
  handleClose: () => void;
  form: any;
  onFinish: (values: any) => void;
  isPending: boolean;
  folio_balance_code?: string;
  guest_id?: number | undefined;
  bookingLineDetail?: BookingLine;
  userData?: MyInfoType;
  transactionCodes?: TransactionCodesResponse[];
  orderDetails?: TransactionData;
  uniqueTransactionCodesName: Set<string[] | undefined>;
  title: string;
  dummyBalance?: number;
}

export const RenderPostTransactionModal = ({
  isOpen,
  handleClose,
  form,
  onFinish,
  isPending,
  guest_id,
  bookingLineDetail,
  userData,
  folio_balance_code,
  transactionCodes,
  orderDetails,
  uniqueTransactionCodesName,
  title,
  dummyBalance
}: RenderModalProps) => {
  const { t } = useTranslation();
  useEffect(() => {
    form.setFieldValue('folio_balance_code', folio_balance_code);
  }, [folio_balance_code, form]);

  useEffect(() => {
    if (!isOpen) {
      form.resetFields();
    }
  }, [form, isOpen]);

  return (
    <Modal
      title={title}
      centered
      width={900}
      open={isOpen}
      onCancel={handleClose}
      okText={t('common.actions.save')}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      onOk={() => form.submit()}
      cancelText={t('common.actions.cancel')}
      className="modal-change-price post-payment-transaction-modal"
      confirmLoading={isPending}
      destroyOnClose
    >
      <Form
        form={form}
        layout="horizontal"
        onFinish={onFinish}
        autoComplete="off"
        className="post-payment-transaction-form"
      >
        <Card className="post-payment-transaction-card">
          <Row gutter={[16, 0]} className="post-payment-transaction-card__row">
            <Col span={12} className="post-payment-transaction-card__col-left">
              <div className="post-payment-transaction-card__body-left">
                <Row gutter={[8, 0]} className="w-full equal-spacing-row">
                  <Col span={24} className="post-payment-transaction-card__col">
                    <Form.Item
                      label={t('cashierPage.common.date')}
                      name="transaction_date"
                      rules={[{ required: true, message: 'Please select date' }]}
                      labelCol={{ span: 10, style: { paddingRight: '0' } }}
                    >
                      <DatePicker format="DD-MM-YYYY" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col span={24} className="post-payment-transaction-card__col">
                    <Form.Item
                      label={t('cashierPage.common.guestName')}
                      labelCol={{ span: 10, style: { paddingRight: '0' } }}
                    >
                      <div className="post-payment-transaction-card__value">
                        {bookingLineDetail?.booking_line_guests
                          ? bookingLineDetail?.booking_line_guests
                              .filter(item => item.id === guest_id)
                              .map(item => item.guest_name)
                          : 'TBD*'}
                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={24} className="post-payment-transaction-card__col">
                    <Form.Item
                      label={t('cashierPage.common.cashier')}
                      labelCol={{ span: 10, style: { paddingRight: '0' } }}
                    >
                      <div className="post-payment-transaction-card__value">{userData?.name}</div>
                    </Form.Item>
                  </Col>
                  <Col span={24} className="post-payment-transaction-card__col">
                    <Form.Item
                      label={<strong>{t('cashierPage.common.type')}</strong>}
                      name="transaction_code_id"
                      rules={[{ required: true, message: 'Please select a transaction type' }]}
                      labelCol={{ span: 10, style: { paddingRight: '0' } }}
                    >
                      <Select style={{ width: '100%' }} placeholder="Select Transaction Type">
                        {Array.from(uniqueTransactionCodesName)
                          .flat()
                          .map(name => {
                            const matchingCode = transactionCodes?.find(code => code.name === name);
                            return (
                              matchingCode && (
                                <Select.Option key={matchingCode.id} value={matchingCode.id}>
                                  {name}
                                </Select.Option>
                              )
                            );
                          })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col span={12} className="post-payment-transaction-card__col-right">
              <div className="post-payment-transaction-card__body-right">
                <Row gutter={[8, 0]} className="w-full equal-spacing-row">
                  <Col span={24} className="post-payment-transaction-card__col">
                    <Form.Item
                      label={t('cashierPage.common.transactionPage')}
                      name="folio_balance_code"
                      labelCol={{ span: 10, style: { paddingRight: '0' } }}
                    >
                      <Select options={FOLIO_BALANCE_CODE} />
                    </Form.Item>
                  </Col>
                  <Col span={24} className="post-payment-transaction-card__col">
                    <Form.Item
                      label={t('cashierPage.balance')}
                      labelCol={{ span: 10, style: { paddingRight: '0' } }}
                    >
                      <div className="post-payment-transaction-card__value">
                        {dummyBalance
                          ? formatInputNumber(dummyBalance) || 0
                          : formatInputNumber(orderDetails?.balance || 0)}
                      </div>
                    </Form.Item>
                  </Col>
                  <Col span={24} className="post-payment-transaction-card__col">
                    <Form.Item
                      label={<strong>{t('cashierPage.payment.paymentAmount')}</strong>}
                      name="amount"
                      labelCol={{ span: 10, style: { paddingRight: '0' } }}
                    >
                      <InputNumber
                        defaultValue={0}
                        formatter={value => formatInputNumber(value)}
                        style={{ width: '100%' }}
                        addonAfter="VND"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col span={24} className="post-payment-transaction-note-row">
              <Form.Item label={t('common.noteEn')} name="note">
                <Input.TextArea
                  placeholder={t('common.noteEn')}
                  rows={3}
                  style={{ width: '100%' }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Form>
    </Modal>
  );
};
