import { Badge } from 'antd';
import React from 'react';
import { BellOutlined } from '@ant-design/icons';
import { usePermissions } from 'AuthorizePermission';

interface IBBellProps {
  permission: string;
  unReadMessages: number;
  handleRedirectToChat: () => void;
}

const IBBell: React.FC<IBBellProps> = ({
  permission,
  unReadMessages,
  handleRedirectToChat
}: IBBellProps) => {
  const { hasPermission } = usePermissions();

  if (!hasPermission(permission)) {
    return null;
  }

  return (
    <Badge count={unReadMessages} size="small" offset={[10, 5]}>
      <BellOutlined
        size={32}
        onClick={handleRedirectToChat}
        style={{
          color: 'white',
          fontSize: '20px',
          cursor: 'pointer'
        }}
      />
    </Badge>
  );
};

export default IBBell;
