import { useQuery } from '@tanstack/react-query';
import QUERY_KEYS from 'services/api/queryKeys';
import API from 'services/api';

export const useGetBookingConfirmationTemplate = (bookingId: number, temp: string) => {
  const { data, isFetching } = useQuery({
    queryKey: [QUERY_KEYS.GET_BOOKING_CONFIRMATION_TEMPLATE],
    queryFn: () => API.booking.getBookingConfirmationTemplate(Number(bookingId), temp),
    refetchOnWindowFocus: false,
    enabled: !!bookingId
  });
  return {
    data,
    isFetching
  };
};
