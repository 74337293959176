import React, { useEffect } from 'react';
import { Modal, Form, Radio, Button, notification } from 'antd';

import useBookingStore from 'stores/useBooking';

import { useTranslation } from 'react-i18next';
import { LanguageEnum, LanguageLabels } from 'components/common/constant';
import { useMutation } from '@tanstack/react-query';
import API from 'services/api';
import useModal from 'stores/useModal';

function CreateSurvey() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const language = Form.useWatch('language', form);

  const { bookingLineId } = useBookingStore();
  const { isOpenChooseSurveyLanguage, survey_type, setIsChooseSurveyLanguage } = useModal();

  const {
    mutateAsync: mutateCreateSurvey,
    isPending,
    data: surveyResponse
  } = useMutation({
    mutationFn: (body: any = {}) => API.booking.createSurvey(body)
  });

  useEffect(() => {
    if (surveyResponse?.survey_url) {
      setIsChooseSurveyLanguage(false, '');
      window.open(surveyResponse.survey_url, '_blank');
    }
  }, [surveyResponse, setIsChooseSurveyLanguage]);

  const handleCloseCreateSurvey = () => {
    setIsChooseSurveyLanguage(false, '');
    form.resetFields();
  };

  const handleCreateSurvey = async () => {
    try {
      await mutateCreateSurvey({
        booking_line_id: bookingLineId,
        survey_type: survey_type,
        lang: language
      });
    } catch (err: any) {
      console.log(err);
      notification.error({
        message: err.error || t('common.error')
      });
    }
  };

  return (
    <Modal
      title={t('common.actions.createSurvey')}
      centered
      width={600}
      open={isOpenChooseSurveyLanguage}
      onCancel={handleCloseCreateSurvey}
      okText={null}
      cancelText="Đóng"
      className="modal-assign-room"
      destroyOnClose
      confirmLoading={isPending}
      footer={[
        <Button key="ok" onClick={handleCreateSurvey} className="ant-btn-secondary">
          {t('common.actions.save')}
        </Button>
      ]}
    >
      <Form form={form}>
        <Form.Item
          name="language"
          rules={[{ required: true, message: 'Please select a language' }]}
        >
          <Radio.Group
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              paddingTop: '10px'
            }}
          >
            {Object.entries(LanguageEnum).map(([key, value]) => (
              <Radio key={key} value={value}>
                {LanguageLabels[value]}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CreateSurvey;
