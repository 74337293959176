export const ACTION_BOOKING = {
  DETAIL: 'DETAIL',
  DETAIL_DIVIDER: 'DETAIL_DIVIDER',
  DETAIL_GROUP: 'DETAIL_GROUP',
  GET_ROOM: 'GET_ROOM',
  ADD_SERVICE: 'ADD_SERVICE',
  CHANGE_DATE: 'CHANGE_DATE',
  CHANGE_ROOM: 'CHANGE_ROOM',
  CHANGE_PRICE: 'CHANGE_PRICE',
  CANCEL_BOOKING: 'CANCEL_BOOKING',
  CANCEL_BOOKING_DIVIDER: 'CANCEL_BOOKING_DIVIDER',
  QUICK_GET_ROOM: 'QUICK_GET_ROOM',
  DIRTY_ROOM: 'DIRTY_ROOM',
  CANCEL_ASSIGN_ROOM: 'CANCEL_ASSIGN_ROOM',
  COPY: 'COPY',
  PRINT_GROUP: 'PRINT_GROUP',
  CANCEL_GROUP: 'CANCEL_GROUP',
  CLEAN_ROOM: 'CLEAN_ROOM',
  ASSIGN_ROOM: 'ASSIGN_ROOM',
  CHANGE_ROOM_TYPE: 'CHANGE_ROOM_TYPE',
  FIX_ROOM: 'FIX_ROOM',
  CHECK_OUT: 'CHECK_OUT',
  RESOLVE_DOCUMENT: 'RESOLVE_DOCUMENT',
  POST_ROOM_CHARGE: 'POST_ROOM_CHARGE',
  DUPLICATE_BOOKING_LINE: 'DUPLICATE_BOOKING_LINE',
  CREATE_SURVEY: 'CREATE_SURVEY'
};
