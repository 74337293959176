export enum PermissionMenuTypes {
  NIGHT_AUDIT = 'night_audit',
  CUSTOMER_LIST = 'customer_list',
  INVENTORY_MANAGEMENT = 'inventory_management',
  CASH_BOOK_DETAIL = 'cash_book_detail',
  BOOKING_ROOM = 'new_reservation',
  ROOM_MAP = 'room_map',
  BOOKING_LIST = 'booking_list',
  CLEANING_CALENDER = 'cleaning_calendar',
  ROOM_STATUS = 'room_status',
  GROUP_LIST = 'group_list',
  ROOM_AVAILABILITY = 'room_availability',
  RESERVATION_FORECAST = 'reservation_forecast',
  BREAKFAST = 'breakfast',
  HOUSE_STATUS = 'house_status',
  SHIFT_HANDOVER_LIST = 'shift_handover_list',
  TRANSACTION_LIST = 'transaction_list',
  WILL_CHECK_IN = 'will_check_in_view',
  ARRIVAL_GUEST = 'arrival_guest',
  IN_HOUSE_GUEST = 'in_house_guest',
  WILL_CHECK_OUT = 'will_check_out',
  CHECK_OUT_ROOM_LIST = 'check_out_room_list',
  UNAVAILABLE = 'unavailable',
  ACTUAL_CHECK_IN = 'actual_check_in',
  CA_DECLARATION = 'ca_declaration',
  DETAILED_ANALYSIS_REPORT = 'detailed_analysis_report',
  CONSOLIDATE_REVENUE_REPORT = 'consolidate_revenue_report',
  TA_MANAGEMENT_VIEW = 'ta_management_view'
}
