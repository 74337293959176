import { getEndOfToday, getStartOfToday, toLocalTime } from 'utils';
import CustomTable from './CustomTable';
import { getColumnRoomLock } from 'constants/table';
import { useGetRoomLock } from 'hooks/useGetRoomLock';

interface RoomLockedProps {
  isEnable: boolean;
  dateToQuery: any;
  roomTypeToQuery: string;
  onCountChange: (count: number) => void;
  t: (key: string) => string;
}

export default function RoomLockedTab({
  isEnable,
  dateToQuery,
  roomTypeToQuery,
  onCountChange,
  t
}: RoomLockedProps) {
  const { data: roomLocks, isLoading } = useGetRoomLock(
    {
      start_date: toLocalTime(getStartOfToday(dateToQuery), 'YYYY-MM-DD HH:mm:ss'),
      end_date: toLocalTime(getEndOfToday(dateToQuery), 'YYYY-MM-DD HH:mm:ss'),
      search: roomTypeToQuery
    },
    isEnable
  );

  onCountChange(roomLocks ? roomLocks.length : 0);

  return (
    <CustomTable
      rowKey="room_id"
      loading={isLoading}
      columns={getColumnRoomLock(t)}
      dataSource={roomLocks || []}
      t={t}
    />
  );
}
