import React, { useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import {
  Modal,
  notification,
  Form,
  FormProps,
  InputNumber
  // Checkbox,
} from 'antd';

import useBookingStore from 'stores/useBooking';
import useModal from 'stores/useModal';
import API from 'services/api';

import { BookingPrice, ParamsChangePrices } from 'services/api/type/booking.type';
import QUERY_KEYS from 'services/api/queryKeys';
import { refreshBookingList, refreshRoomMap } from 'hooks';
import { formatInputNumber } from 'utils/currency';

function ChangePriceGroupModal() {
  const [form] = Form.useForm();
  const location = useLocation();
  const queryClient = useQueryClient();

  const { checkedListBookingLineId } = useBookingStore();
  const { isOpenChangePriceGroup, setIsOpenChangePriceGroup } = useModal();

  const { mutateAsync: mutateChangePrice, isPending } = useMutation({
    mutationFn: ({
      bookingLineId,
      params
    }: {
      bookingLineId: number;
      params: ParamsChangePrices;
    }) => API.booking.changePrices(bookingLineId, params)
  });

  useEffect(() => {
    if (!isOpenChangePriceGroup) {
      form.resetFields();
    } else {
      form.setFieldsValue({
        price: ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenChangePriceGroup]);

  const handleCloseModal = () => {
    setIsOpenChangePriceGroup(false);
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      const params: any = {
        prices: (values.booking_prices as BookingPrice[]).map(item => ({
          id: item.id,
          price: item.price
        }))
      };
      if (values.pricelist_id) {
        params.pricelist_id = values.pricelist_id;
      }

      await mutateChangePrice({
        bookingLineId: Number(checkedListBookingLineId),
        params
      });

      notification.success({
        message: 'Đổi giá thành công'
      });

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_DETAIL, checkedListBookingLineId]
      });

      refreshBookingList(location);
      refreshRoomMap(location);

      setIsOpenChangePriceGroup(false);
    } catch (err: any) {
      notification.error({
        message: err.error || 'Có lỗi xảy ra'
      });
    }
  };

  return (
    <Modal
      title="Đổi giá nhiều phòng"
      centered
      width={400}
      open={isOpenChangePriceGroup}
      onCancel={handleCloseModal}
      okText="Lưu"
      okButtonProps={{ className: 'ant-btn-secondary' }}
      onOk={() => form.submit()}
      cancelText="Đóng"
      closeIcon={null}
      className={classNames('modal-change-price-group')}
      confirmLoading={isPending}
      destroyOnClose
    >
      <Form
        form={form}
        name="change-price-group-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{
          price: ''
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item label="Giá phòng" name="price" className="m-0">
          <InputNumber min={0} className="w-full" formatter={value => formatInputNumber(value)} />
        </Form.Item>
        <p className="note">Lưu ý: sửa giá chỉ áp dụng với giá cả ngày</p>
      </Form>
    </Modal>
  );
}

export default ChangePriceGroupModal;
