import React, { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { DatePicker, Form, Button, Table, Card } from 'antd';
import { generateExportFileName, toLocalTime } from 'utils';
import { FormProps } from 'rc-field-form';
import _ from 'lodash';
import 'styles/detailed-analysis-report.scss';
import { useGetDetailedAnalysisReport } from 'hooks/useGetDetailedAnalysisReport';
import { getColumnDetailedAnalysisReport } from 'constants/table';
import { PAGE_SIZE_DETAILED_ANALYSIS_REPORT } from 'services/api/module/report.api';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import ExportDetailedAnalysisReport from 'components/common/export-detailed-analysis-report';
import { useTranslation } from 'react-i18next';

const initStartDate = dayjs().startOf('day');

function DetailedAnalysisReport() {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_DETAILED_ANALYSIS_REPORT);

  const [filterOptions, setFilterOptions] = useState<any>({
    check_in: toLocalTime(String(initStartDate), 'YYYY-MM-DD'),
    check_out: toLocalTime(String(initStartDate.add(1, 'day')), 'YYYY-MM-DD')
  });

  const {
    data: detailedData,
    pagination,
    isFetching
  } = useGetDetailedAnalysisReport(filterOptions, page, pageSize);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_DETAILED_ANALYSIS_REPORT, page]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, ...restFilterOptions } = truthyValues;
    if (rangeDate) {
      const startDate: Dayjs = rangeDate[0];
      const endDate: Dayjs = rangeDate[1];

      if (startDate && endDate) {
        _.set(restFilterOptions, 'check_in', toLocalTime(String(startDate), 'YYYY-MM-DD'));
        _.set(restFilterOptions, 'check_out', toLocalTime(String(endDate), 'YYYY-MM-DD'));
      }
    }
    if (page !== 1) {
      setPage(1);
    }
    setFilterOptions(restFilterOptions);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const columnDetailedAnalysisReport = useMemo(() => {
    return getColumnDetailedAnalysisReport(t);
  }, [t]);

  return (
    <div className="pms-detailed-analysis-report">
      <Card title={t('managePage.detailedAnalysisReport.title')}>
        <div className="pms-detailed-analysis-report__timetable">
          <Form
            form={form}
            initialValues={{
              rangeDate: [initStartDate, initStartDate.add(1, 'day')]
            }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <div className="pms-detailed-analysis-report__filter">
              <div className="pms-detailed-analysis-report__filter__left">
                <Form.Item name="rangeDate">
                  <DatePicker.RangePicker
                    className="w-full"
                    placeholder={[t('common.time.fromDate'), t('common.time.toDate')]}
                    format="DD-MM-YYYY"
                  />
                </Form.Item>
                <Form.Item>
                  <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                    {t('common.actions.submitButton')}
                  </Button>
                </Form.Item>
              </div>
              <ExportDetailedAnalysisReport
                fileName={generateExportFileName('detailed-analysis-report')}
                filterOptions={filterOptions}
                t={t}
              />
            </div>
          </Form>
          <div className="pms-detailed-analysis-report__table">
            <Table
              loading={isFetching}
              rowKey="id"
              columns={columnDetailedAnalysisReport}
              dataSource={detailedData}
              bordered
              pagination={{
                current: page,
                position: ['bottomCenter'],
                total: pagination.total,
                pageSize: pageSize,
                locale: { items_per_page: '/ trang' },
                showTotal: total => `${total} items`,
                onChange: (page: number) => handleChangePage(page),
                onShowSizeChange: (current, size) => {
                  setPageSize(size);
                }
              }}
              locale={{
                emptyText: <span className="empty-data">Không có dữ liệu</span>
              }}
              scroll={{ x: 1800, y: 500 }}
            />
          </div>
        </div>
      </Card>
    </div>
  );
}

export default DetailedAnalysisReport;
