import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Link, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { Avatar, Button, Dropdown, MenuProps, Drawer, Menu } from 'antd';
import {
  UserOutlined,
  MenuOutlined,
  RetweetOutlined,
  LockOutlined,
  ScanOutlined,
  DownOutlined,
  LogoutOutlined
} from '@ant-design/icons';
import classNames from 'classnames';

import ChangePasswordModal from 'components/modal/change-password-modal';
import BranchesDropdown from 'components/common/branches-dropdown';
import { useGetMe } from 'hooks/useGetMe';
import useCommonStore from 'stores/useCommon';
import useModalStore from 'stores/useModal';
import { useGetCurrentBranch } from 'hooks/useGetCurrentBranch';

import PAGES, { getMenuHeaderMobile, MENU_HEADER_VALUE } from 'routes/constants';
import { SUB_MENU_HEADER } from 'routes/constants';
import { ReactComponent as Logo } from 'assets/images/logo-header.svg';
import { ReactComponent as ViLanguage } from 'assets/images/vi-language-icon.svg';
import { ReactComponent as EnLanguage } from 'assets/images/en-language-icon.svg';
import LogoForNotification from 'assets/images/logo-header.svg';
import { ReactComponent as Logout } from 'assets/images/logout.svg';
import { clearToken } from 'services/storages/userStorage';
import 'styles/common.scss';
import UnReadMessages from 'components/common/unReadMessages';
import { usePermissions } from 'AuthorizePermission';
import { getFilteredSubMenuHeaderAndMenuHeader } from 'helper/common/permission.menu.helper';

import { useTranslation } from 'react-i18next';
import IBBell from 'helper/permission.chat_box';
import { PermissionActionTypes } from 'helper/type/action.type';

const ACTION_MENU_USER = {
  INFO: 'INFO',
  LOGOUT: 'LOGOUT',
  SHIFTHANDOVER: 'SHIFTHANDOVER',
  CHANGE_PASSWORD: 'CHANGE_PASSWORD',
  SCAN_CUSTOMER_INFO: 'SCAN_CUSTOMER_INFO',
  CHANGE_LANGUAGE: 'CHANGE_LANGUAGE'
};

type MenuItem = Required<MenuProps>['items'][number];
function Header() {
  const { i18n, t } = useTranslation();
  const currentLang = i18n.language;

  const location = useLocation();
  const navigate = useNavigate();
  const { activeMenuId, setActiveMenuId, activeMenuManageId, setActiveMenuManageId } =
    useCommonStore();
  const { setIsOpenChangePassword } = useModalStore();

  const { data: myInfo } = useGetMe();
  const { data: currentNightAudit } = useGetCurrentBranch();
  const { permissions, hasPermission } = usePermissions();
  const unReadMessages = UnReadMessages();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [notiftTest, setNotiftTest] = useState<string>('');
  const [isOpenDropdownMenuManage, setIsOpenDropdownMenuManage] = useState(false);

  const showNotifyTestEnv = JSON.parse(process.env.REACT_APP_DISPLAY_NOTIFY_TEST || 'false');
  const handleShowDrawer = () => {
    setOpenDrawer(true);
  };
  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const filteredMenuHeader = useMemo(() => {
    return getFilteredSubMenuHeaderAndMenuHeader(permissions, hasPermission)
      .filteredMenuHeader.map(menu => {
        const translatedMenu = { ...menu, label: t(menu.label) };

        if (translatedMenu.children) {
          const filteredChildren = translatedMenu.children
            // .filter(child => {
            //   return currentNightAudit?.is_enable_v2
            //     ? child.onlyV2 || child.onlyV2 === undefined
            //     : !child.onlyV2;
            // })
            .map(child => ({
              ...child,
              label: t(child.label)
            }));

          return { ...translatedMenu, children: filteredChildren };
        }

        return translatedMenu;
      })
      .filter(menu => menu.id !== MENU_HEADER_VALUE.CASHIER || currentNightAudit?.is_enable_v2);
  }, [permissions, hasPermission, currentNightAudit?.is_enable_v2, t]);

  useEffect(() => {
    if (matchPath(PAGES.detailGroup, location.pathname)) {
      setActiveMenuId(MENU_HEADER_VALUE.GROUP);
      return;
    }

    for (const menu of filteredMenuHeader) {
      if (SUB_MENU_HEADER[menu.id]) {
        for (const subMenu of SUB_MENU_HEADER[menu.id]) {
          if (matchPath(subMenu.url, location.pathname)) {
            setActiveMenuId(menu.id);
            setActiveMenuManageId(0);
            break;
          }
        }
      } else if (menu.id === MENU_HEADER_VALUE.MANAGE) {
        for (const item of menu.children || []) {
          if (item.url && matchPath(item.url, location.pathname)) {
            setActiveMenuId(menu.id);
            setActiveMenuManageId(item.id);
            break;
          }
        }
      } else if (menu.url && matchPath(menu.url, location.pathname)) {
        setActiveMenuId(menu.id);
        setActiveMenuManageId(0);
        break;
      }
    }
  }, [location.pathname, setActiveMenuId, setActiveMenuManageId, filteredMenuHeader]);

  useEffect(() => {
    if (showNotifyTestEnv) {
      setNotiftTest('TEST');
    }
  }, [showNotifyTestEnv]);

  useEffect(() => {
    if (unReadMessages && location.pathname !== PAGES.chat) {
      let text: string;
      if (unReadMessages > 1) {
        text = 'New messages';
      } else {
        text = 'New message';
      }
      Notification.requestPermission().then(perm => {
        if (perm === 'granted') {
          const notification = new Notification(`+${unReadMessages} ${text}`, {
            icon: LogoForNotification
          });
          notification.onclick = () => {
            navigate(PAGES.chat);
          };
        }
      });
    }
    // eslint-disable-next-line
  }, [unReadMessages, location.pathname]);

  const isNightAuditOutdated = () => {
    const nightAuditDate = currentNightAudit?.night_audit_date;
    const today = dayjs().format('YYYY-MM-DD');
    if (nightAuditDate && nightAuditDate < today) {
      return true;
    }
    return false;
  };

  const handleClickMenu = (menu: any) => {
    switch (menu.key) {
      case ACTION_MENU_USER.INFO:
        break;
      case ACTION_MENU_USER.LOGOUT:
        clearToken();
        navigate(PAGES.login);
        break;
      case ACTION_MENU_USER.SHIFTHANDOVER:
        navigate(PAGES.shiftHandover);
        break;
      case ACTION_MENU_USER.CHANGE_PASSWORD:
        setIsOpenChangePassword(true);
        break;
      case ACTION_MENU_USER.SCAN_CUSTOMER_INFO:
        navigate(PAGES.scanCustomerInfo);
        break;
      default:
        break;
    }
  };

  const handleClickMenuMobile = (menu: any) => {
    handleCloseDrawer();

    switch (menu.key) {
      case ACTION_MENU_USER.INFO:
        break;
      case ACTION_MENU_USER.LOGOUT:
        clearToken();
        navigate(PAGES.login);
        break;
      case ACTION_MENU_USER.SHIFTHANDOVER:
        navigate(PAGES.shiftHandover);
        break;
      case ACTION_MENU_USER.CHANGE_PASSWORD:
        setIsOpenChangePassword(true);
        break;
      case ACTION_MENU_USER.SCAN_CUSTOMER_INFO:
        navigate(PAGES.scanCustomerInfo);
        break;
      default:
        navigate(menu.key);
        break;
    }
  };

  const handleChangeDropdown = (open: boolean) => {
    setIsOpenDropdownMenuManage(open);
  };

  const handleRedirectToChat = () => {
    navigate(PAGES.chat);
  };

  const menuMobile: MenuItem[] = useMemo(() => {
    const MENU_HEADER_MOBILE = getMenuHeaderMobile();
    return [
      ...MENU_HEADER_MOBILE.map(item => {
        const menuItem = {
          key: !SUB_MENU_HEADER[item.id] ? item.url : item.id,
          label: t(item.label),
          children: SUB_MENU_HEADER[item.id]
            ? SUB_MENU_HEADER[item.id].map(subItem => ({
                key: subItem.url,
                label: t(subItem.label)
              }))
            : null
        };
        return menuItem;
      }),
      {
        type: 'divider'
      },
      {
        key: ACTION_MENU_USER.CHANGE_PASSWORD,
        label: t('common.header.user.changePassword'),
        icon: <LockOutlined />
      },
      {
        key: ACTION_MENU_USER.SHIFTHANDOVER,
        label: t('common.header.user.shiftHandover'),
        icon: <RetweetOutlined />
      },
      {
        key: ACTION_MENU_USER.SCAN_CUSTOMER_INFO,
        label: t('common.header.user.scanCustomerInfo'),
        icon: <ScanOutlined />
      },
      {
        key: ACTION_MENU_USER.LOGOUT,
        label: t('common.header.user.logout'),
        icon: <LogoutOutlined />
      }
    ];
  }, [t]);
  const items: MenuProps['items'] = useMemo(() => {
    const enableLocales = process.env.REACT_APP_ENABLE_LOCALES === 'true';
    return [
      {
        key: ACTION_MENU_USER.INFO,
        label: (
          <div className="menu-item-user">
            <Avatar size="large" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
            <div className="menu-item-user__name">
              <p className="m-0 name">{myInfo?.email}</p>
              <p className="m-0 email">{myInfo?.email}</p>
              <p className="m-0 name">{myInfo?.permission_role_name?.join(', ')}</p>
            </div>
          </div>
        )
      },
      {
        type: 'divider'
      },
      ...(enableLocales
        ? [
            {
              key: ACTION_MENU_USER.CHANGE_LANGUAGE,
              label: (
                <div className="language-switch">
                  <Button
                    icon={<EnLanguage />}
                    onClick={() => i18n.changeLanguage('en')}
                    size="small"
                    style={{
                      marginRight: '8px',
                      alignItems: 'center',
                      display: 'inline-flex'
                    }}
                    className={currentLang === 'en' ? 'active-lang' : ''}
                  >
                    English
                  </Button>
                  <Button
                    icon={<ViLanguage />}
                    onClick={() => i18n.changeLanguage('vi')}
                    size="small"
                    style={{
                      alignItems: 'center',
                      display: 'inline-flex'
                    }}
                    className={currentLang === 'vi' ? 'active-lang' : ''}
                  >
                    Tiếng Việt
                  </Button>
                </div>
              ),
              disabled: false
            }
          ]
        : []),
      {
        key: ACTION_MENU_USER.CHANGE_PASSWORD,
        label: <span>Đổi mật khẩu</span>,
        icon: <LockOutlined />
      },
      {
        key: ACTION_MENU_USER.SHIFTHANDOVER,
        label: <span className="shiftHandover">Bàn giao ca</span>,
        icon: <RetweetOutlined />
      },
      {
        key: ACTION_MENU_USER.LOGOUT,
        label: <span className="required">Đăng xuất</span>,
        icon: <Logout />
      }
    ];
    // eslint-disable-next-line
  }, [myInfo, i18n.language]);

  return (
    <header className="pms-app-header">
      <div className="flex items-center" style={{ gap: '16px' }}>
        <Button
          className="btn-burger"
          type="link"
          icon={<MenuOutlined />}
          onClick={handleShowDrawer}
        ></Button>
        <Link to={PAGES.home} className="pms-logo">
          <Logo />
        </Link>
        <p style={{ color: 'red', fontWeight: 'bold' }}>{notiftTest}</p>
      </div>

      <div>
        <div
          className={`pms-night-audit-header__date ${isNightAuditOutdated() ? 'pms-night-audit-header__date__error' : 'pms-night-audit-header__date__true'}`}
        >
          {currentNightAudit?.night_audit_date}
        </div>
      </div>

      <div className="pms-menu-header">
        {filteredMenuHeader.map((item, key) => {
          if (_.isEmpty(item.children)) {
            return (
              <Link
                to={String(item.url)}
                className={classNames('pms-menu-header__item', {
                  active: activeMenuId === item.id
                })}
                key={key}
              >
                <span>{item.label}</span>
              </Link>
            );
          }
          return (
            <Dropdown
              open={isOpenDropdownMenuManage}
              onOpenChange={handleChangeDropdown}
              key={key}
              trigger={['click']}
              overlayClassName="menu-manage"
              className="action-manage"
              dropdownRender={() => {
                return (
                  <ul className="ant-dropdown-menu ant-dropdown-menu-root ant-dropdown-menu-vertical ant-dropdown-menu-light">
                    {!!item.children?.length &&
                      item.children.map((item: any) => (
                        <li
                          key={item.id}
                          className={classNames(
                            'ant-dropdown-menu-item ant-dropdown-menu-item-only-child',
                            {
                              active: activeMenuManageId === item.id
                            }
                          )}
                          onClick={() => setIsOpenDropdownMenuManage(false)}
                        >
                          <Link to={item.url} className="ant-dropdown-menu-title-content">
                            {item.label}
                          </Link>
                        </li>
                      ))}
                  </ul>
                );
              }}
            >
              <span
                className={classNames('pms-menu-header__item', {
                  active: activeMenuId === item.id
                })}
                onClick={() => setIsOpenDropdownMenuManage(true)}
              >
                {item.label} <DownOutlined style={{ marginLeft: 4 }} />
              </span>
            </Dropdown>
          );
        })}
      </div>
      <div className="pms-menu-action">
        <div className="pms-menu-action__unread-branches">
          <div className="pms-menu-action__unread-branches__unread">
            <IBBell
              permission={PermissionActionTypes.CHAT_BOX}
              unReadMessages={unReadMessages}
              handleRedirectToChat={handleRedirectToChat}
            />
            {/*<Badge count={unReadMessages} size="small" offset={[10, 5]}>*/}
            {/*  <BellOutlined*/}
            {/*    size={32}*/}
            {/*    onClick={handleRedirectToChat}*/}
            {/*    style={{ color: 'white', fontSize: '20px' }}*/}
            {/*  />*/}
            {/*</Badge>*/}
          </div>
          <div className="pms-menu-action__unread-branches__branches">
            <BranchesDropdown />
          </div>
        </div>
        <Dropdown
          menu={{ items, onClick: handleClickMenu }}
          trigger={['click']}
          overlayClassName="menu-user"
          className="action-user"
        >
          <div className="pms-menu-action__item pms-menu-action__item--user">
            <Avatar size="small" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
            <p>{myInfo?.email}</p>
          </div>
        </Dropdown>
      </div>
      <Drawer
        title={
          <div className="pms-menu-action__item pms-menu-action__item--user">
            <Avatar size="large" style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
            <div>
              <p>{myInfo?.email}</p>
              {myInfo?.permission_role_name && myInfo.permission_role_name.length > 0 && (
                <p>{myInfo.permission_role_name.join(', ')}</p>
              )}
            </div>
          </div>
        }
        className="drawer-menu-mobile"
        onClose={handleCloseDrawer}
        open={openDrawer}
        placement="left"
        width={280}
      >
        <Menu
          mode="inline"
          defaultOpenKeys={[String(activeMenuId)]}
          selectedKeys={[location.pathname]}
          onClick={handleClickMenuMobile}
          items={menuMobile}
          className="menu-mobile"
        />

        <div
          className={`pms-night-audit-header__date ${
            isNightAuditOutdated()
              ? 'pms-night-audit-header__date__error'
              : 'pms-night-audit-header__date__true'
          }`}
        >
          {t('common.header.nightAudit')}: {currentNightAudit?.night_audit_date}
        </div>
      </Drawer>

      <ChangePasswordModal />
    </header>
  );
}
export default Header;
