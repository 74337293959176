import { useEffect, useState } from 'react';
import { Modal, Tabs } from 'antd';
import { ReactComponent as IconClose } from 'assets/images/close.svg';
import UsedRoomsTab from './UsedRoomsTab';
import SubGroupsTab from './SubGroupsTab';
import RoomLockedTab from './RoomLockedTab';
import { useTranslation } from 'react-i18next';

function ConfirmCheckRoomAvailable({
  isOpen,
  dateToQuery,
  roomTypeToQuery,
  onCancel,
  onHandleClose
}: {
  isOpen: boolean;
  dateToQuery: any;
  roomTypeToQuery: string;
  onCancel: () => void;
  onHandleClose: () => void;
}) {
  const { t } = useTranslation();
  const [keyTab, setKeyTab] = useState<string>('');
  const [usedRoomsCount, setUsedRoomsCount] = useState<number>(0);
  const [roomLockedCount, setRoomLockedCount] = useState<number>(0);
  const [groupCount, setGroupCount] = useState<number>(0);

  useEffect(() => {
    if (isOpen) {
      setKeyTab('used-room');
    }
    // eslint-disable-next-line
  }, [isOpen]);

  const onTabClick = (key: string) => {
    setKeyTab(key);
  };

  return (
    <Modal
      title={`${t('managePage.roomAvailabilityPage.confirmCheckRoomAvailable.title')} ${dateToQuery ? dateToQuery.format('DD-MM-YYYY') : 'N/A'}`}
      centered
      width={1400}
      open={isOpen}
      onCancel={onCancel}
      cancelButtonProps={{
        style: {
          display: 'none'
        }
      }}
      onOk={onHandleClose}
      okText={t('common.actions.closeSession')}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      closeIcon={<IconClose />}
    >
      <Tabs activeKey={keyTab} onTabClick={onTabClick} type="card">
        <Tabs.TabPane
          tab={`${t('managePage.roomAvailabilityPage.confirmCheckRoomAvailable.titleUsedRoom')} (${usedRoomsCount})`}
          key="used-room"
        >
          <UsedRoomsTab
            isEnable={keyTab === 'used-room'}
            dateToQuery={dateToQuery}
            roomTypeToQuery={roomTypeToQuery}
            onCountChange={setUsedRoomsCount}
            t={t}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={`${t('managePage.roomAvailabilityPage.confirmCheckRoomAvailable.titleLockedRoom')} (${roomLockedCount})`}
          key="room-locked"
        >
          <RoomLockedTab
            isEnable={keyTab === 'room-locked'}
            dateToQuery={dateToQuery}
            roomTypeToQuery={roomTypeToQuery}
            onCountChange={setRoomLockedCount}
            t={t}
          />
        </Tabs.TabPane>
        <Tabs.TabPane
          tab={`${t('managePage.roomAvailabilityPage.confirmCheckRoomAvailable.titleGroupNotCreateYet')} (${groupCount})`}
          key="groups"
        >
          <SubGroupsTab
            isEnable={keyTab === 'groups'}
            dateToQuery={dateToQuery}
            roomTypeToQuery={roomTypeToQuery}
            onCountChange={setGroupCount}
            t={t}
          />
        </Tabs.TabPane>
      </Tabs>
    </Modal>
  );
}

export default ConfirmCheckRoomAvailable;
