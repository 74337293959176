import React, { useEffect, useMemo } from 'react';
import { Table } from 'antd';
import { getColumnBreakfastsDetails } from 'constants/table';
import 'styles/breakfasts-details.scss';

import { useGetBreakfastsDetails } from 'hooks/useGetBreakfasts';
import { toLocalTime } from 'utils';

interface Props {
  filterOptions: any;
  setDataSource: (data: any[]) => void;
  t: (key: string) => string;
}

function BreakfastsDetails({ filterOptions, setDataSource, t }: Props) {
  const { data: breakfastsDetailsData, isFetching } = useGetBreakfastsDetails(
    filterOptions.check_in,
    filterOptions.check_out
  );

  const dataSource = useMemo(() => {
    if (!breakfastsDetailsData) return [];
    const dataArray = breakfastsDetailsData.map((item: any) => ({
      ...item,
      date: toLocalTime(item.date, 'DD/MM/YYYY ddd')
    }));

    const totalRoomsCheckIn = dataArray.reduce((sum, item) => sum + (item.check_in?.room || 0), 0);
    const totalGuestsCheckIn = dataArray.reduce((sum, item) => sum + (item.check_in?.pax || 0), 0);
    const totalRoomsCheckOut = dataArray.reduce(
      (sum, item) => sum + (item.check_out?.room || 0),
      0
    );
    const totalGuestsCheckOut = dataArray.reduce(
      (sum, item) => sum + (item.check_out?.pax || 0),
      0
    );
    const totalBreakfastRooms = dataArray.reduce(
      (sum, item) => sum + (item.breakfast?.room || 0),
      0
    );
    const totalBreakfastAdults = dataArray.reduce(
      (sum, item) => sum + (item.breakfast?.adult || 0),
      0
    );
    const totalBreakfastChildren = dataArray.reduce(
      (sum, item) => sum + (item.breakfast?.child || 0),
      0
    );
    const totalBreakfastsPax = dataArray.reduce((sum, item) => sum + (item.breakfast?.pax || 0), 0);

    const averageRow = {
      date: t('managePage.reservationForecastPage.total'),
      check_in: { room: totalRoomsCheckIn, pax: totalGuestsCheckIn },
      check_out: { room: totalRoomsCheckOut, pax: totalGuestsCheckOut },
      breakfast: {
        room: totalBreakfastRooms,
        adult: totalBreakfastAdults,
        child: totalBreakfastChildren,
        pax: totalBreakfastsPax
      }
    };

    return [...dataArray, averageRow];
  }, [breakfastsDetailsData, t]);

  const columnBreakfastsDetails = useMemo(() => {
    return getColumnBreakfastsDetails(t);
  }, [t]);

  useEffect(() => {
    if (dataSource && setDataSource) {
      setDataSource(dataSource);
    }
  }, [dataSource, setDataSource]);

  return (
    <div className="pms-breakfasts-details">
      <div className="pms-breakfasts-details__table">
        <Table
          rowKey="date"
          loading={isFetching}
          size="small"
          columns={columnBreakfastsDetails}
          rowClassName={record =>
            record.date === t('managePage.reservationForecastPage.total') ? 'bold-row' : ''
          }
          dataSource={dataSource}
          pagination={false}
          scroll={{ x: 1000, y: 'calc(85vh - 320px)' }}
          bordered
          locale={{
            emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
          }}
        />
      </div>
    </div>
  );
}

export default BreakfastsDetails;
