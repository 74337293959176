import React, { useCallback, useEffect, useState } from 'react';
import { Card, Form, FormProps, DatePicker, Tabs, Input } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import ExportBreakfastList from 'components/common/export-breakfast/export-breakfast-list';
import { generateExportFileName, toLocalTime, toUtcTime } from 'utils';
import 'styles/breakfasts.scss';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import BreakfastsByDate from 'components/modal/breakfasts-components/by-date';
import BreakfastsDetails from 'components/modal/breakfasts-components/breakfasts-details';
import ExportBreakfastsDetails from 'components/common/export-breakfast/export-breakfasts-details';

const initStartDate = dayjs().startOf('day');
const INITIAL_RANGE_DATE = 14;

function Breakfasts() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [filterOptions, setFilterOptions] = useState<any>({});
  const [keyTab, setKeyTab] = useState<string>('by_date');
  const [dataSource, setDataSource] = useState<any[]>();

  const onTabClick = (key: string) => {
    setKeyTab(key);
  };

  const handleSetDataSource = useCallback((data: any[]) => {
    setDataSource(data);
  }, []);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, date, ...restFilterOptions } = truthyValues;
    if (keyTab == 'by_date') {
      if (date) {
        _.set(restFilterOptions, 'date', toUtcTime(date.add(1, 'day'), 'YYYY-MM-DD'));
      }
    } else {
      if (rangeDate) {
        const startDate: Dayjs = rangeDate[0];
        const endDate: Dayjs = rangeDate[1];

        if (startDate && endDate) {
          _.set(restFilterOptions, 'check_out', toUtcTime(endDate.add(1, 'day'), 'YYYY-MM-DD'));
          _.set(restFilterOptions, 'check_in', toUtcTime(startDate.add(1, 'day'), 'YYYY-MM-DD'));
        }
      }
    }

    setFilterOptions(restFilterOptions);
  };

  const handleRangeDateChange = (dates: any) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;
      const dayCount = end.diff(start, 'day') + 1; // Include both start and end dates
      form.setFieldsValue({ period: dayCount });
      form.submit();
    }
  };

  const handleDateChange = (date: Dayjs | null) => {
    if (date) {
      form.submit();
    }
  };

  const handlePeriodChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const period = parseInt(e.target.value);
    if (!isNaN(period)) {
      const startDate = dayjs(form.getFieldValue('rangeDate')[0]);
      const endDate = startDate.add(period - 1, 'day'); // Subtract 1 day for inclusive calculation
      form.setFieldsValue({ rangeDate: [startDate, endDate] });
      form.submit();
    }
  };

  useEffect(() => {
    if (keyTab == 'by_date') {
      form.setFieldsValue({
        date: initStartDate
      });
      form.submit();
    } else {
      form.setFieldsValue({
        rangeDate: [initStartDate, initStartDate.add(INITIAL_RANGE_DATE - 1, 'day')], // Subtract 1 day for inclusive calculation
        period: INITIAL_RANGE_DATE
      });
      form.submit();
    }
    // eslint-disable-next-line
  }, [keyTab]);

  const fromDate = toLocalTime(filterOptions.check_in, 'DD-MM-YYYY');

  const toDate = toLocalTime(filterOptions.check_out, 'DD-MM-YYYY');

  return (
    <div className="pms-breakfast-list">
      <Card title={t('managePage.breakfastPage.title')} style={{ width: '100%' }}>
        <Form
          form={form}
          name="breakfast-filter-form"
          layout="inline"
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-breakfast-list__filter-wrapper">
            <div className="pms-breakfast-list__filter">
              {keyTab == 'by_date' ? (
                <Form.Item name="date" className="m-0">
                  <DatePicker
                    format={'DD-MM-YYYY'}
                    placeholder={t('common.time.selectDate')}
                    onChange={handleDateChange}
                  />
                </Form.Item>
              ) : (
                <Form.Item name="rangeDate" className="m-0">
                  <DatePicker.RangePicker
                    className="w-full"
                    placeholder={[t('common.time.startDate'), t('common.time.endDate')]}
                    format="DD-MM-YYYY"
                    onChange={handleRangeDateChange}
                  />
                </Form.Item>
              )}
              {keyTab != 'by_date' && (
                <Form.Item name="period">
                  <Input placeholder={t('common.time.totalDays')} onChange={handlePeriodChange} />
                </Form.Item>
              )}
            </div>
            {keyTab == 'by_date' ? (
              <ExportBreakfastList
                fileName={generateExportFileName('breakfast-list')}
                dataToExport={dataSource}
                t={t}
              />
            ) : (
              <ExportBreakfastsDetails
                fileName={generateExportFileName('breakfasts-details')}
                dataToExport={dataSource}
                t={t}
                fromDate={fromDate}
                toDate={toDate}
              />
            )}
          </div>
        </Form>
        <div className="pms-breakfast-list__tabs">
          <Tabs type="card" activeKey={keyTab} onTabClick={onTabClick} defaultActiveKey="1">
            <Tabs.TabPane tab="By Date" key="by_date">
              <BreakfastsByDate
                filterOptions={filterOptions}
                setDataSource={handleSetDataSource}
                t={t}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="From date to date" key="from_date_to_date">
              <BreakfastsDetails
                filterOptions={filterOptions}
                setDataSource={handleSetDataSource}
                t={t}
              />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </Card>
    </div>
  );
}

export default Breakfasts;
