import { Payment } from './payment.type';
import { PersonalInfoType, RoomDetailStatus, RoomLock } from './room.type';
import { Labels } from './labels.type';

export enum BookingStatus {
  INITIAL = 'initial',
  CONFIRM = 'initial', // same as initial for now
  CHECK_IN = 'allot',
  CANCEL = 'cancel',
  CHECK_OUT = 'checkout',
  ALL = ''
}

export const FOLIO_BALANCE_CODE = [
  { label: 'A', value: 'A' },
  { label: 'B', value: 'B' },
  { label: 'C', value: 'C' },
  { label: 'D', value: 'D' },
  { label: 'E', value: 'E' },
  { label: 'F', value: 'F' },
  { label: 'G', value: 'G' },
  { label: 'H', value: 'H' },
  { label: 'I', value: 'I' }
];

export interface UpdateInvoiceDetailsType {
  partner_name: string;
  company_name: string;
  company_address: string;
  tax_code: string;
  email: string;
  publish_einvoice: boolean;
}

export interface Pricelist {
  id: number;
  name: string;
}
export interface Log {
  id: string;
  create_date: string;
  user_id: string;
  user_name: string;
  user_email: string;
  booking_line_id: string;
  type: string;
  log: string;
}
export interface BookingPrice {
  id: number;
  start_date: string;
  end_date: string;
  price: number;
  is_posted: boolean;
  final_price: number;
}

export interface BookingLineOrderIds {
  folio_balance_code: string;
  sale_order_id: number;
}

export interface BookingLineGuests {
  id: number;
  profile_id: number;
  guest_name: string;
  main_guest: boolean;
  is_child: boolean;
  note: string;
  email: string;
  address: string;
  booking_line_order_ids: BookingLineOrderIds[];
}

export interface RoomStatus {
  is_clean: boolean;
  is_occupied: boolean;
}

export interface Surveys {
  is_checkin: boolean;
  is_checkout: boolean;
}

export interface BookingLine {
  booking_line_id: number;
  booking_line_sequence_id: string;
  room_id: number;
  room_name: string;
  room_type_id: number;
  room_type_name: string;
  original_room_type_name: string;
  room_type_short_name: string;
  price: number;
  adult: number;
  child: number;
  booking_days: number;
  booking_id: number;
  subtotal_price: number;
  total_price: number;
  paid_amount: number;
  booking_sequence_id: string;
  partner_id: number;
  partner_name: string;
  gender: string;
  sale_order_name: string;
  check_in: string;
  check_in_date: string;
  check_out: string;
  check_out_date: string;
  has_group: boolean;
  has_party: boolean;
  note: string;
  status: BookingStatus;
  pricelist: Pricelist;
  booking_prices: BookingPrice[];
  customers: PersonalInfoType[];
  expandableRows?: BookingLine[];
  cancel_reason: string;
  cancel_price: number;
  services?: GetServiceType[];
  source_id?: number;
  source_name?: string;
  medium_id?: number;
  medium_name?: string;
  cms_booking_id?: string;
  cms_ota_id?: string;
  cms_booking_source?: string;
  is_no_show?: boolean;
  tax: number;
  breakfast_dates: BreakfastType[];
  payments: Payment[];
  attachments: AttachmentType[];
  partner_identification: string | boolean;
  actual_check_in?: string;
  actual_check_out?: string;
  invoice_details: UpdateInvoiceDetailsType;
  labels: Labels[];
  group?: {
    name: string;
    booking_lines: GroupBookingLineItem[];
  };
  group_master?: {
    id: number;
    name: string;
  };
  hotel_travel_agency_name?: string;
  hotel_travel_agency_id?: number;
  create_date: string;
  booking_line_guests: BookingLineGuests[];
  is_foc: boolean;
  remain_amount: number;
  booking_line_guest_ids: number[];
  room_status: RoomStatus;
  surveys: Surveys;
  reason_approve_by: string;
  foc_level: string;
}

export interface GroupBookingLineItem {
  id: number;
  status: string;
  room_id: number;
  room_name: string;
  room_type_id: number;
  room_type_name: string;
}

export interface CreateBookingResponseType {
  booking_id: number;
  booking_lines: { booking_line_id: number }[];
}

export interface GetServiceType {
  id: number;
  name: string;
  price: number;
  total_price: number;
  subtotal_price: number;
  is_deleted: boolean;
  note: string;
  username: string;
  created_date: string;
  qty: number;
  tax: number;
}

export interface PaginationType {
  total: number;
  limit: number;
  page: number;
}

export interface ParamsCancelBooking {
  payment_method: string;
  fee: number;
  note: string;
  is_no_show?: boolean;
  is_send_email?: boolean;
}

export interface ParamsCancelTransaction {
  note: string;
}

export interface BookingServiceType {
  id: number;
  name: string;
  price: number;
  qty?: number;
  category: string;
  sub_category: string;
  allow_foc: boolean;
  allow_custom_price: boolean;
  folio_balance_code?: string;
  date?: string;
  reference?: string;
  transaction_code_id?: number;
  tax_id?: number;
}

export interface BreakfastType {
  id: number;
  active: boolean;
  date: string;
}

export interface ParamsAddServices {
  service_id: number;
  note: string;
  price: number;
}

export interface ParamsChangePrices {
  prices: {
    id: number;
    price: number;
  }[];
  pricelist_id: number;
}

export interface ParamsExtraInfo {
  medium_id: number;
  source_id: number;
  note: string;
  label_name?: string;
  cms_ota_id?: string;
  hotel_travel_agency_id?: number;
}

export interface AttachmentType {
  id: number;
  name: string;
  url: string;
  created_date: string;
}

export type RoomDetailStatusPicked = Pick<
  RoomDetailStatus,
  'room_id' | 'room_name' | 'is_clean' | 'room_type_name'
>;

export type NewRoomLock = RoomLock & {
  room_id: number;
  lock_id: number;
  attributes: {
    room_no: string;
  };
};
export interface ResponseTimeline {
  bookings: BookingLine[];
  locks: NewRoomLock[];
  rooms: RoomDetailStatusPicked[];
}

export enum BATCH_ACTION_TYPE {
  CHECK_IN = 'check_in',
  CHECK_OUT = 'check_out',
  AUTO_ASSIGN = 'auto_assign',
  CANCEL = 'cancel',
  CHANGE_DATE = 'change_date',
  UNASSIGN = 'unassign'
}
export interface ParamsBatchAction {
  action: BATCH_ACTION_TYPE;
  booking_line_ids: number[];
  reason?: string;
  check_in?: string;
  check_out?: string;
}

export interface BookingsBatchActions {
  action: BATCH_ACTION_TYPE;
  booking_ids: number[];
}
/**
 * {
 *     "data": {
 *         "id": 2,
 *         "balance": 2100000.0,
 *         "transactions": [
 *             {
 *                 "id": 6,
 *                 "name": "HKT",
 *                 "code": "A",
 *                 "amount": 400000.0,
 *                 "transaction_date": "2024-10-17",
 *                 "note": false,
 *                 "transaction_code_id": 2,
 *                 "transaction_code_code": "110"
 *             },
 *             {
 *                 "id": 7,
 *                 "name": "HKT",
 *                 "code": "A",
 *                 "amount": 400000.0,
 *                 "transaction_date": "2024-10-18",
 *                 "note": false,
 *                 "transaction_code_id": 2,
 *                 "transaction_code_code": "110"
 *             },
 *             {
 *                 "id": 8,
 *                 "name": "HKT",
 *                 "code": "A",
 *                 "amount": 500000.0,
 *                 "transaction_date": "2024-10-18",
 *                 "note": false,
 *                 "transaction_code_id": 2,
 *                 "transaction_code_code": "110"
 *             },
 *             {
 *                 "id": 9,
 *                 "name": "HKT",
 *                 "code": "A",
 *                 "amount": 400000.0,
 *                 "transaction_date": "2024-10-19",
 *                 "note": false,
 *                 "transaction_code_id": 2,
 *                 "transaction_code_code": "110"
 *             },
 *             {
 *                 "id": 10,
 *                 "name": "HKT",
 *                 "code": "A",
 *                 "amount": 400000.0,
 *                 "transaction_date": "2024-10-20",
 *                 "note": false,
 *                 "transaction_code_id": 2,
 *                 "transaction_code_code": "110"
 *             }
 *         ]
 *     }
 * }
 */

export interface Transaction {
  id: number;
  name: string;
  code: string;
  amount: number;
  transaction_date: string;
  note: boolean;
  transaction_code_id: number;
  transaction_code_name: string;
  is_deleted: boolean;
  reference: string;
  tax: number;
  tax_excluded: number;
  service_charge: number;
  tax_amount: number;
  qty: number;
  total: number;
  discount_total?: number;
  transaction_group: string;
}

export interface TransactionData {
  id: number;
  balance: number;
  sale_order_name: string;
  guest_name: string;
  transactions: Transaction[];
}

export interface ParamsGetTransactionsType {
  guest_info?: string;
  group_info?: string;
  travel_agency_info?: string;
  check_in_from?: string;
  check_in_to?: string;
  check_out_from?: string;
  check_out_to?: string;
  non_zero_balance?: boolean;
  main_guest_only: boolean;
  status_bar?: string;
}

export interface ValueSelectedItemsOfTransactionSearchBooking {
  balance: number;
  booking_line_id: number;
  check_in: string;
  check_out: string;
  guest_name: string;
  id: number;
  party_id: number;
  room_no: string;
  status: string;
}

export interface TransactionCodesResponse {
  id: number;
  code: string;
  sub_code: string;
  name: string;
  transaction_group: string;
}

export interface TransactionPayment {
  transaction_date: string;
  transaction_code_name: string;
  amount: number;
}

export interface AddonServiceTaxes {
  id: number;
  name: string;
}

export interface PendingPostRoomCharge {
  booking_line_id: number;
  booknig_id: number;
  partner_name: string;
  room_name: string;
  pricelist: Pricelist;
  status: string;
  source_name: string;
  check_in: string;
  check_out: string;
  total_price: number;
}

export enum TransactionCodesGroupType {
  PAYMENT = 'payment',
  REFUND = 'refund'
}

export interface ParamsAddDiscount {
  discount_percent: number;
  discount_amount: number;
  discount_reason: string;
  discount_code: string;
  is_foc: boolean;
}

export interface ParamsAddDiscountImage {
  discount_image?: string;
}

export interface BookingDiscount {
  discount_amount: number;
  discount_percent: number;
  discount_code: string;
  discount_reason?: string;
  discount_image_url?: string;
  is_foc: boolean;
  discount_total: number;
}

export interface CreateDummyBooking {
  guest_id: number;
}

export interface BookingConfirmationTemplate {
  body_html: string;
  email_cc: string;
  email_from: string;
  email_to: string;
  model: string;
  partner_ids: number[];
  reply_to: string;
  res_id: number;
  subject: string;
}
