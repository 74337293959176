import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import classNames from 'classnames';
import {
  AutoComplete,
  Button,
  DatePicker,
  Form,
  FormProps,
  Input,
  Modal,
  Radio,
  Select,
  Tooltip,
  notification
} from 'antd';
import { IdcardOutlined, PhoneFilled, ReloadOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import imageCompression from 'browser-image-compression';

import CustomerPhotos from '../form/customer-info/customer-photos';
import { useGetCountries } from 'hooks/useGetCountries';
import { useGetCustomers } from 'hooks/useGetCustomers';
import { useGetAreas } from 'hooks/useGetAreas';
import { useGetCustomerById } from 'hooks/useGetCustomerById';

import { addOrUpdateCustomers } from 'services/api/module/customer.api';
import { MESSAGE_CODE } from 'constants/validate';
import { EmailRegex } from 'constants/regex';
import { SEX } from 'constants/form';
import { PersonalInfoType } from 'services/api/type/room.type';
import { disabledDateFuture, getBase64, trimTruthyValues } from 'utils';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';

import { ReactComponent as IconMale } from 'assets/images/male.svg';
import { ReactComponent as IconFemale } from 'assets/images/female.svg';
import { ReactComponent as IconRegisterCard } from 'assets/images/register-card.svg';
import { ReactComponent as IconEmail } from 'assets/images/email.svg';
import { useTranslation } from 'react-i18next';

interface Props {
  isOpen: boolean;
  isShowUpload?: boolean;
  isBasicInfo?: boolean;
  customer: PersonalInfoType;
  onOk: (data: any) => void;
  onCancel: () => void;
  onReload?: () => void;
}

function EditCustomerModal({
  isOpen,
  customer: customerProps,
  isShowUpload = true,
  isBasicInfo = false,
  onOk,
  onCancel,
  onReload
}: Props) {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const partner_id = Form.useWatch(['customer', 'partner_id'], form);
  const country_id = Form.useWatch(['customer', 'country_id'], form);

  const [searchName, setSearchName] = useState('');

  const { data: countries } = useGetCountries();
  const { data: states } = useGetAreas(country_id);
  const { data: customers } = useGetCustomers(
    { search: searchName },
    'person',
    !!searchName && searchName.length > 3
  );
  const { data: customerInfo, isFetching } = useGetCustomerById(
    Number(customerProps?.id) || Number(customerProps?.profile_id)
  );

  const { mutateAsync: createOrUpdateCustomer, isPending } = useMutation({
    mutationFn: async (values: any) => {
      const images = values.attachments
        ? await Promise.all(
            values.attachments
              .filter((photo: any) => _.isNil(photo.id))
              .map(async (photo: any) => {
                const compressedFile = await imageCompression(photo.originFileObj, {
                  maxSizeMB: 2,
                  maxWidthOrHeight: 1280
                });
                return getBase64(compressedFile);
              })
          )
        : undefined;

      const data = {
        partner_id: values.partner_id,
        dob: values.dob?.format('YYYY-MM-DD'),
        name: values.name,
        gender: values.gender,
        phone: values.phone,
        email: values.email,
        vat: values.vat,
        identification: values.identification,
        street: values.street,
        state_id: values.state_id,
        country_id: values.country_id,
        comment: values.comment,
        images,
        remove_images: values.remove_images
      };
      const customers = await addOrUpdateCustomers([data]);
      return customers;
    }
  });

  useEffect(() => {
    if (isOpen && !_.isEmpty(customerInfo)) {
      form.setFieldValue(
        'customer',
        trimTruthyValues({
          profile_id: customerInfo.id || customerInfo?.profile_id,
          name: customerInfo.name,
          gender: customerInfo.gender,
          phone: customerInfo.phone,
          email: customerInfo.email,
          dob: customerInfo.dob ? dayjs(customerInfo.dob) : null,
          identification: customerInfo.identification,
          country_id: customerInfo.country?.id,
          state_id: customerInfo.state?.id,
          street: customerInfo.street,
          comment: customerInfo.comment,
          attachments: customerInfo.attachments,
          remove_images: []
        })
      );
    } else {
      form.resetFields(['customer']);
    }
  }, [customerInfo, form, isOpen]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleSearchName = useCallback(
    _.debounce((value: string) => {
      setSearchName(value);
    }, 500),
    []
  );

  const handleOk = () => {
    form.submit();
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    try {
      const result = await createOrUpdateCustomer(values.customer);
      onOk(result[0]);
    } catch (err: any) {
      notification.error({
        message: err.error || t('common.error')
      });
    }
  };

  const handleSelectCustomerAutoComplete = (customerId: number) => {
    const customer = _.find(customers, { id: customerId });
    // const customerForm = form.getFieldValue(["customers", selectedCustomerId]);
    if (customer) {
      const data: {
        [key: string]: any;
      } = {
        partner_id: customer.id,
        name: customer.name,
        gender: customer.gender,
        phone: customer.phone,
        email: customer.email,
        dob: customer.dob ? dayjs(customer.dob) : null,
        identification: customer.identification,
        country_id: customer.country?.id,
        state_id: customer.state?.id,
        street: customer.street,
        comment: customer.comment,
        attachments: customer.attachments
      };
      const truthyValues: any = Object.keys(data)
        .filter(key => Boolean(data[key]))
        .reduce((cur, next) => {
          return {
            ...cur,
            [next]: data[next]
          };
        }, {});

      form.setFieldValue('customer', truthyValues);
    }
  };

  const handleReload = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_CUSTOMER_BY_ID, partner_id]
    });
    onReload && onReload();
  };

  const renderCustomerOptions = useMemo(() => {
    return customers?.map(item => ({
      label: (
        <div className="customer-autocomplete-item">
          <div className="flex items-center justify-between">
            <div className="flex items-center" style={{ gap: '8px' }}>
              <IconMale />
              <p className="m-0 truncate" style={{ maxWidth: '200px' }}>
                {item.name}
              </p>
            </div>
            <div className="flex items-center" style={{ gap: '8px' }}>
              <IdcardOutlined />
              <p className="m-0 truncate" style={{ maxWidth: '200px' }}>
                {item.identification || '-'}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between">
            <div className="flex items-center" style={{ gap: '8px' }}>
              <IconEmail />
              <p className="m-0 truncate" style={{ maxWidth: '200px' }}>
                {item.email || '-'}
              </p>
            </div>
            <div className="flex items-center" style={{ gap: '8px' }}>
              <PhoneFilled />
              <p className="m-0 truncate" style={{ maxWidth: '200px' }}>
                {item.phone || '-'}
              </p>
            </div>
          </div>
        </div>
      ),
      value: item.id
    }));
  }, [customers]);

  return (
    <Modal
      title={t('managePage.caDeclarationPage.editCustomerInfo.title')}
      className={classNames('modal-edit-customer', {
        'modal-edit-customer--basic': isBasicInfo
      })}
      centered
      width={isShowUpload ? 1000 : 500}
      open={isOpen}
      okText={t('managePage.caDeclarationPage.editCustomerInfo.submitButton')}
      cancelText={t('common.actions.cancel')}
      okButtonProps={{ className: 'ant-btn-secondary' }}
      onOk={handleOk}
      onCancel={onCancel}
      confirmLoading={isPending}
      destroyOnClose
    >
      <Form
        form={form}
        name="edit-customer-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{
          customer: {}
        }}
        onFinish={onFinish}
        autoComplete="off"
      >
        <div className="edit-customer-form__content">
          <div className="modal-customer-body__form">
            <h5 className="title flex items-center gap-2">
              {t('common.customer.customer')}
              <Tooltip title={t('common.actions.refresh')} arrow={false}>
                <Button
                  onClick={handleReload}
                  type="text"
                  shape="circle"
                  icon={<ReloadOutlined />}
                  style={{ marginLeft: '4px' }}
                  loading={isFetching}
                ></Button>
              </Tooltip>
            </h5>
            <div className="modal-customer-body__form-row">
              <Form.Item name={['customer', 'partner_id']} hidden>
                <input />
              </Form.Item>
              <Form.Item
                label={
                  <span className="flex gap-2">
                    {t('common.customer.name')}
                    {partner_id && <IconRegisterCard className="icon-sm" />}
                  </span>
                }
                name={['customer', 'name']}
                style={{ flexGrow: 5 }}
                rules={[
                  {
                    required: true,
                    message: MESSAGE_CODE.REQUIRED_FULL_NAME
                  }
                ]}
              >
                <AutoComplete
                  placeholder={t('managePage.caDeclarationPage.editCustomerInfo.findingOrCreating')}
                  popupClassName="autocomplete-customer"
                  onSearch={handleSearchName}
                  onSelect={handleSelectCustomerAutoComplete}
                  options={renderCustomerOptions}
                  popupMatchSelectWidth={false}
                />
              </Form.Item>
              <Form.Item label={t('common.customer.gender')} name={['customer', 'gender']}>
                <Radio.Group buttonStyle="solid">
                  <Radio.Button value={SEX.MALE}>
                    <IconMale />
                    {/* Nam */}
                  </Radio.Button>
                  <Radio.Button value={SEX.FEMALE}>
                    <IconFemale />
                    {/* Nữ */}
                  </Radio.Button>
                </Radio.Group>
              </Form.Item>
            </div>

            <div className="modal-customer-body__form-row">
              <Form.Item label={t('common.customer.phoneNo')} name={['customer', 'phone']}>
                <Input />
              </Form.Item>
              <Form.Item
                label={t('common.customer.email')}
                name={['customer', 'email']}
                style={{ flexGrow: 5 }}
                rules={[
                  {
                    pattern: EmailRegex,
                    message: MESSAGE_CODE.WRONG_FORMAT_EMAIL
                  }
                ]}
              >
                <Input />
              </Form.Item>
            </div>

            <div className="modal-customer-body__form-row">
              <Form.Item label={t('common.customer.dob')} name={['customer', 'dob']}>
                <DatePicker format="DD/MM/YYYY" disabledDate={disabledDateFuture} />
              </Form.Item>
              <Form.Item
                label={t('common.customer.identificationCard')}
                name={['customer', 'identification']}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label={t('common.customer.nationality')}
                name={['customer', 'country_id']}
                style={{ flexGrow: 2 }}
              >
                <Select
                  showSearch
                  filterOption={(input: string, option: any) => {
                    return option.name.toLowerCase().includes(input.toLowerCase().trim());
                  }}
                  options={countries || []}
                  fieldNames={{ label: 'name', value: 'id' }}
                />
              </Form.Item>
            </div>

            <div className="modal-customer-body__form-row">
              <Form.Item label={t('common.customer.state')} name={['customer', 'state_id']}>
                <Select
                  showSearch
                  filterOption={(input: string, option: any) => {
                    return option.name.toLowerCase().includes(input.toLowerCase().trim());
                  }}
                  options={states || []}
                  fieldNames={{ label: 'name', value: 'id' }}
                />
              </Form.Item>
              <Form.Item
                label={t('common.customer.street')}
                name={['customer', 'street']}
                style={{ flexGrow: 5 }}
              >
                <Input />
              </Form.Item>
            </div>

            <Form.Item label={t('common.note')} name={['customer', 'comment']} className="w-full">
              <Input.TextArea rows={4} placeholder={t('common.note')} />
            </Form.Item>
          </div>
          {isShowUpload && (
            <div className="modal-customer-body__upload">
              <CustomerPhotos form={form} isEdit key="customer" selectedCustomerId="customer" />
            </div>
          )}
        </div>
      </Form>
    </Modal>
  );
}

export default EditCustomerModal;
