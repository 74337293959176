import { notification } from 'antd';
import { BATCH_ACTION_TYPE } from 'services/api/type/booking.type';

const createBatchActionHandler = (
  generateConfirm: (
    actionType: BATCH_ACTION_TYPE,
    confirmTitle: string,
    successMessage: string
  ) => void
) => {
  return async (actionType: BATCH_ACTION_TYPE, confirmTitle: string, successMessage: string) => {
    generateConfirm(actionType, confirmTitle, successMessage);
  };
};

export const handleBookingsBatchActions = async (
  currentAction: BATCH_ACTION_TYPE,
  generateConfirm: (
    actionType: BATCH_ACTION_TYPE,
    confirmTitle: string,
    successMessage: string
  ) => void,
  t: (key: string) => string
) => {
  const handler = createBatchActionHandler(generateConfirm);

  switch (currentAction) {
    case BATCH_ACTION_TYPE.AUTO_ASSIGN:
      await handler(
        BATCH_ACTION_TYPE.AUTO_ASSIGN,
        t('common.confirm.assignRoom'),
        t('common.message.assignRoomSuccessfully')
      );
      break;

    case BATCH_ACTION_TYPE.CHECK_OUT:
      await handler(
        BATCH_ACTION_TYPE.CHECK_OUT,
        t('common.confirm.checkout'),
        t('common.message.checkout')
      );
      break;

    case BATCH_ACTION_TYPE.CHECK_IN:
      await handler(
        BATCH_ACTION_TYPE.CHECK_IN,
        t('common.confirm.checkin'),
        t('common.message.checkIn')
      );
      break;

    case BATCH_ACTION_TYPE.UNASSIGN:
      await handler(
        BATCH_ACTION_TYPE.UNASSIGN,
        t('common.confirm.unAssignRoom'),
        t('common.message.unAssignRoomSuccessfully')
      );
      break;

    default:
      notification.error({ message: t('common.message.actionNotImplemented') });
  }
};
