import { useQuery } from '@tanstack/react-query';
import QUERY_KEYS from 'services/api/queryKeys';
import { getBreakfasts, getBreakfastsDetails } from 'services/api/module/breakfasts.api';
import { useMemo } from 'react';

export const useGetBreakfasts = (date?: string) => {
  const { data, isFetching, isFetched } = useQuery({
    queryKey: [QUERY_KEYS.GET_BREAKFASTS, date],
    queryFn: () => {
      if (!date) {
        return;
      }
      return getBreakfasts(date);
    }
  });

  return {
    data,
    isFetching,
    isFetched
  };
};

export const useGetBreakfastsDetails = (check_in: string, check_out: string) => {
  const { data, isFetching, isFetched } = useQuery({
    queryKey: [QUERY_KEYS.GET_BREAKFASTS_DETAILS, check_in, check_out],
    queryFn: () => {
      return getBreakfastsDetails(check_in, check_out);
    },
    enabled: !!check_in && !!check_out
  });

  const convertedData = useMemo(() => {
    return Object.keys(data || {}).map(key => ({
      ...data[key],
      date: key
    }));
  }, [data]);

  return {
    data: convertedData || [],
    isFetching,
    isFetched
  };
};
