import { getEndOfToday, getStartOfToday, toLocalTime } from 'utils';
import CustomTable from './CustomTable';
import { getColumnSubGroupsTab } from 'constants/table';
import { useGetGroupsBookingDetails } from 'hooks/useGetGroupBookings';
import { useMemo } from 'react';

interface SubGroupsProps {
  isEnable: boolean;
  dateToQuery: any;
  roomTypeToQuery: string;
  onCountChange: (count: number) => void;
  t: (key: string) => string;
}

export default function SubGroupsTab({
  isEnable,
  dateToQuery,
  roomTypeToQuery,
  onCountChange,
  t
}: SubGroupsProps) {
  const { data: listGroups, isLoading } = useGetGroupsBookingDetails(
    {
      check_in: toLocalTime(getStartOfToday(dateToQuery), 'YYYY-MM-DD'),
      check_out: toLocalTime(getEndOfToday(dateToQuery), 'YYYY-MM-DD'),
      short_code: roomTypeToQuery
    },
    isEnable
  );

  onCountChange(listGroups.length);

  const columnSubGroupsTab = useMemo(() => {
    return getColumnSubGroupsTab(t);
  }, [t]);

  return (
    <CustomTable
      rowKey="ref_code"
      loading={isLoading}
      columns={columnSubGroupsTab}
      dataSource={listGroups || []}
      t={t}
    />
  );
}
