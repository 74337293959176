import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import { Button, Checkbox, Form, FormProps, Input, Modal } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { CloudUploadOutlined } from '@ant-design/icons';

import ConfirmationPDF from '../confirmation-pdf';
import useModal from 'stores/useModal';
import useBookingStore from 'stores/useBooking';

import { uploadConfirmation } from 'services/api/module/booking.api';
import { getBase64 } from 'utils';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';

export enum CHARGE_TYPE {
  ALL = 'all',
  ROOM = 'room',
  SERVICE = 'service'
}

function ConfirmationPDFModal() {
  const [form] = Form.useForm();
  const { isOpenConfirmationPDF, setIsOpenConfirmationPDF, setIsOpenSignature } = useModal();
  const { bookingLineDetail } = useBookingStore();
  const [dataChange, setDataChange] = useState<any>({
    isShowRoomRate: false,
    isShowVAT: true,
    company: '',
    paymentMethod: '',
    email: _.get(bookingLineDetail, 'customers[0].email') || '',
    phone: _.get(bookingLineDetail, 'customers[0].phone') || ''
  });
  const [signatureStaff, setSignatureStaff] = useState('');
  const [signatureGuest, setSignatureGuest] = useState('');

  const { mutateAsync: mutateUploadConfirmation, isPending } = useMutation({
    mutationFn: async ({ bookingLineId, file, fileName }: any) => {
      const customers = await uploadConfirmation(bookingLineId, {
        file,
        name: fileName
      });
      return customers;
    }
  });

  useEffect(() => {
    if (isOpenConfirmationPDF) {
      form.resetFields();
      const initData = {
        isShowRoomRate: false,
        isShowVAT: true,
        company: '',
        paymentMethod: '',
        email: _.get(bookingLineDetail, 'customers[0].email') || '',
        phone: _.get(bookingLineDetail, 'customers[0].phone') || ''
      };
      form.setFieldsValue(initData);
      setDataChange(initData);
      setSignatureStaff('');
      setSignatureGuest('');
    }
  }, [form, bookingLineDetail, isOpenConfirmationPDF]);

  const handleCancel = () => {
    setIsOpenConfirmationPDF(false);
  };

  const handleOpenSignatureStaff = () => {
    setIsOpenSignature(true, {
      title: 'Chữ ký nhân viên',
      onOk: (data: any) => {
        setSignatureStaff(data);
      }
    });
  };

  const handleOpenSignatureGuest = () => {
    setIsOpenSignature(true, {
      title: 'Chữ ký của khách',
      onOk: (data: any) => {
        setSignatureGuest(data);
      }
    });
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    setDataChange(values);
  };

  const handleUpload = async (blob: Blob | null) => {
    if (blob) {
      const fileName = `confirmation_${
        bookingLineDetail?.booking_line_id
      }-${dayjs().format('DD-MM-YYYY-HH-mm-ss')}`;

      const file = new File([blob], fileName, {
        type: 'application/pdf;charset=UTF-8'
      });
      const fileBase64 = await getBase64(file);
      try {
        await mutateUploadConfirmation({
          bookingLineId: bookingLineDetail?.booking_line_id,
          file: fileBase64,
          fileName
        });

        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.KEY_ROOM,
            QUERY_KEYS.GET_BOOKING_DETAIL,
            bookingLineDetail?.booking_line_id
          ]
        });
      } catch (error: any) {
        console.log('ERROR', error);
      }
    }
  };

  return (
    <Modal
      title="Xem trước khi in"
      className="modal-view-confirmation-pdf"
      centered
      width={1200}
      open={isOpenConfirmationPDF}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
    >
      <PDFViewer className="confirmation-pdf-file">
        <ConfirmationPDF
          dataChange={dataChange}
          bookingLineDetail={bookingLineDetail}
          signatureStaff={signatureStaff}
          signatureGuest={signatureGuest}
        />
      </PDFViewer>

      <Form
        form={form}
        name="confirmation-pdf-form"
        layout="horizontal"
        style={{ width: '250px' }}
        initialValues={dataChange}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Form.Item name="isShowRoomRate" label="Show room rate" valuePropName="checked">
          <Checkbox />
        </Form.Item>
        <Form.Item name="isShowVAT" label="Show VAT" valuePropName="checked">
          <Checkbox />
        </Form.Item>

        <Form.Item name="company" label="Company" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Input />
        </Form.Item>
        <Form.Item
          name="paymentMethod"
          label="Payment method"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
        <Form.Item name="email" label="Email" labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Input />
        </Form.Item>
        <Form.Item
          name="phone"
          label="Phone"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ marginBottom: 24 }}
        >
          <Input />
        </Form.Item>

        <Button htmlType="submit" className="ant-btn-secondary w-full">
          Lưu thay đổi
        </Button>

        <div className="flex" style={{ flexDirection: 'column', gap: '8px', marginTop: 16 }}>
          <Button onClick={handleOpenSignatureStaff}>Chữ ký nhân viên</Button>
          <Button onClick={handleOpenSignatureGuest}>Chữ ký của khách</Button>
        </div>

        <div style={{ marginTop: 16 }}>
          <PDFDownloadLink
            document={
              <ConfirmationPDF
                dataChange={dataChange}
                bookingLineDetail={bookingLineDetail}
                signatureStaff={signatureStaff}
                signatureGuest={signatureGuest}
              />
            }
          >
            {({ blob, loading }) =>
              loading ? (
                ''
              ) : (
                <Button
                  className="ant-btn-secondary w-full"
                  onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();
                    handleUpload(blob);
                  }}
                  loading={isPending}
                  icon={<CloudUploadOutlined />}
                >
                  Tải lên
                </Button>
              )
            }
          </PDFDownloadLink>
        </div>
      </Form>
    </Modal>
  );
}

export default ConfirmationPDFModal;
