import { axiosInstance } from '../base.api';
import { paths } from '../paths';
import { BreakfastsType } from 'services/api/type/breakfasts.type';

export const getBreakfasts = (date: string): Promise<BreakfastsType[]> => {
  return axiosInstance.get(paths.getBreakfasts(date)).then(item => item.data.data);
};

export const getBreakfastsDetails = (check_in: string, check_out: string): Promise<any> => {
  return axiosInstance
    .get(paths.getBreakfastsDetails(check_in, check_out))
    .then(item => item.data.data);
};
