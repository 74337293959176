import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { DatePicker, Select, Table, TableProps } from 'antd';

import {
  FILTER_CLEANING_DETAIL,
  FILTER_CLEANING_DETAIL_OPTIONS,
  RANGE_DATE_CLEANING_DETAIL_OPTIONS
} from 'constants/common';

import CalendarItem from 'components/cleaning-calendar/calendar-item';
import { useGetCleaningCalendar } from 'hooks/useGetCleaningCalendar';
import { toUtcTime } from 'utils';

import { CleaningItem } from 'services/api/type/room.type';
import 'styles/cleaning-calendar.scss';

import { useTranslation } from 'react-i18next';

const INITIAL_RANGE_DATE = 14;
const today = dayjs().startOf('day');

function CleaningCalendar() {
  const { t } = useTranslation();
  const [date, setDate] = useState(today);
  const [filter, setFilter] = useState(FILTER_CLEANING_DETAIL.ROOM);
  const [rangeDate, setRangeDate] = useState(INITIAL_RANGE_DATE);
  const { data: cleaningCalendar, isFetching } = useGetCleaningCalendar(
    String(toUtcTime(date, 'YYYY-MM-DD')),
    rangeDate
  );

  // const onSearch: FormProps["onFinish"] = async (values: any) => {};

  const handleChangeDate = (value: any) => {
    setDate(value);
  };

  const handleChangeFilter = (value: string) => {
    setFilter(value);
  };

  const handleChangeRangeDate = (value: number) => {
    setRangeDate(value);
  };

  // const handleChangeStatus = (value: any, col: any, record: any) => {
  //   console.log("handleChangeStatus", value, col, record);
  // };

  const getLabelByFloor = (floor: string) => {
    return Number(floor);
  };

  const groupDataByFloor = () => {
    const groupedData = _.groupBy(_.cloneDeep(cleaningCalendar), item => {
      // Ex: 101 -> 1, 1001 -> 10
      return item.attributes?.room_no?.slice(0, item.attributes?.room_no?.length - 2);
    });
    return groupedData;
  };

  const groupDataByRoomType = () => {
    const groupedData = _.groupBy(_.cloneDeep(cleaningCalendar), item => item.room_type_name);
    return groupedData;
  };

  const rowCleaningRow = useMemo(() => {
    if (filter === FILTER_CLEANING_DETAIL.FLOOR) {
      const groupedData = groupDataByFloor();
      Object.values(groupedData).forEach(arr => {
        arr.sort((a, b) => a.attributes.room_no.localeCompare(b.attributes.room_no));
      });

      const flattenCalendar = _.flatMap(groupedData);
      const hashRowSpan: any = {};
      const result = (flattenCalendar || []).map(item => {
        const floor = item.attributes?.room_no?.slice(0, item.attributes?.room_no?.length - 2);

        _.set(item, 'floor', floor);
        _.set(item, 'floorLabel', getLabelByFloor(floor));
        if (!hashRowSpan[floor]) {
          hashRowSpan[floor] = true;
          _.set(item, 'rowSpan', groupedData[floor].length);
        }
        return item;
      });

      return result;
    } else if (filter === FILTER_CLEANING_DETAIL.ROOM_TYPE) {
      const groupedData = groupDataByRoomType();
      Object.values(groupedData).forEach(arr => {
        arr.sort((a, b) => a.attributes.room_no.localeCompare(b.attributes.room_no));
      });

      const flattenCalendar = _.flatMap(groupedData);
      const hashRowSpan: any = {};

      const result = (flattenCalendar || []).map(item => {
        if (!hashRowSpan[item.room_type_name]) {
          hashRowSpan[item.room_type_name] = true;
          _.set(item, 'rowSpan', groupedData[item.room_type_name].length);
        }
        return item;
      });
      return result;
    }

    return cleaningCalendar;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cleaningCalendar, filter]);

  const columnCleaningRoom: TableProps<any>['columns'] = useMemo(() => {
    let startDate = dayjs(date);
    const endDate = date.add(rangeDate, 'day');
    const columns: TableProps<any>['columns'] = [];

    while (!startDate.isSame(endDate)) {
      const addedColumn = startDate.format('YYYY-MM-DD');
      columns.push({
        align: 'center',
        title: startDate.format('DD/MM'),
        dataIndex: addedColumn,
        key: addedColumn
      });
      startDate = startDate.add(1, 'day');
    }

    columns.forEach((col: any) => {
      col.width = '160px';
      col.render = (data: any, dataRow: any) => {
        return (
          <CalendarItem
            data={data}
            roomId={dataRow.room_id}
            roomNo={_.get(dataRow, 'attributes.room_no', '')}
          />
        );
      };
    });

    columns.unshift({
      align: 'center',
      title: t('common.bookingData.room'),
      dataIndex: 'attributes',
      key: 'attributes',
      width: 160,
      fixed: 'left',
      render: (text: string, record: CleaningItem) => (
        <p className="m-0" style={{ minWidth: '120px' }}>
          {record.attributes.room_no}
        </p>
      )
    });

    if (filter === FILTER_CLEANING_DETAIL.FLOOR) {
      columns.unshift({
        align: 'center',
        title: t('bookingPage.roomMap.floor'),
        dataIndex: 'floor',
        key: 'floor',
        width: 160,
        fixed: 'left',
        onCell: record => ({
          rowSpan: record.rowSpan ? record.rowSpan : 0
        }),
        render: (text: string, record: any) => (
          <p className="m-0" style={{ minWidth: '120px' }}>
            {record.floorLabel}
          </p>
        )
      });
    } else if (filter === FILTER_CLEANING_DETAIL.ROOM_TYPE) {
      columns.unshift({
        align: 'center',
        title: t('common.bookingData.roomType'),
        dataIndex: 'item.room_type_name',
        key: 'item.room_type_name',
        width: 160,
        fixed: 'left',
        onCell: record => ({
          rowSpan: record.rowSpan ? record.rowSpan : 0
        }),
        render: (text: string, record: any) => (
          <p className="m-0" style={{ minWidth: '120px' }}>
            {record.room_type_name}
          </p>
        )
      });
    }

    return columns;
  }, [date, filter, rangeDate, t]);

  const cleaningDetailOptionsLocale = FILTER_CLEANING_DETAIL_OPTIONS.map(option => ({
    ...option,
    label: t(option.label)
  }));

  const rangeDateLocale = RANGE_DATE_CLEANING_DETAIL_OPTIONS.map(option => ({
    ...option,
    label: t(option.label)
  }));

  return (
    <div className="pms-cleaning-calendar">
      <div className="pms-cleaning-calendar__timetable">
        <div className="pms-cleaning-calendar__filter">
          {/* <Form
            name="search-form"
            layout="inline"
            initialValues={{
              search: "",
            }}
            onFinish={onSearch}
            autoComplete="off"
          >
            <Form.Item name="search" className="search-input">
              <Input placeholder="Tìm mã đặt phòng, tên khách" />
            </Form.Item>
            <Button
              htmlType="submit"
              icon={<SearchOutlined />}
              className="ant-btn-secondary"
            />
          </Form> */}

          <div className="pms-cleaning-calendar__filter__right">
            <DatePicker
              value={date}
              allowClear={false}
              onChange={handleChangeDate}
              placeholder={t('common.time.selectDate')}
            />
            <Select
              placeholder={t('common.bookingData.room')}
              options={cleaningDetailOptionsLocale}
              value={filter}
              onChange={handleChangeFilter}
            />
            <Select
              placeholder={t('common.time.selectRangeDate')}
              options={rangeDateLocale}
              value={rangeDate}
              onChange={handleChangeRangeDate}
            />
          </div>
        </div>
        <div className="pms-cleaning-calendar__status">
          <div className="pms-cleaning-calendar__status-section">
            <div className="pms-cleaning-calendar__status-item">
              <span className="circle" style={{ backgroundColor: '#EA1618' }} />
              <span>{t('common.statuses.checkInRoom')}</span>
            </div>
            <div className="pms-cleaning-calendar__status-item">
              <span className="circle" style={{ backgroundColor: '#0075FF' }} />
              <span>{t('common.statuses.booked')}</span>
            </div>
            <div className="pms-cleaning-calendar__status-item">
              <span className="circle" style={{ backgroundColor: '#878787' }} />
              <span>{t('common.statuses.fixingRoom')}</span>
            </div>
          </div>

          <div className="pms-cleaning-calendar__status-section">
            <div className="pms-cleaning-calendar__status-item">
              <span className="circle circle--secondary" />
              <span>
                S <span className="caption">({t('roomPage.cleaningCalendar.service')})</span>
              </span>
            </div>
            <div className="pms-cleaning-calendar__status-item">
              <span className="circle circle--secondary" />
              <span>
                FS <span className="caption">({t('roomPage.cleaningCalendar.fullService')})</span>
              </span>
            </div>
            <div className="pms-cleaning-calendar__status-item">
              <span className="circle circle--secondary" />
              <span>
                ARR <span className="caption">({t('roomPage.cleaningCalendar.arrivalGuest')})</span>
              </span>
            </div>
            <div className="pms-cleaning-calendar__status-item">
              <span className="circle circle--secondary" />
              <span>
                DEP{' '}
                <span className="caption">({t('roomPage.cleaningCalendar.departureGuest')})</span>
              </span>
            </div>
          </div>
        </div>
        <div className="pms-cleaning-calendar__table">
          <Table
            rowKey="room_id"
            loading={isFetching}
            columns={columnCleaningRoom}
            dataSource={rowCleaningRow}
            pagination={false}
            scroll={{ x: 1000, y: 'calc(100vh - 320px)' }}
            bordered
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default CleaningCalendar;
