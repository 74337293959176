import React, { useEffect, useState } from 'react';
import { Modal, Empty, Typography, List, Tag, Form, DatePicker, Select, Button } from 'antd';
import useModal from 'stores/useModal';
import { toLocalTime, getStartOfToday, getEndOfToday } from 'utils';
import { FormProps } from 'rc-field-form';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import 'styles/transaction-history-logs.scss';
import { useGetTransactionHistoryLogs } from 'hooks/useGetTransactionHistoryLogs';
import { TransactionHistoryLogsType } from 'services/api/type/transaction-history-logs.type';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { Text, Title } = Typography;

const formatCurrency = (log: string) => {
  return log.replace(/(-?\d+)\.(\d+)/g, (_, num) => {
    return `${Number(num).toLocaleString()}`;
  });
};

const groupLogsByDate = (logs: any) => {
  return logs.reduce((acc: any, log: any) => {
    const dateKey = toLocalTime(log.create_date, 'YYYY-MM-DD');
    acc[dateKey] = acc[dateKey] || [];
    log.log = formatCurrency(log.log);
    acc[dateKey].push(log);
    return acc;
  }, {});
};

function TransactionHistoryModal() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { isOpenTransactionHistory, setIsOpenTransactionHistory, bookingLineOrderId } = useModal();
  const [filterOptions, setFilterOptions] = useState<any>({
    booking_line_order_id: bookingLineOrderId
  });
  const [range, setRange] = useState<[Dayjs, Dayjs] | null>(null);
  const [initialized, setInitialized] = useState(false);

  const { data: transactionHistoryData } = useGetTransactionHistoryLogs(filterOptions);

  const trackingLogs = transactionHistoryData ? groupLogsByDate(transactionHistoryData) : {};

  if (
    transactionHistoryData &&
    transactionHistoryData.length > 0 &&
    isOpenTransactionHistory &&
    !initialized
  ) {
    const dates = transactionHistoryData.map((log: any) =>
      dayjs(log.create_date).format('YYYY-MM-DD')
    );
    const oldestDate = dayjs(Math.min(...dates.map((d: string) => dayjs(d).valueOf())));
    const latestDate = dayjs(Math.max(...dates.map((d: string) => dayjs(d).valueOf())));

    setRange([oldestDate, latestDate]);
    setInitialized(true);

    form.setFieldsValue({
      rangeDate: [oldestDate, latestDate]
    });
  }

  useEffect(() => {
    if (!isOpenTransactionHistory) {
      form.resetFields();
      setRange(null);
      setInitialized(false);
    } else {
      setFilterOptions({
        booking_line_order_id: bookingLineOrderId
      });
      setInitialized(false);
    }
  }, [isOpenTransactionHistory, form, bookingLineOrderId]);

  const handleCancel = () => {
    setIsOpenTransactionHistory(false);
    form.resetFields();
  };

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, type, ...restFilterOptions } = truthyValues;
    if (rangeDate) {
      const startDate: Dayjs = rangeDate[0];
      const endDate: Dayjs = rangeDate[1];

      if (startDate && endDate) {
        _.set(restFilterOptions, 'start_date', getStartOfToday(startDate.format('YYYY-MM-DD')));
        _.set(restFilterOptions, 'end_date', getEndOfToday(endDate.format('YYYY-MM-DD')));
        _.set(restFilterOptions, 'type', type);
        _.set(restFilterOptions, 'booking_line_order_id', bookingLineOrderId);
      }
    }

    setFilterOptions(restFilterOptions);
  };

  return (
    <Modal
      title={t('cashierPage.history.title')}
      centered
      width={1150}
      open={isOpenTransactionHistory}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose
      className="transaction-history-logs-modal"
      bodyStyle={{ padding: '20px', height: '70vh' }}
    >
      <Form form={form} layout="inline" onFinish={onFinish} className="form-container">
        <Form.Item name="rangeDate">
          <DatePicker.RangePicker
            className="range-picker"
            placeholder={[t('cashierPage.common.fromDate'), t('cashierPage.common.toDate')]}
            format="DD-MM-YYYY"
            value={range}
            onChange={dates => {
              if (dates && dates[0] && dates[1]) {
                setRange([dates[0], dates[1]]);
              } else {
                setRange(null);
              }
            }}
          />
        </Form.Item>
        <Form.Item name="type" className="select-type-form-item">
          <Select placeholder={t('cashierPage.history.selectType')} allowClear>
            {Object.keys(TransactionHistoryLogsType).map(key => (
              <Option key={key} value={key}>
                {TransactionHistoryLogsType[key]}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" className="submit-button">
            {t('common.actions.submitButton')}
          </Button>
        </Form.Item>
      </Form>

      <div className="logs-container">
        {Object.keys(trackingLogs).length ? (
          Object.keys(trackingLogs).map(dateKey => (
            <div key={dateKey} className="log-date">
              <Title level={5} className="log-title">
                {toLocalTime(dateKey, 'DD/MM/YYYY')}
              </Title>
              <List
                itemLayout="vertical"
                dataSource={trackingLogs[dateKey]}
                className="log-list"
                renderItem={(log: any) => (
                  <List.Item key={log.id} className="log-item">
                    <Text strong className="log-time">
                      {toLocalTime(log.create_date, 'HH:mm:ss')}{' '}
                      <Tag className="log-tag" color="blue">
                        {TransactionHistoryLogsType[log.type] || log.type}
                      </Tag>
                    </Text>
                    <Text className="log-detail">
                      {log.user_email}: {log.log}
                    </Text>
                  </List.Item>
                )}
              />
            </div>
          ))
        ) : (
          <Empty description={t('common.actions.noData')} className="empty-state" />
        )}
      </div>
    </Modal>
  );
}

export default TransactionHistoryModal;
