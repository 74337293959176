import { useEffect, useMemo, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { Button, Card, DatePicker, Form, FormProps, Table } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { queryClient } from 'index';

import { useGetAllShiftHandover } from 'hooks/useGetAllShiftHandover';
import QUERY_KEYS from 'services/api/queryKeys';
import { toUtcTime } from 'utils';
import 'styles/shift-handover-report.scss';
import { getColumnShiftHandover } from 'constants/table';
import { PAGE_SIZE_SHIFT_HANDOVER } from 'services/api/module/report.api';
import { useTranslation } from 'react-i18next';

const initDateFrom = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
const initDateTo = dayjs().set('hour', 23).set('minute', 59).set('second', 59);

function ShiftHandoverReport() {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const isIpadMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });

  const [filterOptions, setFilterOptions] = useState<any>({
    date_from: toUtcTime(initDateFrom, 'YYYY-MM-DD'),
    date_to: toUtcTime(initDateTo, 'YYYY-MM-DD')
  });

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_SHIFT_HANDOVER);
  const { data, pagination, isFetching } = useGetAllShiftHandover(filterOptions, page, pageSize);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.GET_ALL_SHIFT_HANDOVER, page]
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, ...restFilterOptions } = truthyValues;

    if (rangeDate) {
      const dateFrom: Dayjs = rangeDate[0];
      const dateTo: Dayjs = rangeDate[1];
      if (dateFrom) {
        _.set(restFilterOptions, 'date_from', toUtcTime(dateFrom, 'YYYY-MM-DD'));
      }
      if (dateTo) {
        _.set(restFilterOptions, 'date_to', toUtcTime(dateTo, 'YYYY-MM-DD'));
      }
    }

    if (page !== 1) {
      setPage(1);
    }

    setFilterOptions(restFilterOptions);
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const columnShiftHandover = useMemo(() => {
    return getColumnShiftHandover(t);
  }, [t]);

  return (
    <div className="pms-shift-handover-report">
      <Card title={t('managePage.shiftHandoverListPage.title')} style={{ width: '100%' }}>
        <Form
          form={form}
          name="shift-handover-report-form"
          layout={isIpadMobile ? 'vertical' : 'inline'}
          initialValues={{
            rangeDate: [initDateFrom, initDateTo]
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-shift-handover-report__filter-wrapper">
            <div className="pms-shift-handover-report__filter">
              <Form.Item name="rangeDate">
                <DatePicker.RangePicker
                  showTime
                  className="w-full"
                  placeholder={[t('common.time.startDate'), t('common.time.endDate')]}
                  format="YYYY-MM-DD HH:mm"
                />
              </Form.Item>

              <Button htmlType="submit" className="ant-btn-secondary btn-submit">
                {t('common.actions.submitButton')}
              </Button>
            </div>
          </div>
        </Form>

        <div className="pms-shift-handover-report__table">
          <Table
            loading={isFetching}
            columns={columnShiftHandover}
            dataSource={data}
            pagination={{
              current: page,
              position: ['bottomCenter'],
              total: pagination.total,
              pageSize: pageSize,
              locale: { items_per_page: `/ ${t('common.page')}` },
              showTotal: total => `${total} items`,
              onChange: (page: number) => handleChangePage(page),
              onShowSizeChange: size => {
                setPageSize(size);
              }
            }}
            locale={{
              emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
            }}
            scroll={{ x: 600, y: 'calc(100vh - 400px)' }}
          />
        </div>
      </Card>
    </div>
  );
}

export default ShiftHandoverReport;
