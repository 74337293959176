import React, { useEffect, useMemo, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import {
  Button,
  Card,
  Input,
  DatePicker,
  DatePickerProps,
  Select,
  Form,
  FormProps,
  Table,
  notification
} from 'antd';
import { useMediaQuery } from 'react-responsive';
import { useMutation } from '@tanstack/react-query';
import { matchPath, useLocation } from 'react-router-dom';

import { BOOKING_STATUS, BOOKING_STATUS_OPTIONS } from 'constants/form';
import { getBookingListColumns, getBookingListColumnsExpand } from 'constants/table';

import { useGetBooking } from 'hooks/useGetBooking';
import useModal from 'stores/useModal';
import useBookingStore from 'stores/useBooking';

import QUERY_KEYS from 'services/api/queryKeys';
import { RangeValueType } from 'services/api/type/common.type';
import {
  PAGE_SIZE_BOOKING_LIST,
  actionCheckIn,
  actionDuplicateBookingLine
} from 'services/api/module/booking.api';
import PAGES from 'routes/constants';
import { toUtcTime } from 'utils';
import 'styles/receptionist.scss';
import { nanoid } from 'nanoid';
import ExportArrivalGuest from 'components/common/export-arrival-guests';
import { ACTION_BOOKING } from 'components/booking-list/action-booking';
import { queryClient } from 'index';

import { useTranslation } from 'react-i18next';

const disabledDate: DatePickerProps['disabledDate'] = (current, { from }) => {
  if (from) {
    return current.isBefore(from);
  }

  return false;
};

const initStartDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
const initEndDate = dayjs().set('hour', 23).set('minute', 59).set('second', 59);

function Receptionist() {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const rangeDateForm = Form.useWatch('rangeDate', form);
  const statusForm = Form.useWatch('status', form);
  const isIpadMobile = useMediaQuery({
    query: '(max-width: 991px)'
  });

  const location = useLocation();
  const {
    setIsOpenCancelRoom,
    setIsOpenAddService,
    setIsOpenChangeRoom,
    setIsOpenChangeDate,
    setOpenBookingDetail,
    setBookingLineId,
    setIsOpenCancelAssignRoom,
    setIsOpenAssignRoom,
    setInfoConfirmModal,
    setIsChooseSurveyLanguage
  } = useModal();
  const { setBookingLineId: setBookingLineIdBookingStore } = useBookingStore();
  const { mutateAsync: mutateCheckIn } = useMutation({
    mutationFn: (bookingLineId: number) => actionCheckIn(bookingLineId)
  });
  const { mutateAsync: mutateDuplicate } = useMutation({
    mutationFn: (bookingLineId: number) => actionDuplicateBookingLine(bookingLineId)
  });

  const selectedBookingLineId = useRef<number>();

  // For mount case
  const filterOptionInit = useMemo(() => {
    const result = { status: '' };
    if (
      matchPath(PAGES.receptionistGuestWillCheckIn, location.pathname) ||
      matchPath(PAGES.receptionistGuestCheckIn, location.pathname)
    ) {
      if (matchPath(PAGES.receptionistGuestWillCheckIn, location.pathname)) {
        _.set(result, 'status', BOOKING_STATUS.CONFIRM);
      }
      if (matchPath(PAGES.receptionistGuestCheckIn, location.pathname)) {
        _.set(result, 'status', BOOKING_STATUS.ALLOT);
      }

      const startDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
      _.set(result, 'check_in_from', toUtcTime(startDate));

      const endDate = dayjs().set('hour', 23).set('minute', 59).set('second', 59);
      _.set(result, 'check_in_to', toUtcTime(endDate));
    } else if (
      matchPath(PAGES.receptionistGuestWillCheckOut, location.pathname) ||
      matchPath(PAGES.receptionistGuestCheckout, location.pathname)
    ) {
      const startDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0);

      const endDate = dayjs().set('hour', 23).set('minute', 59).set('second', 59);

      if (matchPath(PAGES.receptionistGuestWillCheckOut, location.pathname)) {
        _.set(result, 'status', BOOKING_STATUS.ALLOT);
        _.set(result, 'check_out_from', toUtcTime(startDate));
        _.set(result, 'check_out_to', toUtcTime(endDate));
      }
      if (matchPath(PAGES.receptionistGuestCheckout, location.pathname)) {
        _.set(result, 'status', BOOKING_STATUS.CHECKOUT);
        _.set(result, 'actual_check_out_from', toUtcTime(startDate));
        _.set(result, 'actual_check_out_to', toUtcTime(endDate));
      }
    } else if (matchPath(PAGES.receptionistUnavailable, location.pathname)) {
      const findDate = dayjs();
      const startDate: Dayjs = findDate.set('hour', 0).set('minute', 0).set('second', 0);
      const endDate: Dayjs = findDate.set('hour', 23).set('minute', 59).set('second', 59);
      _.set(result, 'check_in_from', toUtcTime(startDate));
      _.set(result, 'check_in_to', toUtcTime(endDate));
      _.set(result, 'status', `${BOOKING_STATUS.ALLOT},${BOOKING_STATUS.CONFIRM}`);
    }
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const [filterOptions, setFilterOptions] = useState<any>(filterOptionInit);

  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_BOOKING_LIST);
  const { data, pagination, isFetching } = useGetBooking(filterOptions, page, pageSize);
  useEffect(() => {
    const result = { status: [''] };
    form.setFieldValue('search', '');

    if (
      matchPath(PAGES.receptionistGuestWillCheckIn, location.pathname) ||
      matchPath(PAGES.receptionistGuestCheckIn, location.pathname)
    ) {
      if (matchPath(PAGES.receptionistGuestWillCheckIn, location.pathname)) {
        _.set(result, 'status', BOOKING_STATUS.CONFIRM);
      }
      if (matchPath(PAGES.receptionistGuestCheckIn, location.pathname)) {
        _.set(result, 'status', BOOKING_STATUS.ALLOT);
      }

      const startDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0);
      _.set(result, 'check_in_from', toUtcTime(startDate));

      const endDate = dayjs().set('hour', 23).set('minute', 59).set('second', 59);
      _.set(result, 'check_in_to', toUtcTime(endDate));

      form.setFieldsValue({
        rangeDate: [startDate, endDate],
        status: [result.status]
      });
    } else if (
      matchPath(PAGES.receptionistGuestWillCheckOut, location.pathname) ||
      matchPath(PAGES.receptionistGuestCheckout, location.pathname)
    ) {
      const startDate = dayjs().set('hour', 0).set('minute', 0).set('second', 0);

      const endDate = dayjs().set('hour', 23).set('minute', 59).set('second', 59);

      if (matchPath(PAGES.receptionistGuestWillCheckOut, location.pathname)) {
        _.set(result, 'status', BOOKING_STATUS.ALLOT);
        _.set(result, 'check_out_from', toUtcTime(startDate));
        _.set(result, 'check_out_to', toUtcTime(endDate));
      }
      if (matchPath(PAGES.receptionistGuestCheckout, location.pathname)) {
        _.set(result, 'status', BOOKING_STATUS.CHECKOUT);
        _.set(result, 'actual_check_out_from', toUtcTime(startDate));
        _.set(result, 'actual_check_out_to', toUtcTime(endDate));
      }

      form.setFieldsValue({
        rangeDate: [startDate, endDate],
        status: [result.status]
      });
    } else if (matchPath(PAGES.receptionistUnavailable, location.pathname)) {
      const findDate = dayjs();
      const startDate: Dayjs = findDate.set('hour', 0).set('minute', 0).set('second', 0);
      const endDate: Dayjs = findDate.set('hour', 23).set('minute', 59).set('second', 59);
      _.set(result, 'check_in_from', toUtcTime(startDate));
      _.set(result, 'check_in_to', toUtcTime(endDate));
      _.set(result, 'status', `${BOOKING_STATUS.ALLOT},${BOOKING_STATUS.CONFIRM}`);

      form.setFieldsValue({
        date: findDate,
        status: [BOOKING_STATUS.ALLOT, BOOKING_STATUS.CONFIRM]
      });
    } else {
      _.set(result, 'status', BOOKING_STATUS.ALLOT);
      form.setFieldsValue({
        rangeDate: null,
        status: [result.status]
      });
    }

    if (page !== 1) {
      setPage(1);
    }

    setFilterOptions(result);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_LIST]
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  const onFinish: FormProps['onFinish'] = async (objValue: any) => {
    const truthyValues: any = Object.keys(objValue)
      .filter(key => Boolean(objValue[key]))
      .reduce((cur, next) => {
        return {
          ...cur,
          [next]: objValue[next]
        };
      }, {});

    const { rangeDate, status, date, ...restFilterOptions } = truthyValues;
    _.set(restFilterOptions, 'status', status.join(','));
    if (rangeDate) {
      const from: Dayjs = rangeDate[0];
      const to: Dayjs = rangeDate[1];
      if (
        matchPath(PAGES.receptionistGuestWillCheckIn, location.pathname) ||
        matchPath(PAGES.receptionistGuestCheckIn, location.pathname)
      ) {
        if (from) {
          _.set(restFilterOptions, 'check_in_from', toUtcTime(from, 'YYYY-MM-DD HH:mm:ss'));
        }
        if (to) {
          _.set(restFilterOptions, 'check_in_to', toUtcTime(to, 'YYYY-MM-DD HH:mm:ss'));
        }
      }
      if (matchPath(PAGES.receptionistGuestWillCheckOut, location.pathname)) {
        if (from) {
          _.set(restFilterOptions, 'check_out_from', toUtcTime(from));
        }
        if (to) {
          _.set(restFilterOptions, 'check_out_to', toUtcTime(to));
        }
      }
      if (matchPath(PAGES.receptionistGuestCheckout, location.pathname)) {
        if (from) {
          _.set(restFilterOptions, 'actual_check_out_from', toUtcTime(from));
        }
        if (to) {
          _.set(restFilterOptions, 'actual_check_out_to', toUtcTime(to));
        }
      }
    }

    if (date && matchPath(PAGES.receptionistUnavailable, location.pathname)) {
      const startDate: Dayjs = date.set('hour', 0).set('minute', 0).set('second', 0);
      const endDate: Dayjs = date.set('hour', 23).set('minute', 59).set('second', 59);
      _.set(restFilterOptions, 'check_in_from', toUtcTime(startDate, 'YYYY-MM-DD HH:mm:ss'));
      _.set(restFilterOptions, 'check_in_to', toUtcTime(endDate, 'YYYY-MM-DD HH:mm:ss'));
    }

    if (page !== 1) {
      setPage(1);
    }
    setFilterOptions(restFilterOptions);
  };

  const onFinishFailed: FormProps['onFinishFailed'] = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const determineSurveyType = (pathname: any) => {
    if (pathname.includes(PAGES.receptionistGuestCheckIn)) return 'arrival';
    if (pathname.includes(PAGES.receptionistGuestCheckout)) return 'departure';
    return '';
  };

  const handleClickMenu = (menu: any) => {
    switch (menu.key) {
      case ACTION_BOOKING.DETAIL:
        setOpenBookingDetail(true);
        break;
      case ACTION_BOOKING.ADD_SERVICE:
        setIsOpenAddService(true);
        break;
      case ACTION_BOOKING.CANCEL_BOOKING:
        setIsOpenCancelRoom(true);
        break;
      case ACTION_BOOKING.CHANGE_ROOM:
        setIsOpenChangeRoom(true);
        break;
      case ACTION_BOOKING.CHANGE_DATE:
        setIsOpenChangeDate(true);
        break;
      case ACTION_BOOKING.GET_ROOM:
        setInfoConfirmModal(true, {
          title: 'Xác nhận nhận phòng',
          onOk: () => {
            setInfoConfirmModal(false);
            handleCheckIn();
          }
        });
        break;
      case ACTION_BOOKING.ASSIGN_ROOM:
        setIsOpenAssignRoom(true);
        break;
      case ACTION_BOOKING.CANCEL_ASSIGN_ROOM:
        setIsOpenCancelAssignRoom(true);
        break;
      case ACTION_BOOKING.DUPLICATE_BOOKING_LINE:
        setInfoConfirmModal(true, {
          title: 'Xác nhận nhân đôi đơn đặt',
          onOk: () => {
            setInfoConfirmModal(false);
            handleDuplicateBookingLine();
          }
        });
        break;
      case ACTION_BOOKING.CREATE_SURVEY:
        const surveyType = determineSurveyType(location.pathname);
        setIsChooseSurveyLanguage(true, surveyType);
        break;
      default:
        notification.warning({
          message: 'Not yet implemented'
        });
        break;
    }
  };

  const handleCheckIn = async () => {
    if (selectedBookingLineId.current) {
      try {
        await mutateCheckIn(selectedBookingLineId.current);
        notification.success({
          message: 'Nhận phòng thành công'
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_LIST, page]
        });
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.KEY_ROOM,
            QUERY_KEYS.GET_BOOKING_DETAIL,
            selectedBookingLineId.current
          ]
        });
        await queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.GET_TRACKING_LOGS, selectedBookingLineId.current]
        });
      } catch (err: any) {
        notification.error({
          message: err.error || 'Lỗi xảy ra'
        });
      }
    }
  };

  const handleDuplicateBookingLine = async () => {
    if (selectedBookingLineId.current) {
      try {
        await mutateDuplicate(selectedBookingLineId.current);
        notification.success({
          message: 'Nhân đôi đơn đặt thành công'
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.KEY_ROOM, QUERY_KEYS.GET_BOOKING_LIST, page]
        });
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEYS.KEY_ROOM,
            QUERY_KEYS.GET_BOOKING_DETAIL,
            selectedBookingLineId.current
          ]
        });
      } catch (err: any) {
        notification.error({
          message: err.error || 'Lỗi xảy ra'
        });
      }
    }
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleRangeDateChange = (value: RangeValueType | null) => {
    let startDate: Dayjs | null = _.get(value, '0', null);
    let endDate: Dayjs | null = _.get(value, '1', null);
    if (startDate) {
      startDate = startDate.set('hour', 0).set('minute', 0).set('second', 0);
    }
    if (endDate) {
      endDate = endDate.set('hour', 23).set('minute', 59).set('second', 59);
    }
    form.setFieldValue('rangeDate', [startDate, endDate]);
  };

  const columnBookingList = useMemo(() => {
    return getBookingListColumns(
      handleClickMenu,
      !!matchPath(PAGES.receptionistGuestCheckout, location.pathname),
      !!matchPath(PAGES.receptionistActualCheckIn, location.pathname),
      t,
      !!matchPath(PAGES.receptionistGuestWillCheckIn, location.pathname),
      !!matchPath(PAGES.receptionistGuestCheckIn, location.pathname),
      !!matchPath(PAGES.receptionistGuestCheckout, location.pathname)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const columnBookingListExpand = useMemo(() => {
    return getBookingListColumnsExpand(
      handleClickMenu,
      !!matchPath(PAGES.receptionistGuestCheckout, location.pathname),
      !!matchPath(PAGES.receptionistActualCheckIn, location.pathname),
      t,
      !!matchPath(PAGES.receptionistGuestWillCheckIn, location.pathname),
      !!matchPath(PAGES.receptionistGuestCheckIn, location.pathname),
      !!matchPath(PAGES.receptionistGuestCheckout, location.pathname)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  return (
    <div className="pms-receptionist">
      <Card title="Tất cả" style={{ width: '100%' }}>
        <Form
          form={form}
          name="booking-filter-form"
          layout={isIpadMobile ? 'vertical' : 'inline'}
          initialValues={{
            bookingId: '',
            rangeDate: null,
            status: [''],
            search: ''
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <div className="pms-receptionist__filter">
            <Form.Item name="search" style={{ flex: 1 }}>
              <Input placeholder={t('bookingPage.bookingList.searchPlaceholder')} />
            </Form.Item>

            <Form.Item name="booking_id">
              <Input placeholder={t('common.bookingData.bookingId')} />
            </Form.Item>

            {!matchPath(PAGES.receptionistGuestAllot, location.pathname) &&
              !matchPath(PAGES.receptionistUnavailable, location.pathname) && (
                <Form.Item name="rangeDate">
                  <DatePicker.RangePicker
                    className="w-full"
                    placeholder={[t('common.time.startDate'), t('common.time.endDate')]}
                    disabledDate={disabledDate}
                    format="YYYY-MM-DD HH:mm"
                    onChange={handleRangeDateChange}
                  />
                </Form.Item>
              )}

            {matchPath(PAGES.receptionistUnavailable, location.pathname) && (
              <Form.Item name="date">
                <DatePicker className="w-full" format="YYYY-MM-DD" />
              </Form.Item>
            )}

            <div className="select-wrapper">
              <span className="status-text">{t('common.bookingData.status')}: </span>
              <Form.Item name="status">
                <Select mode="multiple" options={BOOKING_STATUS_OPTIONS} />
              </Form.Item>
            </div>

            <Button htmlType="submit" className="ant-btn-secondary btn-submit">
              {t('common.actions.submitButton')}
            </Button>
            <ExportArrivalGuest
              filterOptions={{
                page: pagination.page,
                limit: pagination.total,
                filterOptions: filterOptions
              }}
              fileName={`guests-report-${(_.get(rangeDateForm, '0') || initStartDate)?.format(
                'DD-MM-YYYY'
              )}-${(_.get(rangeDateForm, '1') || initEndDate)?.format('DD-MM-YYYY')}-${nanoid()}`}
              statusForm={statusForm}
              t={t}
            />
          </div>
        </Form>

        <div className="pms-receptionist__table">
          <Table
            loading={isFetching}
            rowKey="booking_id"
            columns={columnBookingList}
            dataSource={data}
            onRow={record => {
              return {
                onClick: () => {
                  setBookingLineId(record.booking_line_id);
                  setBookingLineIdBookingStore(record.booking_line_id);
                  selectedBookingLineId.current = record.booking_line_id;
                } // click row
              };
            }}
            pagination={{
              current: page,
              position: ['bottomCenter'],
              total: pagination.total,
              pageSize: pageSize,
              locale: { items_per_page: '/ trang' },
              showTotal: total => `${total} items`,
              onChange: (page: number) => handleChangePage(page),
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              }
            }}
            locale={{
              emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
            }}
            scroll={{ x: 500, y: 'calc(100vh - 400px)' }}
            expandable={{
              expandedRowRender: record => {
                return (
                  <Table
                    rowKey="booking_line_id"
                    columns={columnBookingListExpand}
                    showHeader={false}
                    dataSource={record.expandableRows}
                    pagination={false}
                    scroll={{ x: 500 }}
                    onRow={record => {
                      return {
                        onClick: () => {
                          setBookingLineId(record.booking_line_id);
                          setBookingLineIdBookingStore(record.booking_line_id);
                          selectedBookingLineId.current = record.booking_line_id;
                        } // click row
                      };
                    }}
                  />
                );
              },
              rowExpandable: record => !_.isEmpty(record.expandableRows),
              columnWidth: 30,
              defaultExpandAllRows: true
            }}
          />
        </div>
      </Card>
    </div>
  );
}

export default Receptionist;
