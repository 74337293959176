import React, { useMemo, useState } from 'react';
import { Button, Card, Input, Form, FormProps, Table } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useGetGroupBookings } from 'hooks/useGetGroupBookings';

import { PAGE_SIZE_STAYING_GUESTS } from 'services/api/module/report.api';
import { getColumnListGroup } from 'constants/table';
import { getDetailGroupURL } from 'routes/constants';
import 'styles/list-group.scss';

import { useTranslation } from 'react-i18next';

function ListGroup() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [page, setPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_STAYING_GUESTS);

  const [search, setSearch] = useState('');
  const { data, pagination, isFetching } = useGetGroupBookings(page, search);

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const onFinish: FormProps['onFinish'] = async (values: any) => {
    setSearch(values.searchGroup);
  };

  const columnListGroup = useMemo(() => {
    return getColumnListGroup(page, t);
  }, [page, t]);

  return (
    <div className="pms-list-group">
      <Card title={t('groupPage.groupList.title')} style={{ width: '100%' }}>
        <Form
          form={form}
          name="list-group-filter-form"
          layout="inline"
          initialValues={{
            searchGroup: ''
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className="pms-list-group__filter">
            <Form.Item name="searchGroup" className="m-0">
              <Input placeholder={t('groupPage.createGroup.groupName')} allowClear />
            </Form.Item>

            <Button htmlType="submit" className="ant-btn-secondary btn-submit">
              {t('common.actions.submitButton')}
            </Button>
          </div>
        </Form>

        <div className="pms-list-group__table">
          <Table
            rowKey="id"
            loading={isFetching}
            columns={columnListGroup}
            dataSource={data}
            size="small"
            bordered
            locale={{
              emptyText: <span className="empty-data">Không có dữ liệu</span>
            }}
            onRow={record => {
              return {
                onClick: () => {
                  navigate(getDetailGroupURL(record.id));
                } // click row
              };
            }}
            scroll={{ x: 1000, y: 'calc(100vh - 400px)' }}
            pagination={{
              current: page,
              position: ['bottomCenter'],
              total: pagination.total,
              pageSize: pageSize,
              locale: { items_per_page: '/ trang' },
              showTotal: total => `${total} items`,
              onChange: (page: number) => handleChangePage(page),
              onShowSizeChange: (current, size) => {
                setPageSize(size);
              }
            }}
          />
        </div>
      </Card>
    </div>
  );
}

export default ListGroup;
