import { Table } from 'antd';
import React, { useState } from 'react';

interface CustomTableProps {
  rowKey: string;
  loading?: boolean;
  columns: any;
  dataSource: any[];
  t: (key: string) => string;
}

export default function CustomTable({ rowKey, loading, columns, dataSource, t }: CustomTableProps) {
  const [expandedRowKeys, setExpandedRowKeys] = useState<React.Key[]>([]);

  const handleExpand = (expanded: boolean, record: any) => {
    const newExpandedRowKeys = expanded
      ? [...expandedRowKeys, record[rowKey]]
      : expandedRowKeys.filter(key => key !== record[rowKey]);

    setExpandedRowKeys(newExpandedRowKeys);
  };

  return (
    <Table
      rowKey={rowKey}
      size="small"
      loading={loading || false}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      scroll={{ x: 1000, y: 'calc(80vh - 260px)' }}
      locale={{
        emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
      }}
      expandable={{
        expandedRowRender: record => (
          <Table
            size="small"
            columns={columns}
            showHeader={false}
            dataSource={rowKey == 'booking_id' ? record.services : record.bookings}
            pagination={false}
            scroll={{ x: 1000, y: 'calc(80vh - 260px)' }}
          />
        ),
        rowExpandable: record =>
          rowKey == 'booking_id' ? record.services?.length > 1 : record.bookings?.length > 1,
        expandedRowKeys,
        onExpand: handleExpand,
        columnWidth: 12
      }}
    />
  );
}
