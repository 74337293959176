import _ from 'lodash';
import React, { useEffect } from 'react';
import dayjs from 'dayjs';
import { Modal, Form, FormProps, Row, Col, notification, DatePicker, Button } from 'antd';
import { IdcardOutlined } from '@ant-design/icons';
import { matchPath, useLocation } from 'react-router-dom';

import useModalStore from 'stores/useModal';
import useBookingStore from 'stores/useBooking';
import { useGetBookingDetail } from 'hooks/useGetBookingDetail';

import { ReactComponent as IconClose } from 'assets/images/close.svg';
import { ReactComponent as IconCancelCalendar } from 'assets/images/cancel-calendar.svg';
import { ReactComponent as IconBedUser } from 'assets/images/bed-user.svg';
import {
  BATCH_ACTION_TYPE,
  BookingStatus,
  ParamsBatchAction
} from 'services/api/type/booking.type';
import { actionChangeDate, batchActionBookings } from 'services/api/module/booking.api';
import { useMutation } from '@tanstack/react-query';
import { toUtcTime } from 'utils';
import { queryClient } from 'index';
import QUERY_KEYS from 'services/api/queryKeys';
import PAGES from 'routes/constants';
import { PermissionActionTypes } from 'helper/type/action.type';
import IBButton from 'helper/permission.action';
import { useTranslation } from 'react-i18next';

function ChangeDateModal() {
  const { t } = useTranslation();

  const commonPlaceTranslate = 'common.bookingData.changeDate';
  const [form] = Form.useForm();
  const location = useLocation();

  const { isOpenChangeDate, setIsOpenChangeDate } = useModalStore();
  const { bookingLineId, checkedListBookingLineId, isActionOnGroup, setIsActionOnGroup } =
    useBookingStore();

  const { data: bookingLineDetail } = useGetBookingDetail(bookingLineId);

  const { mutateAsync: mutateChangeDate, isPending } = useMutation({
    mutationFn: (params: any = {}) =>
      actionChangeDate(
        Number(bookingLineDetail?.booking_line_id),
        params.check_in,
        params.check_out
      )
  });

  const { mutateAsync: mutateBatchActionBooking, isPending: isPendingBatch } = useMutation({
    mutationFn: (params: ParamsBatchAction) => batchActionBookings(params)
  });

  useEffect(() => {
    if (bookingLineDetail && isOpenChangeDate) {
      form.setFieldsValue({
        check_in: dayjs.utc(bookingLineDetail.check_in).local(),
        check_out: dayjs.utc(bookingLineDetail.check_out).local()
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingLineDetail, isOpenChangeDate]);

  const handleCloseChangeDate = () => {
    setIsOpenChangeDate(false);
    setIsActionOnGroup(false);
    form.resetFields();
  };

  const handleChangeDate = () => {
    form.submit();
  };

  const handleSetCurrentDate = () => {
    form.setFieldValue('check_out', dayjs());
  };

  const onFinishAssign: FormProps['onFinish'] = async (values: any) => {
    if (bookingLineDetail) {
      try {
        if (isActionOnGroup && !_.isEmpty(checkedListBookingLineId)) {
          await mutateBatchActionBooking({
            action: BATCH_ACTION_TYPE.CHANGE_DATE,
            booking_line_ids: checkedListBookingLineId,
            check_in: toUtcTime(values.check_in, 'YYYY-MM-DD HH:mm:ss'),
            check_out: toUtcTime(values.check_out, 'YYYY-MM-DD HH:mm:ss')
          });
        } else {
          await mutateChangeDate({
            check_in: toUtcTime(values.check_in, 'YYYY-MM-DD HH:mm:ss'),
            check_out: toUtcTime(values.check_out, 'YYYY-MM-DD HH:mm:ss')
          });
        }

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEYS.KEY_ROOM]
        });

        if (matchPath(PAGES.roomMap, location.pathname)) {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.GET_BOOKING_TIMELINE]
          });
        }

        if (matchPath(PAGES.nightAudit, location.pathname)) {
          queryClient.invalidateQueries({
            queryKey: [QUERY_KEYS.GET_BOOKING_LIST_ALL]
          });
        }

        setIsOpenChangeDate(false);
      } catch (err: any) {
        notification.error({
          message: err.error || t('common.error')
        });
      }
    }
  };

  return (
    <Modal
      title={t(`${commonPlaceTranslate}.title`)}
      centered
      width={410}
      open={isOpenChangeDate}
      onCancel={handleCloseChangeDate}
      closeIcon={<IconClose />}
      okText={null}
      cancelText="Đóng"
      onOk={handleChangeDate}
      footer={[
        <Button key="cancel" onClick={handleCloseChangeDate}>
          Đóng
        </Button>,
        <IBButton
          key="ok"
          permission={PermissionActionTypes.CHANGE_DATE}
          onClick={handleChangeDate}
          className="ant-btn-secondary"
        >
          Lưu
        </IBButton>
      ]}
      className="modal-change-date"
      confirmLoading={isPending || isPendingBatch}
      destroyOnClose
    >
      <Form
        form={form}
        name="change-date-form"
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{}}
        onFinish={onFinishAssign}
        autoComplete="off"
      >
        {(_.isEmpty(checkedListBookingLineId) || checkedListBookingLineId.length === 1) && (
          <Row gutter={[8, 8]}>
            <Col span={12}>
              <div className="col-type-room">
                <h4 className="title m-0">
                  {bookingLineDetail?.status === BookingStatus.CHECK_IN ? (
                    <IconBedUser />
                  ) : (
                    <IconCancelCalendar />
                  )}
                  {bookingLineDetail?.room_name || bookingLineDetail?.room_type_name + ' N/A'}
                </h4>
              </div>
            </Col>
            <Col span={12}>
              <div className="col-room">
                <div className="title">
                  <IdcardOutlined />
                  <p className="m-0 truncate" title={bookingLineDetail?.partner_name || 'Guest'}>
                    {bookingLineDetail?.partner_name || 'Guest'}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <Form.Item label="Sẽ đến" name="check_in">
          <DatePicker
            showTime
            format="DD/MM/YYYY HH:mm"
            className="w-full"
            // disabledDate={disabledDate}
            // minDate={dayjs()}
            disabled={bookingLineDetail?.status === BookingStatus.CHECK_IN}
          />
        </Form.Item>

        <div className="flex items-end" style={{ gap: '8px', marginBottom: '24px' }}>
          <Form.Item label="Sẽ đi" name="check_out" className="m-0 flex-grow">
            <DatePicker showTime format="DD/MM/YYYY HH:mm" className="w-full" />
          </Form.Item>
          <Button onClick={handleSetCurrentDate}>Hiện tại</Button>
        </div>
      </Form>
    </Modal>
  );
}

export default ChangeDateModal;
