import React, { useMemo, useState } from 'react';
import _ from 'lodash';
import dayjs from 'dayjs';
import ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import useCommonStore from 'stores/useCommon';
import { toLocalTime } from 'utils';
import { ACTION_EXPORT_DATA } from 'constants/common';

import { getRoomLock } from 'services/api/module/room.api';
import { RoomLockType, RoomType } from 'services/api/type/room.type';
import Logo from 'assets/images/logo.png';

import { useTranslation } from 'react-i18next';

interface Props {
  fileName: string;
  filterOptions: any;
  availRoomTypes: RoomType[];
}

const ExportRoomLockHistory = ({ fileName, filterOptions, availRoomTypes }: Props) => {
  const { t } = useTranslation();
  const { currentBranch } = useCommonStore();
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const handleClickMenu = (menu: any) => {
    switch (menu.key) {
      case ACTION_EXPORT_DATA.EXCEL:
        exportToCSV();
        break;
      default:
        break;
    }
  };

  const filterOptionsObject = filterOptions.filterOptions;

  const getAllData = async () => {
    const result = await getRoomLock(filterOptionsObject);
    return result;
  };

  const convertData = (data: RoomLockType[]) => {
    const hash = {} as any;
    const roomLocks: RoomLockType[] = _.clone(data);

    roomLocks.forEach((item: RoomLockType) => {
      if (!hash[item.room_type_name]) {
        const pushedItem = _.cloneDeep(item);
        hash[item.room_type_name] = {
          id: 'root',
          room_type_name: item.room_type_name,
          children: [_.cloneDeep(pushedItem)]
        };
      } else {
        const pushedItem = _.cloneDeep(item);
        hash[item.room_type_name].children.push(_.cloneDeep(pushedItem));
      }
    });

    const sortRoomType = _.orderBy(availRoomTypes, 'price', 'asc');
    Object.keys(hash).forEach(key => {
      hash[key].children = _.orderBy(
        hash[key].children,
        ['attributes.room_no', 'start_date', 'end_date'],
        ['asc', 'asc', 'asc']
      );
    });

    return sortRoomType
      .filter(item => item.room_type_name in hash)
      .map(item => hash[item.room_type_name]);
  };

  const exportToCSV = async () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    try {
      setIsLoadingExport(true);
      const result = await getAllData();

      const cookedData = convertData(_.get(result, 'data', []));
      let count = 0;
      const dataToExport = _.flatMap(
        Object.values(cookedData).map(data => {
          return data.children.map((item: any) => {
            ++count;
            return {
              stt: count,
              room_type_name: item.room_type_name,
              room_no: item.attributes.room_no,
              start_date: toLocalTime(item.start_date, 'DD/MM/YYYY HH:mm'),
              end_date: toLocalTime(item.end_date, 'DD/MM/YYYY HH:mm'),
              original_end_date: toLocalTime(item.original_end_date, 'DD/MM/YYYY HH:mm'),
              reason: item.reason,
              created_date: toLocalTime(item.create_date, 'DD/MM/YYYY HH:mm'),
              create_username: item.create_username
            };
          });
        })
      );

      // Sheet name
      const workbook = new ExcelJS.Workbook();

      const sheetName = `Police_${dayjs.utc(filterOptions.start_date).local().format('DD-MM-YYYY')}_${dayjs.utc(filterOptions.end_date).local().format('DD-MM-YYYY')}`;
      const sheet = workbook.addWorksheet(sheetName, {
        views: [
          {
            showGridLines: false
          }
        ]
      });

      // Title
      sheet.mergeCells('A1:J1');
      sheet.getCell('A1').value = currentBranch?.name?.toUpperCase() || '';
      sheet.getCell('A1').font = { bold: true, size: 14 };
      sheet.getCell('A1').alignment = { horizontal: 'center' };

      // Address
      sheet.mergeCells('A2:J2');
      sheet.getCell('A2').value = `Địa chỉ: ${currentBranch?.address}`;
      sheet.getCell('A2').font = { size: 10 };
      sheet.getCell('A2').alignment = { horizontal: 'center' };

      // Support
      sheet.mergeCells('A3:J3');
      sheet.getCell('A3').value = currentBranch?.phone_contact
        ? `ĐT: ${currentBranch?.phone_contact}`
        : '';
      sheet.getCell('A3').font = { size: 10 };
      sheet.getCell('A3').alignment = { horizontal: 'center' };

      // Support subtitle
      sheet.mergeCells('A4:J4');
      sheet.getCell('A4').value = `BÁO CÁO LỊCH SỬ KHÓA PHÒNG`;
      sheet.getCell('A4').font = { bold: true, size: 10 };
      sheet.getCell('A4').alignment = { horizontal: 'center' };

      sheet.mergeCells('E6:F6');
      sheet.getCell('E6').alignment = { horizontal: 'center' };
      sheet.getCell('E6').value = filterOptionsObject.start_date
        ? `Ngày: ${dayjs.utc(filterOptionsObject.start_date).local().format('DD-MM-YYYY')}`
        : 'Ngày';

      sheet.getCell('G6').value = filterOptionsObject.end_date
        ? `Đến: ${dayjs.utc(filterOptionsObject.end_date).local().format('DD-MM-YYYY')}`
        : 'Đến';

      const startDateStr = filterOptionsObject.start_date
        ? `Ngày: ${dayjs.utc(filterOptionsObject.start_date).local().format('DD-MM-YYYY')}`
        : 'Ngày';
      const endDateStr = filterOptionsObject.end_date
        ? `Đến: ${dayjs.utc(filterOptionsObject.end_date).local().format('DD-MM-YYYY')}`
        : 'Đến';

      const startDateCell = sheet.getCell('E6');
      startDateCell.value = {
        richText: [
          { text: 'Ngày: ', font: { bold: true } },
          { text: startDateStr.split(': ')[1] || '', font: { bold: false } }
        ]
      };
      startDateCell.alignment = { horizontal: 'center' };

      const endDateCell = sheet.getCell('G6');
      endDateCell.value = {
        richText: [
          { text: 'Đến: ', font: { bold: true } },
          { text: endDateStr.split(': ')[1] || '', font: { bold: false } }
        ]
      };

      // Header columns
      sheet.getRow(8).values = [
        'STT',
        'Hạng phòng',
        'Phòng',
        'Ngày bắt đầu',
        'Ngày kết thúc',
        'Ngày mở',
        'Ghi chú',
        'Ngày tạo khóa',
        'Người khóa'
      ];

      sheet.getCell('A7').alignment = { horizontal: 'center' };
      sheet.getCell('A8').alignment = { horizontal: 'center' };
      sheet.getRow(8).eachCell(function (cell) {
        cell.border = {
          bottom: { style: 'medium', color: { argb: '#000' } }
        };
        cell.font = { bold: true };
      });

      // Data
      sheet.columns = [
        {
          key: 'stt',
          width: 10,
          style: { alignment: { horizontal: 'center' } }
        },
        { key: 'room_type_name', width: 15 },
        {
          key: 'room_no',
          width: 15
        },
        { key: 'start_date', width: 20 },
        { key: 'end_date', width: 20 },
        { key: 'original_end_date', width: 20 },
        { key: 'reason', width: 40 },
        { key: 'created_date', width: 20 },
        { key: 'create_username', width: 20 }
      ];

      dataToExport.forEach(item => {
        sheet.addRow(item);
      });

      const imageId2 = workbook.addImage({
        base64: Logo,
        extension: 'png'
      });
      sheet.addImage(imageId2, {
        tl: { col: 1, row: 1 },
        ext: { width: 71, height: 57 }
      });

      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (e) {
      console.log('ERROR', e);
    } finally {
      setIsLoadingExport(false);
    }
  };

  const items: MenuProps['items'] = useMemo(() => {
    return [
      {
        key: ACTION_EXPORT_DATA.EXCEL,
        label: 'Excel'
      }
    ];
  }, []);

  return (
    <Dropdown
      menu={{ items, onClick: handleClickMenu }}
      trigger={['click']}
      overlayClassName="menu-user"
    >
      <Button icon={<DownloadOutlined />} loading={isLoadingExport}>
        {t('common.actions.exportData')}
      </Button>
    </Dropdown>
  );
};

export default ExportRoomLockHistory;
