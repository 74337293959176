import { TransformedRevenueData } from 'services/api/type/report.type';
import { Table } from 'antd';
import { getColumnConsolidateSourceRevenue, getColumnsConsolidateRevenue } from 'constants/table';
import { useMemo, useState } from 'react';
import { useGetConsolidateSourceRevenueDetails } from 'hooks/uesGetConsolidateRevenue';
import { PAGE_SIZE_CONSOLIDATE_SOURCE_DETAILS } from 'services/api/module/report.api';
import { useTranslation } from 'react-i18next';

interface CustomTableProps {
  loading?: boolean;
  dataSource: TransformedRevenueData[];
  onShowOcc?: boolean;
  title: string;
  filterOptions?: any;
}

interface ExpandedRowState {
  sourceId: number;
  page: number;
  pageSize: number;
}

export default function CustomerTableForConsolidationRevenue({
  loading,
  dataSource,
  onShowOcc,
  title,
  filterOptions
}: CustomTableProps) {
  const { t } = useTranslation();
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([]);
  const [expandedRows, setExpandedRows] = useState<Map<number, ExpandedRowState>>(new Map());

  const columnsConsolidateRevenue = useMemo(
    () => getColumnsConsolidateRevenue(title, t, onShowOcc),
    [title, t, onShowOcc]
  );

  const handleExpand = (expanded: boolean, record: TransformedRevenueData) => {
    const sourceId = record.source_id;

    if (!sourceId) return;

    setExpandedRowKeys(prevKeys => {
      if (expanded) {
        return [...prevKeys, sourceId];
      } else {
        return prevKeys.filter(key => key !== sourceId);
      }
    });

    setExpandedRows(prevRows => {
      const newRows = new Map(prevRows);
      if (expanded) {
        newRows.set(sourceId, {
          sourceId,
          page: 1,
          pageSize: PAGE_SIZE_CONSOLIDATE_SOURCE_DETAILS
        });
      } else {
        newRows.delete(sourceId);
      }
      return newRows;
    });
  };

  const handleChangePage = (page: number, sourceId: number) => {
    setExpandedRows(prevRows => {
      const newRows = new Map(prevRows);
      const currentRow = newRows.get(sourceId);
      if (currentRow) {
        newRows.set(sourceId, { ...currentRow, page });
      }
      return newRows;
    });
  };

  const handleChangePageSize = (sourceId: number, size: number) => {
    setExpandedRows(prevRows => {
      const newRows = new Map(prevRows);
      const currentRow = newRows.get(sourceId);
      if (currentRow) {
        newRows.set(sourceId, { ...currentRow, pageSize: size, page: 1 });
      }
      return newRows;
    });
  };

  const hasExpandableRows = useMemo(() => {
    return dataSource?.some(record => record.source_id !== undefined && record.source_id !== null);
  }, [dataSource]);

  const columnsConsolidateSourceRevenue = useMemo(() => {
    return getColumnConsolidateSourceRevenue(t);
  }, [t]);

  const ExpandedRowContent = ({ sourceId }: { sourceId: number }) => {
    const rowState = expandedRows.get(sourceId);

    const { data, pagination, isLoading } = useGetConsolidateSourceRevenueDetails(
      {
        ...filterOptions,
        source_id: sourceId
      },
      rowState?.page || 1,
      rowState?.pageSize || PAGE_SIZE_CONSOLIDATE_SOURCE_DETAILS
    );

    return (
      <Table
        rowKey="source_id"
        size="small"
        loading={isLoading}
        columns={columnsConsolidateSourceRevenue}
        dataSource={data}
        showHeader={false}
        locale={{
          emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
        }}
        pagination={{
          current: rowState?.page || 1,
          position: ['bottomCenter'],
          total: pagination.total,
          pageSize: rowState?.pageSize || PAGE_SIZE_CONSOLIDATE_SOURCE_DETAILS,
          locale: { items_per_page: `/ ${t('common.page')}` },
          showLessItems: true,
          showTotal: total => `${total} items`,
          onChange: (page: number) => handleChangePage(page, sourceId),
          onShowSizeChange: (current, size) => handleChangePageSize(sourceId, size)
        }}
      />
    );
  };

  return (
    <Table
      loading={loading}
      rowKey="source_id"
      size="small"
      columns={columnsConsolidateRevenue}
      dataSource={dataSource ?? []}
      rowClassName={record => (record.roomType === t('common.total') ? 'bold-row' : '')}
      pagination={false}
      locale={{
        emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
      }}
      scroll={{ x: 1200, y: 'calc(100vh - 400px)' }}
      expandable={
        hasExpandableRows
          ? {
              expandedRowKeys,
              onExpand: handleExpand,
              expandedRowRender: record => <ExpandedRowContent sourceId={record.source_id} />,
              rowExpandable: record => record.source_id !== undefined && record.source_id !== null,
              columnWidth: 26
            }
          : undefined
      }
    />
  );
}
