import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './en/en.json';
import vnTranslation from './vi/vi.json';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: enTranslation
      },
      vn: {
        translation: vnTranslation
      }
    },
    fallbackLng: 'vn',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
