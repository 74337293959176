import React, { useMemo, useState } from 'react';
import ExcelJS from 'exceljs';
import { Button, Dropdown, MenuProps } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import * as FileSaver from 'file-saver';
import { handleMenuClick, generateMenuItems } from 'utils/excelUtils';
import useCommonStore from 'stores/useCommon';
import { formatNumber } from 'utils';

interface Props {
  fileName: string;
  dataToExport: any;
  t: (key: string) => string;
  fromDate?: string;
  toDate?: string;
}

const ExportBreakfastsDetails = ({ fileName, dataToExport, fromDate, toDate, t }: Props) => {
  const [isLoadingExport, setIsLoadingExport] = useState(false);
  const { currentBranch } = useCommonStore();

  const titleOfReport = 'Báo cáo Chi tiết Danh sách ăn sáng';

  const exportToCSV = async () => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    try {
      setIsLoadingExport(true);

      const workbook = new ExcelJS.Workbook();
      const sheetName = `Reservation Forecast`;
      const sheet = workbook.addWorksheet(sheetName, {
        views: [
          {
            showGridLines: false
          }
        ]
      });

      // Title
      sheet.mergeCells('A1:K1');
      sheet.getCell('A1').value = currentBranch?.name?.toUpperCase() || '';
      sheet.getCell('A1').font = { bold: true, size: 14 };
      sheet.getCell('A1').alignment = { horizontal: 'center' };

      sheet.mergeCells('A2:K2');
      sheet.getCell('A2').value = `Địa chỉ: ${currentBranch?.address}`;
      sheet.getCell('A2').font = { size: 10 };
      sheet.getCell('A2').alignment = { horizontal: 'center' };

      sheet.mergeCells('A3:K3');
      sheet.getCell('A3').value = currentBranch?.phone_contact
        ? `ĐT: ${currentBranch?.phone_contact}`
        : '';

      sheet.mergeCells('A5:K5');
      sheet.getCell('A5').value = titleOfReport;
      sheet.getCell('A5').font = { size: 12, bold: true };
      sheet.getCell('A5').alignment = { horizontal: 'center' };

      sheet.mergeCells('A6:B6');
      sheet.getCell('A6').value = `Ngày: ${fromDate}`;
      sheet.getCell('A6').font = { bold: true };
      sheet.mergeCells('C6:D6');
      sheet.getCell('C6').value = `Đến: ${toDate}`;
      sheet.getCell('C6').font = { bold: true };

      sheet.mergeCells('B8:C8');
      sheet.getCell('B8').value = 'CHECK IN';
      sheet.getCell('B8').alignment = { horizontal: 'center', vertical: 'middle' };
      sheet.getCell('B8').font = { bold: true };

      sheet.mergeCells('D8:E8');
      sheet.getCell('D8').value = 'CHECK OUT';
      sheet.getCell('D8').alignment = { horizontal: 'center', vertical: 'middle' };
      sheet.getCell('D8').font = { bold: true };

      sheet.mergeCells('F8:I8');
      sheet.getCell('F8').value = 'BREAKFAST';
      sheet.getCell('F8').alignment = { horizontal: 'center', vertical: 'middle' };
      sheet.getCell('F8').font = { bold: true };

      // Other Parent Headers
      sheet.mergeCells('A8:A9');
      sheet.getCell('A8').value = 'DATE';
      sheet.getCell('A8').font = { bold: true };

      // Add Child Headers
      const childHeaders = ['Room', 'Guest', 'Room', 'Guest', 'Room', 'Adult', 'Child', 'Guest'];

      sheet.getCell('A8').alignment = { horizontal: 'center', vertical: 'middle' };
      ['B8', 'D8', 'F8', 'I8'].forEach(cell => {
        sheet.getCell(cell).alignment = { horizontal: 'center', vertical: 'middle' };
      });

      const headerRow = sheet.getRow(9);
      childHeaders.forEach((header, index) => {
        const cell = headerRow.getCell(index + 2);
        cell.value = header;
        cell.alignment = { horizontal: 'center', vertical: 'middle' };
        cell.font = { bold: true };
      });
      headerRow.commit();

      sheet.columns = [
        { key: 'A', width: 15 },
        { key: 'B', width: 12 },
        { key: 'C', width: 12 },
        { key: 'D', width: 12 },
        { key: 'E', width: 12 },
        { key: 'F', width: 12 },
        { key: 'G', width: 12 },
        { key: 'H', width: 12 },
        { key: 'I', width: 15 }
      ];

      // Data Rows
      dataToExport.forEach((item: any) => {
        const row = [
          item.date,
          item.check_in ? formatNumber(item.check_in.room) : 0,
          item.check_in ? formatNumber(item.check_in.pax) : 0,
          item.check_out ? formatNumber(item.check_out.room) : 0,
          item.check_out ? formatNumber(item.check_out.pax) : 0,
          item.breakfast ? formatNumber(item.breakfast.room) : 0,
          item.breakfast ? formatNumber(item.breakfast.adult) : 0,
          item.breakfast ? formatNumber(item.breakfast.child) : 0,
          item.breakfast ? formatNumber(item.breakfast.pax) : 0
        ];
        const newRow = sheet.addRow(row);
        newRow.eachCell(cell => {
          cell.alignment = { horizontal: 'center', vertical: 'middle' };
        });
      });

      sheet.eachRow({ includeEmpty: false }, (row, rowIndex) => {
        if (rowIndex >= 8) {
          row.eachCell(cell => {
            cell.border = {
              top: { style: 'thin' },
              left: { style: 'thin' },
              bottom: { style: 'thin' },
              right: { style: 'thin' }
            };
          });
        }
      });

      if (sheet.lastRow) {
        const lastRowNumber = sheet.lastRow.number;
        const lastRow = sheet.getRow(lastRowNumber);

        lastRow.eachCell({ includeEmpty: true }, cell => {
          cell.font = { bold: true };
        });
      }

      const buffer = await workbook.xlsx.writeBuffer();
      const data = new Blob([buffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);
    } catch (e) {
      console.error('Error exporting data', e);
    } finally {
      setIsLoadingExport(false);
    }
  };

  const handleClickMenu = (menu: any) => {
    handleMenuClick(menu.key, exportToCSV);
  };
  const items: MenuProps['items'] = useMemo(() => generateMenuItems(), []);

  return (
    <>
      <Dropdown
        menu={{ items, onClick: handleClickMenu }}
        trigger={['click']}
        overlayClassName="menu-user"
      >
        <Button icon={<DownloadOutlined />} loading={isLoadingExport}>
          {t('common.actions.exportData')}
        </Button>
      </Dropdown>
    </>
  );
};

export default ExportBreakfastsDetails;
