import { useEffect, useMemo } from 'react';

import { Table } from 'antd';

import { useGetBreakfasts } from 'hooks/useGetBreakfasts';
import { getColumnBreakfast } from 'constants/table';
import { toLocalTime } from 'utils';

interface Props {
  filterOptions: any;
  setDataSource: (data: any[]) => void;
  t: (key: string) => string;
}

function BreakfastsByDate({ filterOptions, setDataSource, t }: Props) {
  const { data, isFetching } = useGetBreakfasts(filterOptions.date);

  const dataSource = useMemo(() => {
    if (data) {
      const mappedData = data.flatMap(item =>
        item.lines.map((line, index) => ({
          booking_id: index === 0 ? item.booking_id : '',
          guest: line.guest || '',
          room_name: index === 0 ? item.room_name : '',
          arrival: line.arrival ? toLocalTime(line.arrival, 'DD/MM/YYYY - HH:mm:ss') : '',
          departure: line.departure ? toLocalTime(line.departure, 'DD/MM/YYYY - HH:mm:ss') : '',
          adult: index === 0 ? item.adult : '',
          child: index === 0 ? item.child : '',
          rowSpan: index === 0 ? item.lines.length : 0
        }))
      );

      const totalAdults = mappedData.reduce((sum, item) => sum + (Number(item.adult) || 0), 0);
      const totalChildren = mappedData.reduce((sum, item) => sum + (Number(item.child) || 0), 0);

      if (totalAdults > 0 || totalChildren > 0) {
        const totalRow = {
          booking_id: 'TOTAL',
          guest: '',
          room_name: '',
          adult: totalAdults || 0,
          child: totalChildren || 0,
          arrival: '',
          departure: '',
          isTotalRow: true
        };

        return [...mappedData, totalRow];
      }
      return mappedData;
    }
    return [];
  }, [data]);

  const columns = getColumnBreakfast
    ? getColumnBreakfast(t).map(column => {
        const originalRender = column.render;

        return {
          ...column,
          render: (text: any, record: any, index: any) => {
            if (record.isTotalRow) {
              return <strong>{text}</strong>;
            }

            if (originalRender) {
              return originalRender(text, record, index);
            }
            return text;
          }
        };
      })
    : [];

  useEffect(() => {
    if (dataSource && setDataSource) {
      setDataSource(dataSource);
    }
  }, [dataSource, setDataSource]);

  return (
    <div className="pms-room-lock__table">
      <Table
        loading={isFetching}
        rowKey="id"
        columns={columns}
        size="small"
        dataSource={dataSource}
        bordered={true}
        pagination={false}
        locale={{
          emptyText: <span className="empty-data">{t('common.actions.noData')}</span>
        }}
        scroll={{ x: 500, y: 'calc(100vh - 320px)' }}
      />
    </div>
  );
}

export default BreakfastsByDate;
