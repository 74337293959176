import { getColumnServiceFocTab } from 'constants/table';
import CustomTable from '../room-available-components/CustomTable';
import { useGetServicesFoc } from 'hooks/useGetFocs';
import { GetFocsType } from 'services/api/type/report.type';
import { useMemo } from 'react';

interface ServicesFocProps {
  isEnable: boolean;
  filterOptions: GetFocsType;
  t: (key: string) => string;
}

export default function ServicesFocTab({ isEnable, filterOptions, t }: ServicesFocProps) {
  const { data: servicesFoc, isLoading } = useGetServicesFoc(filterOptions, isEnable);

  const totalServicesQty =
    servicesFoc?.reduce((acc: any, room: any) => acc + room.services_qty, 0) || 0;
  const totalCalculatePrice =
    servicesFoc?.reduce((acc: any, room: any) => acc + room.calculate_price, 0) || 0;

  const formattedServicesFoc = useMemo(() => {
    return servicesFoc.map((service: any) => ({
      ...service,
      date_of_foc: !service.services ? service.date_of_foc : ''
    }));
  }, [servicesFoc]);

  const dataWithTotal = [
    ...formattedServicesFoc,
    {
      booking_id: t('managePage.focsReportPage.common.total'),
      partner_name: '',
      services_qty: totalServicesQty,
      service_default_price: '',
      calculate_price: totalCalculatePrice,
      room_type: '',
      date_of_foc: ''
    }
  ];

  const columnServiceFocTab = useMemo(() => {
    return getColumnServiceFocTab(t);
  }, [t]);

  return (
    <CustomTable
      rowKey="booking_id"
      loading={isLoading}
      columns={columnServiceFocTab}
      dataSource={dataWithTotal || []}
      t={t}
    />
  );
}
